export const MenuSuperAdmin = [
  {
    title: 'Dashboard',
    iconStyle: <i className="flaticon-041-graph"></i>,
    to: 'super-admin'
  },
  {
    title: 'Organisations',
    iconStyle: <i className="flaticon-022-copy"></i>,
    content: [
      {
        title: 'Organisation List',
        to: '/super-admin/list-organisation'
      },
      {
        title: 'Create Organisation',
        to: '/super-admin/create-organisation'
      }
    ]
  }
];
