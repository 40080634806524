import { useState, useEffect } from 'react';
import './style.css';
import { Spinner } from 'react-bootstrap';

const GlobalLoader = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isFullSize, setIsFullSize] = useState(false);
  const [title, setTitle] = useState('');

  useEffect(() => {
    const handleLoaderEvent = (event) => {
      const { action, title, fullSize = false } = event.detail;
      setIsFullSize(fullSize);
      setTitle(title);
      if (action === 'open') {
        setIsVisible(true);
        document.documentElement.style.overflow = 'hidden'; // Disable scroll on html
      } else if (action === 'close') {
        setIsVisible(false);
        document.documentElement.style.overflow = ''; // Re-enable scroll on html
      }
    };

    // Listen for the custom toggleLoader event
    window.addEventListener('toggleLoader', handleLoaderEvent);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('toggleLoader', handleLoaderEvent);
    };
  }, []);

  // Return null if not visible
  if (!isVisible) {
    return null;
  }

  return (
    <div className={`global-loader ${isFullSize ? 'global-loader-full' : ''}`}>
      <Spinner as={'div'} animation="grow" className="global-loader-entity" />
      <h2 className="global-loader-title">{title}</h2>
    </div>
  );
};

export default GlobalLoader;
