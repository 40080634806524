import { BsCreditCard2Front, BsCreditCard2FrontFill } from 'react-icons/bs';

export const MenuList = [
  //Dashboard
  {
    title: 'Dashboard',
    iconStyle: <i className="flaticon-041-graph"></i>,
    to: 'dashboard'
  },
  {
    title: 'Jobs',
    iconStyle: <i className="flaticon-053-lifebuoy"></i>,
    content: [
      {
        title: 'Job Lists',
        to: 'job-list'
      },
      {
        title: 'Job Application',
        to: 'job-application'
      },
      {
        title: 'Create New Job',
        to: 'new-job'
      }
    ]
  },
  {
    title: 'Personality Test',
    iconStyle: <i className="flaticon-073-question"></i>,
    to: 'personality-test'
  },
  {
    title: 'Billing',
    iconStyle: (
      <BsCreditCard2FrontFill
        id="billing-icon"
        style={{ color: '#96a0af', fontSize: '1.4rem', marginRight: '1.25rem' }}
      />
    ),
    to: 'billing'
  },
  {
    title: 'Customization',
    iconStyle: <i className="flaticon-038-gauge"></i>,
    content: [
      {
        title: 'Theme',
        to: 'theme-customization'
      },
      {
        title: 'Logos',
        to: 'logo-customisation'
      },
      {
        title: 'Currency',
        to: 'currency-customisation'
      },
      {
        title: 'Assistant',
        to: 'assistant-customisation'
      }
    ]
  }
];
