import React, { useEffect, useState } from 'react';
import { Spinner, Tab } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';

const ApplicationStatusBarChart = () => {
  const [timePeriod, setTimePeriod] = useState('daily');
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const selectorData = [
    { title: '24H', value: 'daily', icon: '⏰', label: 'Daily View' },
    { title: '7D', value: 'weekly', icon: '📅', label: 'Weekly View' },
    { title: '30D', value: 'monthly', icon: '📊', label: 'Monthly View' }
  ];
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  const fetchData = async () => {
    setIsLoading(true);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const accessToken = userDetails?.session.accessToken || null;
    if (!accessToken) {
      throw new Error('Access token not found');
    }
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/
		${orgId}/analytics/applications-status-count/${timePeriod}`;

    try {
      const response = await fetch(URL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Error getting reponse');
      }
      const body = await response.json();
      const data = body.data;
      setChartData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Could not fetch chart data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [timePeriod]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    if (timePeriod == 'weekly') {
      return 'week ' + dateString.split('-')[1];
    } else if (timePeriod === 'monthly') {
      return month;
    }

    return `${day} ${month}`;
  }

  const labels = [...new Set(chartData.map((item) => item.period))];

  const rejectedData = labels.map(
    (label) =>
      chartData.find(
        (item) => item.period === label && item.status === 'REJECTED'
      )?.count || 0
  );

  const acceptedData = labels.map(
    (label) =>
      chartData.find(
        (item) => item.period === label && item.status === 'ACCEPTED'
      )?.count || 0
  );

  const data = {
    labels: labels.map((label) => formatDate(label)),
    datasets: [
      {
        label: 'Rejected',
        data: rejectedData,
        backgroundColor: '#FFB4B4',
        hoverBackgroundColor: '#FF9B9B',
        borderRadius: 6,
        borderSkipped: false
      },
      {
        label: 'Accepted',
        data: acceptedData,
        backgroundColor: '#A7E3BD',
        hoverBackgroundColor: '#93D4A8',
        borderRadius: 6,
        borderSkipped: false
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    barThickness: 'flex',
    maxBarThickness: 32,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 0.98)',
        titleColor: '#1a1a1a',
        bodyColor: '#1a1a1a',
        titleFont: {
          size: 13,
          weight: '600',
          family: '"Inter", sans-serif'
        },
        bodyFont: {
          size: 12,
          family: '"Inter", sans-serif'
        },
        padding: 12,
        boxPadding: 6,
        usePointStyle: true,
        borderColor: 'rgba(220, 220, 220, 0.9)',
        borderWidth: 1,
        cornerRadius: 8
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            size: 12,
            family: '"Inter", sans-serif'
          },
          color: '#64748b'
        },
        border: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(226, 232, 240, 0.5)',
          drawBorder: false
        },
        ticks: {
          font: {
            size: 12,
            family: '"Inter", sans-serif'
          },
          color: '#64748b',
          padding: 8
        },
        border: {
          display: false
        }
      }
    }
  };

  return (
    <>
      <style>
        {`
          .card-header {
            width: 100%;
          }

          .header-wrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            text-align: center;
          }

          @media (min-width: 768px) {
            .header-wrapper {
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              gap: 24px;
              text-align: left;
            }
          }

          .header-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          @media (min-width: 768px) {
            .header-content {
              align-items: flex-start;
            }
          }

          .header-title {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
            justify-content: center;
          }

          @media (min-width: 768px) {
            .header-title {
              justify-content: flex-start;
            }
          }

          .status-badge {
            white-space: nowrap;
          }

          .period-selector {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 8px;
          }

          @media (min-width: 768px) {
            .period-selector {
              width: auto;
            }
          }

          .period-selector .tab-btn {
            flex: 1;
            min-width: auto;
            padding: 8px 12px;
            max-width: 120px;
          }

          @media (min-width: 768px) {
            .period-selector .tab-btn {
              flex: 0 1 auto;
              min-width: 90px;
              padding: 8px 16px;
            }
          }

          .period-selector .icon {
            display: none;
          }

          @media (min-width: 576px) {
            .period-selector .icon {
              display: inline;
            }
          }

          .chart-footer {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px 0;
            border-top: 1px solid rgba(226, 232, 240, 0.8);
            margin-top: 16px;
            gap: 24px;
          }

          .chart-label {
            display: inline-flex;
            align-items: center;
            gap: 8px;
            padding: 6px 12px;
            border-radius: 8px;
            font-size: 0.875rem;
            font-weight: 500;
          }

          .chart-label.accepted {
            background: rgba(167, 227, 189, 0.2);
            color: #2F855A;
          }

          .chart-label.rejected {
            background: rgba(255, 180, 180, 0.2);
            color: #E53E3E;
          }

          .chart-label .dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }

          .chart-label.accepted .dot {
            background: #A7E3BD;
          }

          .chart-label.rejected .dot {
            background: #FFB4B4;
          }
        `}
      </style>

      <div className="chart-container bg-white rounded-xl shadow-sm">
        <Tab.Container defaultActiveKey={selectorData[0].value}>
          <div className="card border-0">
            <div className="card-header bg-transparent border-0 pt-5 px-5 pb-0">
              <div className="header-wrapper">
                <div className="header-content">
                  <h4 className="header-title">
                    Application Status
                    <span className="status-badge">
                      <i
                        className="fas fa-circle text-primary"
                        style={{ fontSize: '8px' }}
                      ></i>
                      Real-time Data
                    </span>
                  </h4>
                  <p className="header-subtitle">
                    Track application acceptance and rejection rates
                  </p>
                </div>

                <div className="period-selector">
                  {selectorData.map((item) => (
                    <button
                      key={item.value}
                      onClick={() => setTimePeriod(item.value)}
                      className={`tab-btn ${timePeriod === item.value ? 'active' : ''}`}
                    >
                      <span className="icon">{item.icon}</span>
                      <span className="label">{item.title}</span>
                      <span className="tooltip">{item.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <div className="card-body px-4 pt-3">
              <Tab.Content>
                <div className="chart-wrapper" style={{ height: '300px' }}>
                  {isLoading ? (
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <Spinner
                        animation="border"
                        className="text-primary opacity-50"
                        size="lg"
                      />
                    </div>
                  ) : (
                    <>
                      {chartData?.length !== 0 ? (
                        <Bar options={options} data={data} />
                      ) : (
                        <div className="h-100 d-flex align-items-center justify-content-center">
                          <div className="text-center">
                            <p className="text-gray-500 mb-0">
                              No Data Available
                            </p>
                            <small className="text-gray-400">
                              Try selecting a different time period
                            </small>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Tab.Content>

              <div className="chart-footer">
                <div className="chart-label accepted">
                  <span className="dot"></span>
                  <span>Accepted</span>
                </div>
                <div className="chart-label rejected">
                  <span className="dot"></span>
                  <span>Rejected</span>
                </div>
              </div>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default ApplicationStatusBarChart;
