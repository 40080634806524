import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const PersonalityReportModal = ({
  showModal,
  setModal,
  personalityTestDetails
}) => {
  const handleClose = () => {
    setModal(false);
  };

  const { discTest, hvpPlusTest, motivatorsTest } = personalityTestDetails;

  const openReport = (name) => {
    window.open(`/${name}/${personalityTestDetails._id}`, '_blank');
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>View Personality Tests</Modal.Title>
      </Modal.Header>
      <p className="mx-4">
        The tests which are not completed by the candidate are disabled
      </p>
      <Modal.Body>
        <div className="d-flex w-full gap-2 flex-wrap">
          {discTest && (
            <Button
              disabled={discTest?.status !== 'COMPLETED'}
              variant="primary"
              onClick={() => {
                openReport('job-disc-view');
              }}
            >
              DISC Report
            </Button>
          )}
          {hvpPlusTest && (
            <Button
              disabled={discTest?.status !== 'COMPLETED'}
              variant="primary"
              onClick={() => {
                openReport('job-hvp-view');
              }}
            >
              HVP Report
            </Button>
          )}
          {motivatorsTest && (
            <Button
              disabled={discTest?.status !== 'COMPLETED'}
              variant="primary"
              onClick={() => {
                openReport('motivators-report');
              }}
            >
              Motivators Report
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PersonalityReportModal;
