import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Form
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ToggleSwitch from '../bootstrap/Toggle';
import { FaEye, FaPencilAlt, FaCopy } from 'react-icons/fa';
import { FaAngleLeft, FaAngleRight, FaRegTrashCan } from 'react-icons/fa6';
import { IoSearch } from 'react-icons/io5';
import { MdOutlineWifiTetheringErrorRounded } from 'react-icons/md';
import Search from '../Jobick/Jobs/ui/search';
import currencySymbols from 'currency-symbol-map';
import { formatToCurrency, getItemsPerPage } from '../../utils/CommonFunctions';
import { FaPlus } from 'react-icons/fa6';
import { FaFilter } from 'react-icons/fa6';
import { FaRegEye, FaRegEdit, FaRegCopy, FaRegTrashAlt } from 'react-icons/fa';
import {
  HiOutlineDocumentDuplicate,
  HiOutlineUsers,
  HiOutlineTrash,
  HiOutlinePencilAlt,
  HiOutlineEye
} from 'react-icons/hi';
import { RiFileUserLine, RiDeleteBinLine } from 'react-icons/ri';

const JobLists = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(''); // for status filter
  const [pageVal, setPageVal] = useState(1); // for value of pagination
  const [totalJobs, setTotalJobs] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(1);
  const [jobList, setJobList] = useState(null);
  const [searchVal, setSearchVal] = useState('');
  const jobsPerPage = getItemsPerPage();
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;
  const [currencySymbol, setCurrencySymbol] = useState('₹');

  // const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    const storedCurrency = localStorage.getItem('currency');

    if (storedCurrency) {
      const symbol = currencySymbols(storedCurrency) || '₹';
      setCurrencySymbol(symbol);
    }
  }, []);

  const handlePreviousPageClick = () => {
    if (pageVal === 1) {
      return;
    }
    setPageVal(pageVal - 1);
  };

  const handleNextPageClick = () => {
    if (pageVal >= totalPages) {
      return;
    }
    setPageVal(pageVal + 1);
  };

  const fetchJobList = async () => {
    !jobList && setLoading(1);
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job?search=${searchVal}&page=${pageVal}&limit=${jobsPerPage}${status && '&status=' + status}&sortBy=createdAt&sortOrder=desc`;
    // const numOfRows = Math.floor(tableHeight / 73);
    // const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job?search=${searchVal}&page=${pageVal}&limit=${numOfRows}${status && '&status=' + status}&sortBy=createdAt&sortOrder=desc`;
    try {
      const response = await fetch(URL);
      if (!response.ok) {
        throw new Error('Fetching failed');
      }
      const body = await response.json();
      const { data, total, totalPages } = body;
      setJobList(data);
      setTotalJobs(total);
      setTotalPages(totalPages);
      setLoading(0);
    } catch (error) {
      setLoading(2);
      console.error('Error fetching job list', error);
    }
  };

  useEffect(() => {
    fetchJobList();
  }, [pageVal]);

  useEffect(() => {
    if (pageVal === 1) {
      fetchJobList();
    } else {
      setPageVal(1);
    }
  }, [searchVal, status]);

  // useEffect(() => {
  //   if (!!tableHeight) {
  //     fetchJobList();
  //   }
  // }, [tableHeight]);

  // const checkAndSetTableHeight = () => {
  //   const headerHeight = document.getElementById('header-wrapper').offsetHeight;
  //   const tableHeaderHeight = document.getElementById('job-list-header').offsetHeight;
  //   const tableHeight = window.innerHeight - headerHeight - tableHeaderHeight - 240;
  //   setTableHeight(tableHeight);
  // };

  // useEffect(() => {
  //   checkAndSetTableHeight();

  //   const debounce = (func, wait) => {
  //     let timeout;
  //     return function executedFunction(...args) {
  //       const later = () => {
  //         clearTimeout(timeout);
  //         func(...args);
  //       };
  //       clearTimeout(timeout);
  //       timeout = setTimeout(later, wait);
  //     };
  //   };

  //   const debouncedHandleResize = debounce(checkAndSetTableHeight, 500);

  //   window.addEventListener('resize', debouncedHandleResize);
  //   return () => {
  //     window.removeEventListener('resize', debouncedHandleResize);
  //   };
  // }, []);

  const handleStatusToggle = async (jobId, jobStatus) => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const accessToken = userDetails?.session.accessToken || null;
    if (!accessToken) {
      throw new Error('Access token not found');
    }
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}`;
    const bodydata = {
      status: jobStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    };
    try {
      const response = await fetch(URL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(bodydata)
      });
      if (!response.ok) {
        throw new Error('Error getting reponse');
      }
      fetchJobList();
    } catch (error) {
      console.error('Could not fetch pie data', error);
    }
  };

  const handleEditClick = (jobId) => {
    navigate(`/update-job/${jobId}`);
  };

  const handleCloneJob = (jobId) => {
    navigate(`/clone-job/${jobId}`);
  };

  const handleAddNewJob = () => {
    navigate('/new-job');
  };

  const pgItem1 =
    pageVal === 1
      ? 1
      : pageVal >= totalPages && totalPages > 2
        ? totalPages - 2
        : pageVal - 1;
  const pgItem2 =
    pageVal === 1
      ? 2
      : pageVal >= totalPages && totalPages > 2
        ? totalPages - 1
        : pageVal;
  const pgItem3 =
    pageVal === 1
      ? 3
      : pageVal >= totalPages && totalPages > 2
        ? totalPages
        : pageVal + 1;

  return (
    <>
      <style>
        {`
    .custom-dropdown-toggle,
    .custom-dropdown-toggle:hover,
    .custom-dropdown-toggle:focus,
    .custom-dropdown-toggle:active {
      border: 1px solid #d3d3d3 !important;
      outline: none !important;
      box-shadow: none !important;
      
    }
    .custom-dropdown-toggle::after {
      display: none !important;
    }

    /* Filter Dropdown Button */
    .filter-dropdown-toggle {
      background: #F8FAFC !important;
      border: 1px solid #E2E8F0 !important;
      border-radius: 12px !important;
      padding: 0.7rem 1rem !important;
      display: flex !important;
      align-items: center !important;
      gap: 0.5rem !important;
      transition: all 0.2s ease !important;
      color: #475569 !important;
    }

    .filter-dropdown-toggle:hover {
      background: #fff !important;
      border-color: #93C5FD !important;
      box-shadow: 0 4px 12px rgba(59, 130, 246, 0.08) !important;
      color: #3B82F6 !important;
    }

    .filter-dropdown-toggle:focus,
    .filter-dropdown-toggle.show {
      background: #fff !important;
      border-color: #60A5FA !important;
      box-shadow: 0 0 0 3px rgba(96, 165, 250, 0.12) !important;
    }

    /* Filter Dropdown Menu */
    .filter-dropdown-menu {
      padding: 1.25rem !important;
      border: none !important;
      border-radius: 16px !important;
      box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1) !important;
      min-width: 280px !important;
      margin-top: 0.5rem !important;
      background: #fff !important;
    }

    /* Filter Header */
    .filter-header {
      margin: -0.25rem -0.25rem 1rem -0.25rem !important;
      padding: 0 0 1rem 0 !important;
      border-bottom: 1px solid #E2E8F0 !important;
    }

    .filter-header h4 {
      color: #1E293B !important;
      font-size: 1.1rem !important;
      font-weight: 600 !important;
      margin: 0 !important;
    }

    /* Filter Section */
    .filter-section {
      margin-bottom: 1.5rem !important;
    }

    .filter-section:last-child {
      margin-bottom: 0 !important;
    }

    .filter-label {
      font-size: 0.95rem !important;
      font-weight: 500 !important;
      color: #475569 !important;
      margin-bottom: 0.75rem !important;
      display: block !important;
    }

    /* Status Filter Buttons */
    .status-button-group {
      display: flex !important;
      gap: 0.5rem !important;
      flex-wrap: wrap !important;
    }

    .status-button {
      padding: 0.5rem 1rem !important;
      border-radius: 8px !important;
      font-size: 0.9rem !important;
      font-weight: 500 !important;
      border: 1px solid #E2E8F0 !important;
      background: #F8FAFC !important;
      color: #64748B !important;
      cursor: pointer !important;
      transition: all 0.2s ease !important;
      flex: 1 !important;
      min-width: 80px !important;
      text-align: center !important;
    }

    .status-button:hover {
      background: #F1F5F9 !important;
      border-color: #CBD5E1 !important;
      color: #334155 !important;
    }

    .status-button.active {
      background: #EFF6FF !important;
      border-color: #3B82F6 !important;
      color: #3B82F6 !important;
    }

    /* Filter Icon Animation */
    .filter-icon {
      transition: transform 0.2s ease !important;
    }

    .filter-dropdown-toggle:hover .filter-icon {
      transform: rotate(90deg) !important;
    }

    /* Filter Count Badge */
    .filter-count {
      background: #EFF6FF !important;
      color: #3B82F6 !important;
      padding: 0.25rem 0.5rem !important;
      border-radius: 6px !important;
      font-size: 0.8rem !important;
      font-weight: 500 !important;
      margin-left: 0.5rem !important;
    }

    /* Action Button Styles */
    .action-buttons {
      display: flex;
      gap: 0.8rem;
      justify-content: center;
      align-items: center;
      padding: 0 0.5rem;
      min-width: 12rem;
    }

    .action-btn {
      padding: 0.5rem;
      border-radius: 8px;
      transition: all 0.2s ease;
      position: relative;
      border: none;
      background: transparent;
      color: #64748B;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .action-btn:hover {
      transform: translateY(-2px);
      color: #3B82F6;
    }

    .action-btn::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 8px;
      background: currentColor;
      opacity: 0.1;
      transition: opacity 0.2s ease;
    }

    .action-btn:hover::before {
      opacity: 0.15;
    }

    .action-btn.view-btn:hover { color: #10B981; }
    .action-btn.edit-btn:hover { color: #6366F1; }
    .action-btn.applications-btn:hover { color: #F59E0B; }
    .action-btn.clone-btn:hover { color: #8B5CF6; }
    .action-btn.delete-btn:hover { color: #EF4444; }

    .action-btn.loading {
      pointer-events: none;
      opacity: 0.7;
    }

    .action-btn.loading svg {
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }

    /* Custom Tooltip */
    .custom-tooltip {
      background: #1E293B !important;
      padding: 0.5rem 0.8rem !important;
      border-radius: 6px !important;
      font-size: 0.8rem !important;
      font-weight: 500 !important;
      opacity: 0;
      transition: opacity 0.2s ease !important;
    }

    .custom-tooltip.show {
      opacity: 1 !important;
    }
  `}
      </style>
      <div
        id="job-list-header"
        className="d-flex gap-2 align-items-center mb-4 flex-wrap"
        style={{
          flexDirection: 'row',
          '@media (max-width: 767px)': {
            flexDirection: 'column'
          }
        }}
      >
        <div className="w-100 d-flex flex-column flex-md-row gap-2 align-items-md-center">
          <h3 className="mb-0 me-auto text-center text-md-start w-100 w-md-auto">
            Job List
          </h3>

          <div className="d-flex flex-column flex-md-row gap-2 align-items-center w-100 w-md-auto">
            <div className="w-100 w-md-auto">
              <Search searchVal={searchVal} setSearchVal={setSearchVal} />
            </div>

            <div className="d-flex gap-2 align-items-center">
              <Dropdown className="nav-item">
                <Dropdown.Toggle className="filter-dropdown-toggle">
                  <FaFilter className="filter-icon" size="13" />
                  Filters
                  {status && <span className="filter-count">1</span>}
                </Dropdown.Toggle>

                <Dropdown.Menu className="filter-dropdown-menu">
                  <div className="filter-header">
                    <h4>Filters</h4>
                  </div>

                  <div className="filter-section">
                    <label className="filter-label">Status</label>
                    <div className="status-button-group">
                      <button
                        className={`status-button ${status === '' ? 'active' : ''}`}
                        onClick={() => setStatus('')}
                      >
                        All
                      </button>
                      <button
                        className={`status-button ${status === 'ACTIVE' ? 'active' : ''}`}
                        onClick={() => setStatus('ACTIVE')}
                      >
                        Active
                      </button>
                      <button
                        className={`status-button ${status === 'INACTIVE' ? 'active' : ''}`}
                        onClick={() => setStatus('INACTIVE')}
                      >
                        Inactive
                      </button>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>

              <Button
                className="btn btn-primary btn-sm"
                onClick={handleAddNewJob}
              >
                <FaPlus size="11" /> Add New Job
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className=" mb-4 table-responsive">
            <table
              id="job-table"
              className="table  display dataTablesCard mb-4 job-table table-responsive-xl card-table dataTable no-footer"
            >
              <thead>
                <tr className="user-select-none ">
                  <th style={{ width: '4rem' }}>No</th>
                  <th style={{ width: '10rem' }}>Position </th>
                  <th style={{ width: '5rem' }}>Type</th>
                  <th style={{ width: '5rem' }}>Location</th>
                  <th style={{ width: '8rem' }}>Salary</th>
                  <th style={{ width: '3rem' }}>Posted Date</th>
                  <th style={{ width: '15rem' }}>Status</th>
                  <th style={{ width: '16rem' }} className="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading === 0 &&
                  jobList.map((job, index) => (
                    <tr
                      key={index}
                      style={{
                        height: '4.5rem',
                        borderTop: '1px solid #dee2e6'
                      }}
                    >
                      <td
                        style={{ maxWidth: '4rem' }}
                        className="text-truncate"
                      >
                        {(pageVal - 1) * jobsPerPage + index + 1}.
                      </td>
                      <td
                        style={{ maxWidth: '11rem' }}
                        className="text-truncate"
                      >
                        {job.title}
                      </td>
                      <td
                        style={{ maxWidth: '7rem' }}
                        className="text-truncate wspace-no"
                      >
                        {job.jobType}
                      </td>
                      <td
                        style={{ maxWidth: '5rem' }}
                        className="text-truncate"
                      >
                        {job.location}
                      </td>
                      <td
                        style={{ maxWidth: '12rem' }}
                        className="text-truncate"
                      >
                        {job.minimumSalary
                          ? `${formatToCurrency(job.minimumSalary) || 0} - ${
                              formatToCurrency(job.maximumSalary) || 0
                            }`
                          : job.maximumSalary
                            ? `${formatToCurrency(job.maximumSalary) || 0}`
                            : 'NA'}
                      </td>

                      <td
                        style={{ maxWidth: '3rem' }}
                        className="text-truncate"
                      >
                        {job.createdAt.slice(0, 10)}
                      </td>
                      <td
                        style={{ maxWidth: '10rem' }}
                        className="text-truncate"
                        // style={{ borderTop: "none", borderBottom: "none" }}
                      >
                        <div className="d-flex">
                          <ToggleSwitch
                            isChecked={job.status === 'ACTIVE'}
                            handleToggle={() => {
                              handleStatusToggle(job._id, job.status);
                            }}
                            disabled={job.status === 'DISABLED'}
                          />

                          <span
                            className={`badge badge-lg light badge-${
                              job.status === 'ACTIVE' ? 'success' : 'danger'
                            }`}
                          >
                            {job.status}
                          </span>
                        </div>
                      </td>
                      <td style={{ maxWidth: '15rem' }}>
                        <div className="action-buttons">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip className="custom-tooltip">
                                View Job Details
                              </Tooltip>
                            }
                          >
                            <Link
                              to={`/job-view/${job._id}`}
                              className="action-btn view-btn"
                            >
                              <HiOutlineEye size={18} />
                            </Link>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip className="custom-tooltip">
                                Edit Job Post
                              </Tooltip>
                            }
                          >
                            <button
                              className="action-btn edit-btn"
                              onClick={() => handleEditClick(job._id)}
                            >
                              <HiOutlinePencilAlt size={18} />
                            </button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip className="custom-tooltip">
                                View Applicants
                              </Tooltip>
                            }
                          >
                            <Link
                              to={`/job-application/${job._id}`}
                              className="action-btn applications-btn"
                            >
                              <RiFileUserLine size={18} />
                            </Link>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip className="custom-tooltip">
                                Clone Job
                              </Tooltip>
                            }
                          >
                            <button
                              className="action-btn clone-btn"
                              onClick={() => handleCloneJob(job._id)}
                            >
                              <HiOutlineDocumentDuplicate size={18} />
                            </button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip className="custom-tooltip">
                                Delete Job Post
                              </Tooltip>
                            }
                          >
                            <button
                              className="action-btn delete-btn"
                              onClick={() => {
                                const btn = document.activeElement;
                                btn.classList.add('loading');
                                setTimeout(
                                  () => btn.classList.remove('loading'),
                                  1000
                                );
                              }}
                            >
                              <RiDeleteBinLine size={18} />
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))}

                {/* Adding empty filler rows to keep the height of table same */}
                {loading === 0 &&
                  jobList.length > 0 &&
                  [...Array(Math.max(0, jobsPerPage - jobList.length))].map(
                    (_, i) => <tr key={i} style={{ height: '4.5rem' }}></tr>
                  )}
              </tbody>
            </table>
            {loading === 1 && (
              <div className="d-flex flex-column align-items-center justify-content-center gap-5 mt-5 pt-5">
                <div className="mt-5 mb-4 pt-1"></div>
                <Spinner animation="border mb-3" style={{ color: '#858585' }} />
              </div>
            )}
            {loading === 2 && (
              <div className="d-flex flex-column align-items-center mt-5 pt-5">
                <MdOutlineWifiTetheringErrorRounded
                  size={120}
                  color="#878787B7"
                />
                <h2>Error fetching data</h2>
                <h3 style={{ color: '#828282' }}>
                  Check your network and try again
                </h3>
              </div>
            )}
            {loading === 0 && jobList.length === 0 && (
              <div className="d-flex flex-column align-items-center mt-5 pt-5">
                <IoSearch size={120} color="#878787B7" />
                <h2>No results found</h2>
                <h3 style={{ color: '#828282' }}>
                  Adjust your filters and try again
                </h3>
              </div>
            )}
          </div>
        </div>
        {loading === 0 && jobList.length !== 0 && (
          <div className="d-md-flex d-block align-items-center justify-content-between text-center  flex-wrap mt-md-0 mt-3">
            <div className="mb-md-0 mb-2">
              <p className="dataTables_info">{`Showing ${
                jobList.length !== 0 ? jobsPerPage * (pageVal - 1) + 1 : 0
              } to ${
                jobList ? jobsPerPage * (pageVal - 1) + jobList.length : 0
              } of ${totalJobs} entries`}</p>
            </div>
            <nav>
              <ul className="pagination pagination-circle justify-content-center">
                <li className="page-item page-indicator">
                  <button
                    onClick={handlePreviousPageClick}
                    disabled={pageVal === 1}
                    className={' page-link'}
                    style={{
                      backgroundColor: pageVal === 1 && '#ECECEC',
                      cursor: pageVal === 1 && 'not-allowed'
                    }}
                  >
                    <FaAngleLeft
                      style={{ color: pageVal === 1 && '#616161' }}
                    />
                  </button>
                </li>
                {pgItem1 > 1 ? (
                  <li className="page-item d-flex align-items-end">
                    <label className="fs-4">...</label>
                  </li>
                ) : (
                  <div style={{ width: '15px' }} />
                )}
                <li
                  className={`page-item ${pgItem1 === pageVal && 'active'}`}
                  onClick={() => {
                    setPageVal(pgItem1);
                  }}
                >
                  <Button className="page-link">{pgItem1}</Button>
                </li>
                {totalPages > 1 && (
                  <li
                    className={`page-item ${pgItem2 === pageVal && 'active'}`}
                    onClick={() => {
                      setPageVal(pgItem2);
                    }}
                  >
                    <Button className="page-link">{pgItem2}</Button>
                  </li>
                )}
                {totalPages > 2 && (
                  <li
                    className={`page-item ${pgItem3 === pageVal && 'active'}`}
                    onClick={() => {
                      setPageVal(pgItem3);
                    }}
                  >
                    <Button className="page-link">{pgItem3}</Button>
                  </li>
                )}
                {pgItem3 < totalPages ? (
                  <li className="page-item d-flex align-items-end">
                    <label className="fs-4">...</label>
                  </li>
                ) : (
                  <div style={{ width: '15px' }} />
                )}
                <li className="page-item page-indicator">
                  <button
                    onClick={handleNextPageClick}
                    disabled={pageVal >= totalPages}
                    className="page-link"
                    style={{
                      backgroundColor: pageVal >= totalPages && '#ECECEC',
                      cursor: pageVal >= totalPages && 'not-allowed'
                    }}
                  >
                    <FaAngleRight
                      style={{ color: pageVal >= totalPages && '#616161' }}
                    />
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </>
  );
};
export default JobLists;
