import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner'; // Assuming you're using react-bootstrap

const PDFDownloadButton = ({ label, isLoading, loadingLabel, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="btn btn-primary btn-lg shadow-lg animate-fadeIn d-flex gap-2 align-items-center justify-content-center"
    >
      {isLoading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            style={{ color: '#fff' }}
            className="pe-3"
          />
          {loadingLabel || 'Loading...'}
        </>
      ) : (
        <>
          {label}
          <i className="fas fa-paper-plane ms-2"></i>
        </>
      )}
    </button>
  );
};

PDFDownloadButton.propTypes = {
  label: PropTypes.string, // label should be a string
  isLoading: PropTypes.bool, // isLoading should be a boolean
  loadingLabel: PropTypes.string, // loadingLabel should be a string
  onClick: PropTypes.func.isRequired // onClick should be a required function
};

export default PDFDownloadButton;
