import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Spinner, Tab } from 'react-bootstrap';

import { Pie } from 'react-chartjs-2';
import ChartLabel from './chartLabel';

const CanvasPieChartTab = () => {
  const [pieData, setPieData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  const fetchData = async () => {
    setIsLoading(true);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const accessToken = userDetails?.session.accessToken || null;
    if (!accessToken) {
      throw new Error('Access token not found');
    }
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/analytics/application-status-distribution`;

    try {
      const response = await fetch(URL, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Error getting reponse');
      }
      const body = await response.json();
      const data = body.data;
      setPieData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Could not fetch pie data', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const accepted =
    pieData?.find(({ status }) => status === 'ACCEPTED')?.count || 0;
  const rejected = pieData?.find(({ status }) => status === 'REJECTED')?.count;
  const inProcess = pieData
    ?.filter(({ status }) => !['ACCEPTED', 'REJECTED'].includes(status))
    ?.reduce((acc, { count }) => acc + count, 0);
  const total = accepted + rejected + inProcess;

  const pieOptions = {
    chart: {
      type: 'pie'
    },
    labels: ['Accepted', 'Under Process', 'Rejected'],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return [opts.w.config.series[opts.seriesIndex]];
      },
      textAnchor: 'middle',
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '20px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        colors: ['#3EC15C', '#FEB019', '#F54F52']
      },
      background: {
        enabled: true,
        foreColor: '#ffffff',
        padding: 20,
        borderRadius: 10,
        borderWidth: 0
      },
      dropShadow: {
        enabled: false
      }
    },
    legend: false,
    plotOptions: {
      pie: {
        customScale: 0.7,
        expandOnClick: false,
        dataLabels: {
          offset: 80,
          minAngleToShowLabel: 0
        }
      }
    },
    stroke: {
      width: 3,
      colors: ['#FFFFFF']
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.9
        }
      },
      active: {
        filter: {
          type: 'none'
        }
      }
    },
    series: total ? [accepted, inProcess, rejected] : [],
    colors: ['#3EC15C', '#FEB019', '#F54F52']
  };

  return (
    <>
      <Tab.Container defaultActiveKey="Daily">
        <div className="card" style={{ height: 'auto' }}>
          <div className="card-header border-0 pb-0 flex-wrap">
            <h4 className="fs-20 font-w500 mb-1">Application Distribution</h4>
            <div className="card-action coin-tabs mt-3 mt-sm-0"></div>
          </div>
          <div className="card-body">
            <div className="pb-4 d-flex flex-wrap justify-content-between">
              <ChartLabel title={'Accepted: ' + accepted} color={'#3EC15C'} />
              <ChartLabel
                title={'Under Process: ' + inProcess}
                color={'#FEB019'}
              />
              <ChartLabel title={'Rejected: ' + rejected} color={'#F54F52'} />
            </div>
            <Tab.Content>
              <div style={{ height: '20rem' }}>
                {isLoading ? (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <Spinner
                      animation="border mb-3"
                      style={{ color: '#858585' }}
                    />
                  </div>
                ) : (
                  <>
                    {total ? (
                      <div
                        className="position-relative"
                        style={{ height: '20rem' }}
                      >
                        <ReactApexChart
                          options={pieOptions}
                          series={pieOptions.series}
                          type="pie"
                          height={350}
                        />
                        <div
                          style={{
                            bottom: '0px',
                            right: '0px',
                            fontSize: '0.85rem',
                            background: '#4885ED',
                            padding: '2px 5px'
                          }}
                          className="text-white position-absolute rounded "
                        >
                          Total : {total}
                        </div>
                      </div>
                    ) : (
                      <h5>No Data Found</h5>
                    )}
                  </>
                )}
              </div>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </>
  );
};
export default CanvasPieChartTab;
