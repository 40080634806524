import React, { Fragment, useState, useReducer, useEffect } from 'react';
import SideBar from './SideBar';
import NavHader from './NavHader';
import Header from './Header';

const initialState = {
  active: '',
  activeMenu: '',
  activeSubmenu: ''
};

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState
});

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const [toggle, setToggle] = useState('');
  const onClick = (name) => setToggle(toggle === name ? '' : name);

  const handleActive = (status, subMenu = '') => {
    if (state.active !== status) {
      setState({ active: status, activeMenu: subMenu });
    } else {
      if (state.activeMenu !== subMenu) {
        setState({ active: status, activeMenu: subMenu });
      }
    }
  };

  const closeSidebar = () => {
    const mainWrapper = document.querySelector('#main-wrapper');
    const sidebarStyle = document
      .querySelector('body')
      .getAttribute('data-sidebar-style');
    if (mainWrapper && sidebarStyle === 'overlay') {
      mainWrapper.classList.remove('menu-toggle');
    }
  };

  return (
    <Fragment>
      <NavHader handleActive={handleActive} />
      <Header
        onNote={() => onClick('chatbox')}
        onNotification={() => onClick('notification')}
        onProfile={() => onClick('profile')}
        toggle={toggle}
        title={title}
        onBox={() => onClick('box')}
        onClick={() => ClickToAddEvent()}
      />
      <SideBar
        state={state}
        setState={setState}
        handleActive={handleActive}
        closeSidebar={closeSidebar}
      />
    </Fragment>
  );
};

export default JobieNav;
