import React, { useEffect, useRef, useState } from 'react';
import { formatToCurrency } from '../../utils/CommonFunctions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Spinner, Alert, Modal, Button } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import ApplicationStatusProgress from '../Jobick/Jobs/ui/jobApplicationView/applicationProgress';
import { TbEdit } from 'react-icons/tb';
import { MdKeyboardArrowDown, MdClose } from 'react-icons/md';
import currencySymbols from 'currency-symbol-map';
import Rating from './rating';
import Select from 'react-select';
import html2pdf from 'html2pdf.js';
import {
  FaBuilding,
  FaClock,
  FaPhoneAlt,
  FaTimes,
  FaCode,
  FaCalendar,
  FaBirthdayCake,
  FaGraduationCap,
  FaUniversity,
  FaUsers,
  FaBriefcase,
  FaClipboardCheck,
  FaTasks,
  FaCheckCircle,
  FaExclamationCircle,
  FaChartBar,
  FaTrophy,
  FaFileAlt,
  FaPlayCircle,
  FaHourglassHalf
} from 'react-icons/fa';
import avatarImg from '../../../images/avatar.webp';
import {
  BsArrowClockwise,
  BsDownload,
  BsEnvelopeFill,
  BsEye,
  BsMapFill
} from 'react-icons/bs';
import axios from 'axios';
import PersonalityReportModal from '../Jobick/Jobs/ui/jobApplicationView/PersonalityReportModal';
import './JobApplicationView.css';
import { IoIosArrowRoundBack } from 'react-icons/io';

const statusOptions = [
  { value: 'INTERVIEW_PROCESS_STARTED', label: 'Interview Process Started' },
  { value: 'INTERVIEWS_COMPLETED', label: 'Interviews Completed' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'OFFER_EXTENDED', label: 'Offer Extended' },
  { value: 'HIRED', label: 'Hired' }
];

const testProcessWithResults = ['SKILL_BASED_MCQ_TEST', 'APTITUDE_MCQ_TEST'];

// Set up the worker for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const JobApplicationView = () => {
  const { jobId, appId } = useParams() || {};
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [jobDetails, setJobDetails] = useState(null);
  const [applicationDetails, setApplicationDetails] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [loading, setLoading] = useState(0);
  const [fetchPersonalityLoading, setFetchPersonalityLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [resumeLink, setResumeLink] = useState(null);
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [personalityTestDetails, setPersonalityTestDetails] = useState(null);
  const [showPersonalityModal, setShowPersonalityModal] = useState(false);
  const [
    showApplicationReattemptLoader,
    setShowApplicationReattemptLoaderFlag
  ] = useState(false);
  const [showPDFDownloadLoader, setShowPDFDownloadLoaderFlag] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState(null);
  const [updateApplicationModal, setUpdateApplicationModal] = useState(false);
  const [applicationReason, setApplicationReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPdfExport, setIsPdfExport] = useState(false);
  const navigate = useNavigate();

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;
  const [openSections, setOpenSections] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState('₹');
  let storedCurrency = localStorage.getItem('currency');
  const pdfContentRef = useRef(null);

  const [testResults, setTestResults] = useState(null);

  // Add these state variables at the top of your component where other useState declarations are
  const [touched, setTouched] = useState({
    status: false,
    reason: false
  });

  // Add this validation function
  const isFormValid = () => {
    return applicationStatus && applicationReason.trim().length >= 10;
  };

  const fetchCandidateTestProcess = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}/application/${appId}/candidate/test-process`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message || 'An error occurred');
        throw new Error(errorData.message || 'API issue');
      }

      const data = await response.json();
      let results = [];
      data.data.forEach((test) => {
        let testName = test.testProcess.type;
        if (testProcessWithResults.includes(testName)) {
          let result = test.result;
          let score = result?.score;
          let percentage = Number(result?.percentage).toFixed(2);
          let totalQuestions = Math.round(score / (percentage / 100));
          let threshold = parseInt(test.testProcess.config?.threshold) || 70;
          if (result && score && percentage) {
            results.push({
              testName,
              result: {
                ...result,
                percentage,
                totalQuestions,
                threshold
              }
            });
          }
        }
      });
      setTestResults(results);

      const objWithPersonality = data.data.find((obj) =>
        Object.hasOwn(obj, 'personalityTest')
      );
      if (objWithPersonality && objWithPersonality.status === 'COMPLETED') {
        const personalityId = objWithPersonality.personalityTest;
        await fetchPersonalityTest(personalityId);
      }
    } catch (error) {
      toast.error(error.message);
      console.error('Fetch error: ', error.message);
      // navigate(`/${orgName}/404`);
    }
  };

  const fetchPersonalityTest = async (id) => {
    let url = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/personality-test/${id}`;
    try {
      setFetchPersonalityLoading(true);
      const response = await axios.get(`${url}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      setFetchPersonalityLoading(false);
      if (response.data.statusCode === 200) {
        const testDetails = response?.data?.data;
        if (testDetails) {
          setPersonalityTestDetails(testDetails);
        }
      }
    } catch (error) {
      setFetchPersonalityLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    storedCurrency = localStorage.getItem('currency');

    if (storedCurrency) {
      const symbol = currencySymbols(storedCurrency) || '₹';
      setCurrencySymbol(symbol);
    }
    fetchCandidateTestProcess();
  }, []);
  const toggleSection = (section) => {
    if (openSections.includes(section)) {
      setOpenSections(openSections.filter((sec) => sec !== section));
    } else {
      if (openSections.length < 2) {
        setOpenSections([...openSections, section]);
      } else {
        // If already two sections are open, replace the oldest open one
        setOpenSections([openSections[1], section]);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setIsResumeModalOpen(false);
      }
    };

    if (isResumeModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isResumeModalOpen]);

  const accessToken = localStorage.getItem('userDetails')
    ? JSON.parse(localStorage.getItem('userDetails')).session.accessToken
    : null;

  if (!accessToken) {
    throw new Error('Access token not found in local storage');
  }

  const fetchJobDetails = async () => {
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}`;
    try {
      setLoading(0);
      const response = await fetch(URL);
      if (!response.ok) {
        navigate('/page-error-404');
        setLoading(2);
        return;
      }
      const body = await response.json();
      const data = body.data;
      const responsibilitiesArray = data.responsibilities
        .split('. ')
        .map((item) => item.trim())
        .filter((item) => item);

      data['responsibilities'] = responsibilitiesArray;
      setJobDetails(data);
      setLoading(1);
    } catch (error) {
      navigate('/page-error-404');
      console.error('Error fetching job details', error);
      setLoading(2);
    }
  };

  const fetchApplicationDetails = async () => {
    try {
      setLoading(0);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}/application/${appId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) {
        setLoading(2);
        navigate('/page-error-404');
        return;
      }

      const body = await response.json();
      const applicationData = body.data.application;
      const userDetail = body.data.userDetails;
      setApplicationDetails(applicationData);
      setApplicationStatus(applicationData.status);
      setUserDetail(userDetail);
      setLoading(1);
    } catch (error) {
      console.error('Error fetching application details', error);
      navigate('/page-error-404');
      setLoading(2);
    }
  };

  const sendReAttemptRequest = async () => {
    try {
      setShowApplicationReattemptLoaderFlag(true);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}/application/${appId}/reattempt`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      const body = await response.json();
      if (response.ok) {
        toast.success('Application submitted for reattempt.');
      } else {
        toast.error(body.error);
      }
    } catch (error) {
      console.error('Error while trying to reattempt application', error);
    } finally {
      setShowApplicationReattemptLoaderFlag(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 940);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const calculateWidth = () => {
    if (isMobile && !isPdfExport) {
      return '100%';
    }
    return '65%';
  };

  const divStyle = {
    width: calculateWidth(),
    padding: isMobile && !isPdfExport ? '0' : 'initial',
    margin: isMobile && !isPdfExport ? '0' : 'initial'
  };

  const colStyle = {
    padding: isMobile && !isPdfExport ? '0' : 'initial',
    margin: isMobile && !isPdfExport ? '0' : 'initial',
    borderRadius: '0.5rem'
  };

  useEffect(() => {
    fetchJobDetails();
    fetchApplicationDetails();
  }, [jobId, appId]);

  const formatStringForFrontend = (str) => {
    if (!str) {
      return 'N/A';
    }
    const words = str.split('_');

    const formatWord = (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    };

    const formattedWords = words.map((word) => {
      if (word.includes(' ')) {
        return word
          .split(' ')
          .map((w) => {
            return formatWord(w);
          })
          .join(' ');
      } else {
        return formatWord(word);
      }
    });

    return formattedWords.join(' ');
  };

  const timeAgo = (pastDate) => {
    if (!pastDate) {
      return 'N/A';
    }
    const currentDate = new Date();
    const pastDateTime = new Date(pastDate);

    const diffInMs = currentDate - pastDateTime;

    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return years === 1 ? '1 year ago' : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? '1 month ago' : `${months} months ago`;
    } else if (weeks > 0) {
      return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
    } else if (days > 0) {
      return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    } else {
      return seconds === 1 ? '1 second ago' : `${seconds} seconds ago`;
    }
  };

  const handleUpdateApplication = () => {
    setUpdateApplicationModal(true);
    setApplicationReason('');
  };

  const handleViewResume = async () => {
    try {
      const objectKey = applicationDetails.resume;
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/media/signed-url`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify({ objectKey })
        }
      );

      const data = await response.json();
      if (response.ok && data.data.signedUrl) {
        setResumeLink(data.data.signedUrl);
        setIsResumeModalOpen(true);
      } else {
        console.error('Fetch error: ', data.message);
        throw new Error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const openLoader = () => {
    const loaderEvent = new CustomEvent('toggleLoader', {
      detail: { action: 'open', title: 'Loading your PDF....' }
    });
    window.dispatchEvent(loaderEvent);
  };

  const closeLoader = () => {
    const loaderEvent = new CustomEvent('toggleLoader', {
      detail: { action: 'close' }
    });
    window.dispatchEvent(loaderEvent);
  };

  const handlePDFDownload = async (previousOpenSections) => {
    const element = pdfContentRef.current;
    const cloneElement = element.cloneNode(true);
    const wrapper = document.createElement('div');
    wrapper.style.padding = '30px'; // Padding inside wrapper
    wrapper.style.width = '1500px';
    cloneElement.style.width = '1440px';
    cloneElement.style.margin = '0 auto'; // Center the content in the wrapper

    // Get candidate name, fallback to "Candidate" if not available
    const candidateName = (
      userDetail?.name ||
      applicationDetails?.resumeAnalysis?.personal_info?.fullName ||
      applicationDetails?.resumeAnalysis?.fullName ||
      'Candidate'
    ).replace(/[^a-zA-Z0-9]/g, '_'); // Replace special characters with underscore

    // Get current Unix timestamp
    const timestamp = Math.floor(Date.now() / 1000);

    // Create dynamic filename
    const filename = `${candidateName}-${timestamp}.pdf`;

    // Apply page break and margin styles via CSS
    const style = document.createElement('style');
    style.textContent = `
    .pdf-section {
      page-break-inside: avoid;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  `;
    document.head.appendChild(style);

    try {
      wrapper.appendChild(cloneElement);
      openLoader();
      const options = {
        filename: filename,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true
        },
        jsPDF: {
          unit: 'mm',
          format: [400, 397],
          orientation: 'portrait'
        },
        margin: [20, 0, 20, 0], // Top, Right, Bottom, Left margins
        pagebreak: { mode: ['avoid-all', 'css'] }
      };

      await html2pdf().set(options).from(wrapper).save();
      setOpenSections(previousOpenSections);
      toast.success('PDF generated successfully!');
    } catch (error) {
      setOpenSections(previousOpenSections);
      toast.error('PDF generation failed');
      console.error(error);
    } finally {
      wrapper.remove();
      closeLoader();
      setShowPDFDownloadLoaderFlag(false);
      setIsPdfExport(false); // Reset PDF export mode
      document.head.removeChild(style); // Clean up added styles
    }
  };

  useEffect(() => {
    if (isPdfExport) {
      const previousOpenSections = openSections;
      setOpenSections(['skills', 'resume', 'status', 'test']);
      setTimeout(() => {
        handlePDFDownload(previousOpenSections);
      }, 0);
    }
  }, [isPdfExport]);

  const updateApplication = async () => {
    try {
      if (!applicationStatus) {
        toast.error('Please select an application status');
        return;
      }

      if (!applicationReason) {
        toast.error('Please provide a reason for rejection');
        return;
      }

      setIsSubmitting(true);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}/application/${appId}/status`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify({
            status: applicationStatus,
            reason: applicationReason
          })
        }
      );

      if (!response.ok) {
        throw new Error(response.message);
      }
      setIsSubmitting(false);
      setUpdateApplicationModal(false);
      setApplicationReason('');
      fetchApplicationDetails();
      toast.success('Application status updated successfully!');
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
      toast.error('Failed to update application status');
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);

  useEffect(() => {
    if (isResumeModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isResumeModalOpen]);

  function getProgressBarClass(profileMatchPercentage) {
    if (profileMatchPercentage <= 50) {
      return 'bg-danger';
    } else if (profileMatchPercentage <= 75) {
      return 'bg-warning';
    } else {
      return 'bg-success';
    }
  }

  const renderExperienceField = (min, max) => {
    if (min || min === 0) {
      if (max) {
        return `${min} - ${max} Years`;
      } else {
        return `${min} Years`;
      }
    } else {
      return '';
    }
  };
  const renderExperienceLabel = (min, max) => {
    if (min || min === 0) {
      if (max) {
        return 'Experience';
      } else {
        return 'Minimum Experience';
      }
    } else {
      return '';
    }
  };

  const ratingColorMap = {
    BEGINNER: 'bg-warning',
    BASIC: 'bg-secondary',
    INTERMEDIATE: 'bg-primary',
    ADVANCED: 'bg-info',
    EXPERT: 'bg-success'
  };

  // Add this style object at the top of your component
  const getHeadingStyle = (isPdfExport) => {
    if (isPdfExport) {
      return {
        color: '#1f2937',
        background: 'none',
        WebkitBackgroundClip: 'unset',
        WebkitTextFillColor: '#1f2937',
        backgroundImage: 'none',
        // Add these additional properties
        backgroundClip: 'unset',
        textFillColor: '#1f2937',
        textShadow: 'none'
      };
    }
    return {
      background: 'linear-gradient(135deg, #1f2937 0%, #4b5563 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    };
  };

  // Add this new component for contact details
  const ContactDetail = ({ icon, text, isMobile }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        color: '#475569',
        fontSize: isMobile ? '0.875rem' : '1rem',
        width: '100%',
        gap: '1rem',
        padding: '0.75rem 1rem',
        background: '#f8fafc',
        borderRadius: '8px',
        border: '1px solid #e2e8f0',
        transition: 'all 0.2s ease',
        cursor: 'default',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        maxWidth: '100%', // Always 100% max-width
        overflow: 'hidden' // Add overflow hidden to container
      }}
    >
      <span
        style={{
          color: '#6366f1',
          minWidth: '20px',
          minHeight: '20px',
          flexShrink: 0,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {icon}
      </span>
      <span
        style={{
          fontWeight: '500',
          flex: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          minWidth: 0,
          maxWidth: 'calc(100% - 40px)' // Account for icon width and gap
        }}
      >
        {text}
      </span>
    </div>
  );

  // Add these helper functions
  const getProfileMatchColor = (applicationDetails) => {
    const percentage =
      (applicationDetails.finalCandidateAnalysis &&
        applicationDetails.finalCandidateAnalysis.profileMatchPercentage) ||
      (applicationDetails.candidateAnalysis &&
        applicationDetails.candidateAnalysis.profileMatchPercentage) ||
      0;

    if (percentage <= 40) {
      return '#f56565';
    }
    if (percentage <= 70) {
      return '#ed8936';
    }
    return 'rgb(34 197 94)';
  };

  const getProfileMatchGradient = (applicationDetails) => {
    const percentage =
      (applicationDetails.finalCandidateAnalysis &&
        applicationDetails.finalCandidateAnalysis.profileMatchPercentage) ||
      (applicationDetails.candidateAnalysis &&
        applicationDetails.candidateAnalysis.profileMatchPercentage) ||
      0;

    if (percentage <= 40) {
      return 'linear-gradient(135deg, #ef4444 0%, #dc2626 100%)';
    }
    if (percentage <= 70) {
      return 'linear-gradient(135deg, #f59e0b 0%, #d97706 100%)';
    }
    return 'linear-gradient(135deg, #22c55e 0%, #16a34a 100%)';
  };

  const getProfileMatchShadow = (applicationDetails) => {
    const percentage =
      (applicationDetails.finalCandidateAnalysis &&
        applicationDetails.finalCandidateAnalysis.profileMatchPercentage) ||
      (applicationDetails.candidateAnalysis &&
        applicationDetails.candidateAnalysis.profileMatchPercentage) ||
      0;

    if (percentage <= 40) {
      return '0 8px 32px rgba(239, 68, 68, 0.3)';
    }
    if (percentage <= 70) {
      return '0 8px 32px rgba(245, 158, 11, 0.3)';
    }
    return '0 8px 32px rgba(34, 197, 94, 0.3)';
  };

  // Add these helper functions near the top of your component
  const getStatusColor = (status) => {
    switch (status) {
      case 'APPLICATION_SUBMITTED':
        return '#3B82F6'; // blue
      case 'PROFILE_REVIEW_COMPLETED':
        return '#8B5CF6'; // purple
      case 'AI_REVIEW_COMPLETED':
        return '#10B981'; // green
      case 'ASSESSMENTS_IN_PROGRESS':
        return '#F59E0B'; // amber
      case 'ASSESSMENTS_COMPLETED':
        return '#6366F1'; // indigo
      case 'INTERVIEW_PROCESS_STARTED':
        return '#EC4899'; // pink
      case 'INTERVIEWS_COMPLETED':
        return '#14B8A6'; // teal
      case 'CANDIDATE_WITHDRAWN':
        return '#6B7280'; // gray
      case 'REJECTED':
        return '#EF4444'; // red
      case 'OFFER_EXTENDED':
        return '#8B5CF6'; // purple
      case 'HIRED':
        return '#059669'; // emerald
      default:
        return '#6B7280'; // gray
    }
  };

  const formatStatus = (status) => {
    if (!status) {
      return 'N/A';
    }

    // Split by underscore and capitalize each word
    return status
      .split('_')
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join(' ');
  };

  const handleBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/jobs'); // Default fallback
    }
  };

  return (
    <div className={isPdfExport ? 'pdf-export' : ''}>
      {personalityTestDetails && (
        <PersonalityReportModal
          showModal={showPersonalityModal}
          setModal={setShowPersonalityModal}
          personalityTestDetails={personalityTestDetails}
        />
      )}
      <style>
        {`
        .modal-dialog{
           max-width: max-content !important;
        }

        .accordion-button:focus {
          z-index: auto;
          border-color: var(--headerbg);
        }
        tr:last-child{
          border-bottom:0px !important;
        }
        `}
      </style>
      <div className="job-application-header">
        <div className="header-content">
          <div className="d-flex align-items-center">
            <div
              onClick={handleBack}
              style={{
                cursor: 'pointer',
                marginRight: '1rem',
                transition: 'transform 0.2s ease',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = 'scale(1.1)';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
              }}
            >
              <IoIosArrowRoundBack size={32} />
            </div>
            <h3 className="header-title">Job Application View</h3>
          </div>
          <div className="header-actions">
            {applicationDetails && applicationDetails.status === 'REJECTED' && (
              <Button
                variant="outline-primary"
                disabled={showApplicationReattemptLoader}
                style={{
                  width: isMobile && !isPdfExport ? 'auto' : '210px',
                  backgroundColor: showApplicationReattemptLoader
                    ? 'var(--primary-hover)'
                    : ''
                }}
                onClick={sendReAttemptRequest}
                className="d-flex justify-content-center align-items-center gap-2"
              >
                {showApplicationReattemptLoader ? (
                  <>
                    Updating..{' '}
                    <Spinner style={{ width: '1rem', height: '1rem' }} />
                  </>
                ) : (
                  <>
                    Allow Reattempt{' '}
                    <BsArrowClockwise className="text-primary" />
                  </>
                )}
              </Button>
            )}

            {personalityTestDetails && (
              <Button
                variant="primary"
                disabled={!personalityTestDetails}
                style={{ width: 'auto' }}
                onClick={() => {
                  setShowPersonalityModal(true);
                }}
                className="d-flex justify-content-center align-items-center gap-2 bg--priamry"
              >
                View Personality Report
                <BsEye size={20} />
              </Button>
            )}

            {applicationDetails && (
              <Button
                variant="primary"
                disabled={showPDFDownloadLoader}
                style={
                  isMobile && !isPdfExport
                    ? { width: 'auto' }
                    : { width: '190px' }
                }
                onClick={() => {
                  setIsPdfExport(true);
                  setShowPDFDownloadLoaderFlag(true);
                  openLoader();
                }}
                className="d-flex justify-content-center align-items-center gap-2"
              >
                {showPDFDownloadLoader ? (
                  <>
                    Downloading{' '}
                    <Spinner style={{ width: '1rem', height: '1rem' }} />
                  </>
                ) : (
                  <>
                    Download PDF <BsDownload />
                  </>
                )}
              </Button>
            )}

            {applicationDetails &&
              applicationDetails?.status !== 'REJECTED' && (
                <Button
                  variant="primary"
                  style={isMobile && !isPdfExport ? { width: 'auto' } : {}}
                  onClick={handleUpdateApplication}
                  className="d-flex justify-content-center align-items-center gap-2"
                >
                  Update Application <TbEdit />
                </Button>
              )}
          </div>
        </div>
      </div>
      {loading === 0 && (
        <div className="d-flex flex-column align-items-center justify-content-center gap-5 mt-5 pt-5">
          <div className="mt-5 mb-5 pt-5 pb-4">
            <div className="mt-2"></div>
          </div>
          <Spinner animation="border mb-3" style={{ color: '#858585' }} />
        </div>
      )}
      {loading === 2 && (
        <Alert variant="danger">Failed to load data. Please try again.</Alert>
      )}
      {loading === 1 && jobDetails && applicationDetails && (
        <>
          <div class="container py-8" style={{ maxWidth: 'fit-content' }}>
            <div ref={pdfContentRef}>
              {/* <!-- Applicant Overview --> */}
              <div
                style={{
                  backgroundColor: 'white',
                  borderRadius: '1rem',
                  boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
                  marginBottom: '1.5rem',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  position: 'relative',
                  overflow: 'hidden'
                }}
              >
                {/* Background gradient overlay */}
                <div
                  style={{
                    position: 'absolute',
                    inset: 0,
                    background: 'linear-gradient(to right, #4299e1, #a855f7)',
                    opacity: 0.2
                  }}
                  className="shadow-xl"
                ></div>

                {/* Content container */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '2rem',
                    width: '100%',
                    justifyContent: 'space-between',
                    flexDirection: isMobile && !isPdfExport ? 'column' : 'row',
                    gap: isMobile && !isPdfExport ? '2rem' : '0'
                  }}
                >
                  {/* Left section with photo and details */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection:
                        isMobile && !isPdfExport ? 'column' : 'row',
                      gap: '2rem',
                      alignItems:
                        isMobile && !isPdfExport ? 'center' : 'flex-start',
                      width: isMobile && !isPdfExport ? '100%' : 'auto'
                    }}
                  >
                    {/* Photo container */}
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <div
                        style={{
                          width: isMobile && !isPdfExport ? '120px' : '100px',
                          height: isMobile && !isPdfExport ? '120px' : '100px',
                          borderRadius: '50%',
                          overflow: 'hidden',
                          border: '4px solid white',
                          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          src={avatarImg}
                          alt="Applicant Photo"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                          }}
                        />
                      </div>
                    </div>

                    {/* Details container */}
                    <div
                      style={{
                        position: 'relative',
                        textAlign: isMobile && !isPdfExport ? 'center' : 'left',
                        display: 'flex',
                        gap: '1rem',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: isMobile && !isPdfExport ? '100%' : 'auto'
                      }}
                    >
                      {/* Name */}
                      <h2
                        style={{
                          fontSize:
                            isMobile && !isPdfExport ? '1.5rem' : '1.75rem',
                          fontWeight: 'bold',
                          color: '#2d3748',
                          margin: 0
                        }}
                      >
                        {userDetail.name ||
                          applicationDetails.resumeAnalysis.personal_info
                            .fullName ||
                          applicationDetails.resumeAnalysis.fullName ||
                          'N/A'}
                      </h2>

                      {/* Contact details grid */}
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns:
                            isMobile && !isPdfExport ? '1fr' : 'repeat(3, 1fr)',
                          gap: '0.75rem',
                          width: '100%'
                        }}
                      >
                        {/* Email */}
                        <ContactDetail
                          icon={<BsEnvelopeFill />}
                          text={
                            userDetail.email ||
                            applicationDetails.resumeAnalysis.personal_info
                              .email ||
                            applicationDetails.resumeAnalysis.email
                          }
                          isMobile={isMobile && !isPdfExport}
                        />

                        {/* Phone */}
                        <ContactDetail
                          icon={<FaPhoneAlt />}
                          text={
                            userDetail.phone ||
                            applicationDetails.resumeAnalysis.personal_info
                              .phone ||
                            applicationDetails.resumeAnalysis.phone ||
                            'N/A'
                          }
                          isMobile={isMobile && !isPdfExport}
                        />

                        {/* Address */}
                        <ContactDetail
                          icon={<BsMapFill />}
                          text={
                            userDetail.address ||
                            applicationDetails.resumeAnalysis.personal_info
                              .address ||
                            applicationDetails.resumeAnalysis.address ||
                            'N/A'
                          }
                          isMobile={isMobile && !isPdfExport}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Profile match percentage */}
                  <div
                    style={{
                      backgroundColor: getProfileMatchColor(applicationDetails),
                      color: 'white',
                      fontWeight: '800',
                      fontSize: isMobile && !isPdfExport ? '2rem' : '2.25rem',
                      borderRadius: '50%',
                      minWidth: isMobile && !isPdfExport ? '100px' : '110px',
                      maxWidth: isMobile && !isPdfExport ? '100px' : '110px',
                      height: isMobile && !isPdfExport ? '100px' : '110px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: getProfileMatchGradient(applicationDetails),
                      boxShadow: getProfileMatchShadow(applicationDetails),
                      transition: 'all 0.3s ease',
                      animation: 'float 3s ease-in-out infinite',
                      border: '3px solid rgba(255, 255, 255, 0.3)',
                      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                      marginTop: isMobile && !isPdfExport ? '-1rem' : '0'
                    }}
                  >
                    {(applicationDetails.finalCandidateAnalysis &&
                      applicationDetails.finalCandidateAnalysis
                        .profileMatchPercentage) ||
                      (applicationDetails.candidateAnalysis &&
                        applicationDetails.candidateAnalysis
                          .profileMatchPercentage) ||
                      'N/A'}
                    %
                  </div>
                </div>
              </div>

              {/* <!-- Application Details --> */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobile && !isPdfExport ? 'column' : 'row'
                }}
              >
                <div
                  class=""
                  style={{ width: isMobile && !isPdfExport ? '100%' : '70%' }}
                >
                  {/* <!-- Left Column --> */}
                  <div class="lg:col-span-2">
                    {/* <!-- Description Section --> */}

                    <div
                      class="bg-white rounded-xl shadow-xl  mb-8 relative overflow-hidden"
                      style={{
                        borderRadius: '1rem',
                        marginBottom: '1.5rem',
                        padding: isMobile && !isPdfExport ? '2.2rem' : '2rem',
                        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      <div class="relative z-10">
                        <h3
                          className="font-bold text-gray-800 mb-6"
                          style={{
                            ...(isPdfExport
                              ? {
                                  fontSize: '1.875rem',
                                  lineHeight: '2.25rem',
                                  color: '#1f2937',
                                  fontWeight: '700',
                                  marginBottom: '1.5rem',
                                  background: 'none',
                                  WebkitBackgroundClip: 'unset',
                                  WebkitTextFillColor: '#1f2937',
                                  backgroundImage: 'none'
                                }
                              : {
                                  fontSize: '1.875rem',
                                  lineHeight: '2.25rem',
                                  color: '#1f2937',
                                  fontWeight: '700',
                                  marginBottom: '1.5rem',
                                  background:
                                    'linear-gradient(135deg, #1f2937 0%, #4b5563 100%)',
                                  WebkitBackgroundClip: 'text',
                                  WebkitTextFillColor: 'transparent'
                                })
                          }}
                        >
                          Description
                        </h3>
                        <div className="w-full">
                          <div className="d-flex flex-column">
                            {applicationDetails.resumeAnalysis
                              ?.workExperience[0]?.role && (
                              <div
                                className="description-row"
                                style={{ '--delay': '0.1s' }}
                              >
                                <div className="description-label">
                                  <FaBriefcase />
                                  Current Designation
                                </div>
                                <div className="description-value">
                                  {applicationDetails.resumeAnalysis
                                    ?.workExperience[0]?.role || 'N/A'}
                                </div>
                              </div>
                            )}

                            {applicationDetails.resumeAnalysis
                              ?.workExperience[0]?.company && (
                              <div
                                className="description-row"
                                style={{ '--delay': '0.3s' }}
                              >
                                <div className="description-label">
                                  <FaBuilding />
                                  Current Company
                                </div>
                                <div className="description-value">
                                  {applicationDetails.resumeAnalysis
                                    ?.workExperience[0]?.company || 'N/A'}
                                </div>
                              </div>
                            )}

                            <div
                              className="description-row"
                              style={{ '--delay': '0.5s' }}
                            >
                              <div className="description-label">
                                <FaClock />
                                Total Experience
                              </div>
                              <div className="description-value">
                                {userDetail.experience ||
                                  applicationDetails.resumeAnalysis
                                    ?.totalExperience ||
                                  'N/A'}{' '}
                                {userDetail.experience ||
                                applicationDetails.resumeAnalysis
                                  ?.totalExperience
                                  ? ' Years'
                                  : ''}
                              </div>
                            </div>

                            {userDetail?.currentCTC !== undefined &&
                              userDetail?.currentCTC !== null && (
                                <div
                                  className="description-row"
                                  style={{ '--delay': '0.7s' }}
                                >
                                  <div className="description-label">
                                    <FaPhoneAlt />
                                    Current CTC ({storedCurrency})
                                  </div>
                                  <div className="description-value">
                                    {userDetail.currentCTC > 0
                                      ? formatToCurrency(userDetail.currentCTC)
                                      : 'N/A'}
                                  </div>
                                </div>
                              )}

                            {/* Status */}
                            <div className="description-row">
                              <div className="description-label">
                                <FaClipboardCheck />
                                Status
                              </div>
                              <div className="description-value">
                                <span
                                  style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    padding: '0.375rem 0.75rem',
                                    borderRadius: '9999px',
                                    fontSize: '0.875rem',
                                    fontWeight: '500',
                                    backgroundColor: `${getStatusColor(applicationDetails.status)}15`,
                                    color: getStatusColor(
                                      applicationDetails.status
                                    ),
                                    border: `1px solid ${getStatusColor(applicationDetails.status)}30`
                                  }}
                                >
                                  {formatStatus(applicationDetails.status)}
                                </span>
                              </div>
                            </div>

                            {applicationDetails.applicationDate && (
                              <div
                                className="description-row"
                                style={{ '--delay': '1.1s' }}
                              >
                                <div className="description-label">
                                  <FaCalendar />
                                  Application Date
                                </div>
                                <div className="description-value">
                                  {applicationDetails.applicationDate
                                    ? new Intl.DateTimeFormat('en-GB', {
                                        day: 'numeric',
                                        month: 'long',
                                        year: 'numeric'
                                      }).format(
                                        new Date(
                                          applicationDetails.applicationDate
                                        )
                                      )
                                    : 'N/A'}
                                </div>
                              </div>
                            )}

                            {applicationDetails.noticePeriodDays && (
                              <div
                                className="description-row"
                                style={{ '--delay': '1.1s' }}
                              >
                                <div className="description-label">
                                  <FaHourglassHalf />
                                  Notice Period Days
                                </div>
                                <div className="description-value">
                                  {applicationDetails.noticePeriodDays} Days
                                </div>
                              </div>
                            )}

                            {applicationDetails.earliestStartDate && (
                              <div
                                className="description-row"
                                style={{ '--delay': '1.1s' }}
                              >
                                <div className="description-label">
                                  <FaPlayCircle />
                                  Earliest Start Date
                                </div>
                                <div className="description-value">
                                  {applicationDetails.earliestStartDate
                                    ? new Intl.DateTimeFormat('en-GB', {
                                        day: 'numeric',
                                        month: 'long',
                                        year: 'numeric'
                                      }).format(
                                        new Date(
                                          applicationDetails.earliestStartDate
                                        )
                                      )
                                    : 'N/A'}
                                </div>
                              </div>
                            )}

                            <div
                              className="description-row"
                              style={{ '--delay': '1.3s' }}
                            >
                              <div className="description-label">
                                <FaBirthdayCake />
                                Date of Birth
                              </div>
                              <div className="description-value">
                                {userDetail.dob
                                  ? new Intl.DateTimeFormat('en-GB', {
                                      day: 'numeric',
                                      month: 'long',
                                      year: 'numeric'
                                    }).format(new Date(userDetail.dob))
                                  : 'N/A'}
                              </div>
                            </div>

                            <div
                              className="description-row"
                              style={{ '--delay': '1.5s' }}
                            >
                              <div className="description-label">
                                <FaGraduationCap />
                                Highest Qualification
                              </div>
                              <div className="description-value">
                                {applicationDetails.resumeAnalysis
                                  ?.highestEducation || 'N/A'}
                              </div>
                            </div>

                            <div
                              className="description-row"
                              style={{ '--delay': '1.7s' }}
                            >
                              <div className="description-label">
                                <FaUniversity />
                                University
                              </div>
                              <div className="description-value">
                                {applicationDetails.resumeAnalysis
                                  ?.university || 'N/A'}
                              </div>
                            </div>

                            {/* Technical Skills */}
                            <div
                              className="description-row"
                              style={{ '--delay': '0.8s' }}
                            >
                              <div className="description-label">
                                <FaCode />
                                Technical Skills
                              </div>
                              <div className="description-value">
                                <div className="d-flex flex-wrap gap-2">
                                  {applicationDetails.resumeAnalysis?.technicalSkills?.map(
                                    (skill, index) => (
                                      <span
                                        key={index}
                                        className="skill-tag"
                                        style={{ '--delay': `${0.1 * index}s` }}
                                      >
                                        {skill}
                                      </span>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>

                            {applicationDetails.resumeAnalysis
                              ?.nonTechnicalSkills.length ? (
                              <div
                                className="description-row"
                                style={{ '--delay': '2.1s' }}
                              >
                                <div className="description-label">
                                  <FaUsers />
                                  Soft Skills
                                </div>
                                <div className="description-value">
                                  <div className="d-flex flex-wrap gap-2">
                                    {applicationDetails.resumeAnalysis?.nonTechnicalSkills.map(
                                      (skill) => (
                                        <span
                                          key={skill}
                                          className="skill-tag"
                                          style={{
                                            '--delay': `${0.1 * skill}s`
                                          }}
                                        >
                                          {skill}
                                        </span>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    {/* <!-- Work Experience Section --> */}
                    <div
                      className="work-experience-section"
                      style={{
                        marginTop: isPdfExport ? '18rem' : '0' // Add margin for PDF export
                      }}
                    >
                      <h3 style={getHeadingStyle(isPdfExport)}>
                        Work Experience
                      </h3>
                      <div className="space-y-6">
                        {applicationDetails.resumeAnalysis.workExperience.map(
                          (exp, index) => (
                            <div
                              className="experience-card"
                              key={index}
                              style={{ '--delay': index }}
                            >
                              <div className="experience-role">
                                <FaBriefcase />
                                {exp.role || 'N/A'}
                              </div>

                              <div className="experience-company">
                                <FaBuilding />
                                {exp.company || 'N/A'}
                              </div>

                              <div className="experience-duration">
                                <FaClock />
                                {exp.duration || 'N/A'}
                              </div>

                              {exp.responsibilities &&
                                exp.responsibilities.length > 0 && (
                                  <div className="responsibilities-section">
                                    <div className="responsibilities-title">
                                      <FaTasks />
                                      Responsibilities
                                    </div>
                                    <ul className="responsibilities-list">
                                      {exp.responsibilities.map(
                                        (responsibility, respIndex) => (
                                          <li
                                            key={respIndex}
                                            className="responsibility-item"
                                          >
                                            {responsibility}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    {/* <!-- Final Candidate Analysis --> */}
                    <div
                      className="candidate-analysis-section"
                      style={{
                        marginTop: isPdfExport ? '28rem' : '0' // Add margin for PDF export
                      }}
                    >
                      <h3 style={getHeadingStyle(isPdfExport)}>
                        {applicationDetails.finalCandidateAnalysis
                          ? 'Candidate Evaluation Summary'
                          : applicationDetails.candidateAnalysis
                            ? 'Initial Assessment Report'
                            : 'Candidate Analysis'}
                      </h3>
                      <div className="analysis-content">
                        {applicationDetails.finalCandidateAnalysis ? (
                          <>
                            <div className="analysis-card pros-section">
                              <div className="analysis-header">
                                <div className="header-icon pros-icon">
                                  <FaCheckCircle className="analysis-icon" />
                                </div>
                                <h4>Strengths & Qualifications</h4>
                              </div>
                              <ul className="analysis-list">
                                {applicationDetails.finalCandidateAnalysis.pros.map(
                                  (pro, index) => (
                                    <li
                                      key={index}
                                      className="analysis-item pros-item"
                                    >
                                      <div className="bullet-point">✓</div>
                                      <span>{pro || 'N/A'}</span>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>

                            <div className="analysis-card cons-section">
                              <div className="analysis-header">
                                <div className="header-icon cons-icon">
                                  <FaExclamationCircle className="analysis-icon" />
                                </div>
                                <h4>Areas for Consideration</h4>
                              </div>
                              <ul className="analysis-list">
                                {applicationDetails.finalCandidateAnalysis.cons.map(
                                  (con, index) => (
                                    <li
                                      key={index}
                                      className="analysis-item cons-item"
                                    >
                                      <div className="bullet-point">!</div>
                                      <span>{con || 'N/A'}</span>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </>
                        ) : applicationDetails.candidateAnalysis ? (
                          <>
                            <div className="analysis-card pros-section">
                              <div className="analysis-header">
                                <div className="header-icon pros-icon">
                                  <FaCheckCircle className="analysis-icon" />
                                </div>
                                <h4>Key Strengths</h4>
                              </div>
                              <ul className="analysis-list">
                                {applicationDetails.candidateAnalysis.pros.map(
                                  (pro, index) => (
                                    <li
                                      key={index}
                                      className="analysis-item pros-item"
                                    >
                                      <div className="bullet-point">✓</div>
                                      <span>{pro || 'N/A'}</span>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>

                            <div className="analysis-card cons-section">
                              <div className="analysis-header">
                                <div className="header-icon cons-icon">
                                  <FaExclamationCircle className="analysis-icon" />
                                </div>
                                <h4>Development Areas</h4>
                              </div>
                              <ul className="analysis-list">
                                {applicationDetails.candidateAnalysis.cons.map(
                                  (con, index) => (
                                    <li
                                      key={index}
                                      className="analysis-item cons-item"
                                    >
                                      <div className="bullet-point">!</div>
                                      <span>{con || 'N/A'}</span>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </>
                        ) : (
                          <p className="no-analysis">
                            No analysis data available
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <!-- Education Section --> */}
                    <div
                      className="education-section"
                      style={{
                        marginTop: isPdfExport ? '30rem' : '0' // Add margin for PDF export
                      }}
                    >
                      <h3 style={getHeadingStyle(isPdfExport)}>
                        <FaGraduationCap className="section-icon" />
                        Educational Background
                      </h3>
                      <div className="education-timeline">
                        {applicationDetails.resumeAnalysis.education.map(
                          (edu, index) => (
                            <div
                              className="education-card"
                              key={index}
                              style={{ '--delay': index }}
                            >
                              <div
                                className="education-header"
                                style={{
                                  flexDirection:
                                    isMobile && !isPdfExport ? 'column' : 'row',
                                  gap: isMobile && !isPdfExport ? '0.5rem' : '0'
                                }}
                              >
                                <div className="degree-info">
                                  <h4 className="degree-name">
                                    {edu.degree || 'N/A'}
                                  </h4>
                                  <p className="institution-name">
                                    <FaUniversity className="icon" />
                                    {edu.institution || 'N/A'}
                                  </p>
                                </div>
                                <div
                                  className="education-meta"
                                  style={{
                                    marginLeft:
                                      isMobile && !isPdfExport ? '0' : 'auto',
                                    marginTop:
                                      isMobile && !isPdfExport ? '0.5rem' : '0',
                                    maxWidth: '100%' // Ensure container doesn't overflow
                                  }}
                                >
                                  {edu.attended && (
                                    <span
                                      className="education-year"
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        maxWidth: '100%',
                                        overflow: 'hidden'
                                      }}
                                    >
                                      <FaCalendar
                                        className="icon"
                                        style={{
                                          flexShrink: 0,
                                          marginRight: '0.5rem'
                                        }}
                                      />
                                      <span
                                        style={{
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis'
                                        }}
                                      >
                                        {edu.attended}
                                      </span>
                                    </span>
                                  )}
                                  {edu.grade && (
                                    <span
                                      className="education-grade"
                                      style={{
                                        marginLeft:
                                          isMobile && !isPdfExport
                                            ? '0'
                                            : '1rem',
                                        marginTop:
                                          isMobile && !isPdfExport
                                            ? '0.5rem'
                                            : '0',
                                        display:
                                          isMobile && !isPdfExport
                                            ? 'flex'
                                            : 'inline-flex',
                                        alignItems: 'center',
                                        maxWidth: '100%',
                                        overflow: 'hidden'
                                      }}
                                    >
                                      <FaChartBar
                                        className="icon"
                                        style={{
                                          flexShrink: 0,
                                          marginRight: '0.5rem'
                                        }}
                                      />
                                      <span
                                        style={{
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis'
                                        }}
                                      >
                                        {edu.grade}
                                      </span>
                                    </span>
                                  )}
                                </div>
                              </div>

                              {edu.specialization && (
                                <div className="specialization-info">
                                  <span className="label">Specialization:</span>
                                  <span className="value">
                                    {edu.specialization}
                                  </span>
                                </div>
                              )}

                              {edu.achievements &&
                                edu.achievements.length > 0 && (
                                  <div className="achievements-section">
                                    <h5 className="achievements-title">
                                      <FaTrophy className="icon" />
                                      Key Achievements
                                    </h5>
                                    <ul className="achievements-list">
                                      {edu.achievements.map(
                                        (achievement, idx) => (
                                          <li
                                            key={idx}
                                            className="achievement-item"
                                          >
                                            {achievement}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                )}
                            </div>
                          )
                        )}
                      </div>
                    </div>

                    {/* <!-- Accordions Section --> */}
                    {(applicationDetails.skillRatings ||
                      applicationDetails.resumeAnswers ||
                      applicationDetails.statusHistory) && (
                      <div
                        className="bg-white p-6 mb-8 page-break-before"
                        style={{
                          borderRadius: '1rem',
                          boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
                          marginBottom: '1.5rem',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '1rem'
                        }}
                      >
                        {/* Skills Rating Section */}
                        {applicationDetails.skillRatings && (
                          <div style={{ width: '100%' }} className="no-break">
                            <button
                              onClick={() => toggleSection('skills')}
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textAlign: 'left',
                                backgroundColor: '#dbeafe',
                                padding: '1rem',
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: '#1f2937',
                                border: 'none',
                                borderRadius: !openSections.includes('skills')
                                  ? '0.5rem'
                                  : '0.5rem 0.5rem 0 0',
                                cursor: 'pointer',
                                pageBreakAfter: 'avoid'
                              }}
                            >
                              Skills Rating
                              {openSections.includes('skills') ? (
                                <MdClose />
                              ) : (
                                <MdKeyboardArrowDown className="ml-2" />
                              )}
                            </button>
                            {openSections.includes('skills') && (
                              <div
                                style={{
                                  backgroundColor: '#f9fafb',
                                  pageBreakBefore: 'avoid',
                                  padding: '1rem',
                                  borderRadius: '0 0 8px 8px'
                                }}
                              >
                                <div
                                  className="row"
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '5%'
                                  }}
                                >
                                  {applicationDetails.skillRatings.map(
                                    (skill, index) => (
                                      <div
                                        key={index}
                                        className="col-md-6 mb-3 no-break"
                                        style={{
                                          backgroundColor: '#fff',
                                          margin: '0 2.5%',
                                          width: '95%',
                                          padding: '1.5rem',
                                          borderRadius: '0.5rem',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          marginBottom: '0.5rem'
                                        }}
                                      >
                                        <Rating
                                          disableEvents={true}
                                          skill={skill.skill}
                                          rating={skill.rating}
                                          index={index + 1}
                                          onRatingChange={() => {}}
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {/* Resume Answers Section */}
                        {applicationDetails.resumeAnswers && (
                          <div className="no-break">
                            <button
                              onClick={() => toggleSection('resume')}
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textAlign: 'left',
                                backgroundColor: '#dbeafe', // Light blue background color
                                padding: '1rem',
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: '#1f2937', // Darker text color
                                border: 'none',
                                borderRadius: !openSections.includes('resume')
                                  ? '0.5rem'
                                  : '0.5rem 0.5rem 0 0',
                                cursor: 'pointer'
                              }}
                            >
                              Resume Answers
                              {openSections.includes('resume') ? (
                                <MdClose />
                              ) : (
                                <MdKeyboardArrowDown className="ml-2" />
                              )}
                            </button>
                            {openSections.includes('resume') && (
                              <div
                                style={{
                                  backgroundColor: '#f9fafb', // Light gray background color
                                  padding: '1rem',
                                  borderRadius: '0 0 8px 8px' // Rounded bottom corners
                                }}
                              >
                                <div className="space-y-4">
                                  {applicationDetails.resumeAnswers.map(
                                    (qa, index) => (
                                      <div
                                        key={index}
                                        style={{ pageBreakInside: 'auto' }}
                                      >
                                        <h4 className="font-bold text-gray-800">
                                          Q: {qa.question || 'N/A'}
                                        </h4>
                                        <p className="text-gray-600">
                                          A: {qa.answer || 'N/A'}
                                        </p>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {/* Application Status Progress Section */}
                        {applicationDetails.statusHistory && (
                          <div className="no-break">
                            <button
                              onClick={() => toggleSection('status')}
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textAlign: 'left',
                                backgroundColor: '#dbeafe',
                                padding: '1rem',
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: '#1f2937',
                                border: 'none',
                                borderRadius: !openSections.includes('resume')
                                  ? '0.5rem'
                                  : '0.5rem 0.5rem 0 0',
                                cursor: 'pointer'
                              }}
                            >
                              Application Status Progress
                              {openSections.includes('status') ? (
                                <MdClose />
                              ) : (
                                <MdKeyboardArrowDown className="ml-2" />
                              )}
                            </button>
                            {openSections.includes('status') && (
                              <div
                                style={{
                                  backgroundColor: '#f9fafb', // Light gray background color
                                  padding: '1rem',
                                  borderRadius: '0 0 8px 8px' // Rounded bottom corners
                                }}
                              >
                                <div className="can-break card-body d-block">
                                  <ApplicationStatusProgress
                                    statusHistory={
                                      applicationDetails.statusHistory
                                    }
                                    isPdfExport={isPdfExport}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {/* Test Results Section */}
                        {testResults && testResults.length > 0 && (
                          <div className="no-break">
                            <button
                              onClick={() => toggleSection('test')}
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                textAlign: 'left',
                                backgroundColor: '#dbeafe',
                                padding: '1rem',
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: '#1f2937',
                                border: 'none',
                                borderRadius: !openSections.includes('resume')
                                  ? '0.5rem'
                                  : '0.5rem 0.5rem 0 0',
                                cursor: 'pointer'
                              }}
                            >
                              Test Results
                              {openSections.includes('test') ? (
                                <MdClose />
                              ) : (
                                <MdKeyboardArrowDown className="ml-2" />
                              )}
                            </button>
                            {openSections.includes('test') && (
                              <div
                                style={{
                                  backgroundColor: '#f9fafb', // Light gray background color
                                  padding: '1rem',
                                  borderRadius: '0 0 8px 8px' // Rounded bottom corners
                                }}
                              >
                                <div className="card-body d-block">
                                  {testResults.map((test) => (
                                    <div
                                      key={test.testName}
                                      className="mb-4 p-4 bg-white rounded-lg shadow-sm"
                                    >
                                      <h4 className="text-lg font-semibold mb-2 d-flex align-items-center justify-content-between">
                                        <span>
                                          {formatStringForFrontend(
                                            test.testName
                                          )}
                                        </span>
                                        <span
                                          className={`badge ${test.result.percentage >= test.result.threshold ? 'bg-success' : 'bg-danger'}`}
                                          style={{
                                            fontSize: '0.8rem',
                                            padding: '0.35em 0.65em'
                                          }}
                                        >
                                          {test.result.percentage >=
                                          test.result.threshold
                                            ? 'PASS'
                                            : 'FAIL'}
                                        </span>
                                      </h4>

                                      <div className="mb-3">
                                        <div className="d-flex justify-content-between mb-2">
                                          <h5>
                                            <span style={{ color: '#718096' }}>
                                              Score:{' '}
                                            </span>
                                            {test.result &&
                                            test.result.score &&
                                            test.result.totalQuestions ? (
                                              <>
                                                {test.result.score}/
                                                {test.result.totalQuestions}
                                              </>
                                            ) : (
                                              'N/A'
                                            )}
                                          </h5>
                                          <span className="font-semibold">
                                            {test.result.percentage}%
                                          </span>
                                        </div>

                                        <div className="position-relative">
                                          <div
                                            className="position-absolute w-100 d-flex justify-content-end"
                                            style={{
                                              top: '-12px',
                                              zIndex: 1
                                            }}
                                          >
                                            <div
                                              className="d-flex flex-column align-items-center"
                                              style={{
                                                position: 'absolute',
                                                left: `${test.result.threshold}%`
                                              }}
                                            >
                                              <div
                                                style={{
                                                  width: '2px',
                                                  height: '30px',
                                                  backgroundColor: '#dc3545',
                                                  marginTop: '2px'
                                                }}
                                              />
                                            </div>
                                          </div>

                                          <div
                                            className="progress"
                                            style={{ height: '10px' }}
                                          >
                                            <div
                                              className={`progress-bar ${
                                                test.result.percentage >=
                                                test.result.threshold
                                                  ? 'bg-success'
                                                  : test.result.percentage >= 50
                                                    ? 'bg-warning'
                                                    : 'bg-danger'
                                              }`}
                                              role="progressbar"
                                              style={{
                                                width: `${test.result.percentage}%`,
                                                transition:
                                                  'width 1s ease-in-out'
                                              }}
                                              aria-valuenow={
                                                test.result.percentage
                                              }
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {/* <!-- Footer Action Bar --> */}
                  </div>
                  {!isMobile && !isPdfExport && (
                    <div className="profile-stats-grid">
                      {/* Stats Row */}
                      <div className="stats-row">
                        {/* Current Company Stats */}
                        <div className="stat-card">
                          <div className="stat-icon">
                            <FaBriefcase />
                          </div>
                          <div className="stat-content">
                            <div className="stat-label">
                              Current Organization
                            </div>
                            <div className="stat-value">
                              {applicationDetails?.resumeAnalysis
                                ?.workExperience?.[0]?.company ||
                                'Not Specified'}
                            </div>
                          </div>
                        </div>

                        {/* Total Experience Stats */}
                        <div className="stat-card">
                          <div className="stat-icon">
                            <FaClock />
                          </div>
                          <div className="stat-content">
                            <div className="stat-label">
                              Professional Experience
                            </div>
                            <div className="stat-value">
                              {applicationDetails?.resumeAnalysis
                                ?.totalExperience
                                ? `${applicationDetails.resumeAnalysis.totalExperience} Years`
                                : 'Not Specified'}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Resume Button */}
                      <div className="resume-card">
                        <button
                          className="view-resume-btn"
                          onClick={() =>
                            handleViewResume(applicationDetails.resumeUrl)
                          }
                        >
                          <span>View Complete Resume</span>
                          <span className="btn-icon">
                            <FaFileAlt />
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    width: isMobile && !isPdfExport ? '100%' : '28%',
                    marginLeft: isMobile && !isPdfExport ? '0%' : '2%',
                    marginBottom: isMobile && !isPdfExport ? '5%' : '0%'
                  }}
                >
                  {/* <!-- Job Details Section --> */}
                  <div
                    class="bg-white md:p-8 mb-8 relative overflow-hidden"
                    style={{
                      borderRadius: '1rem',
                      padding: isMobile && !isPdfExport ? '2.2rem' : '2rem',
                      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)'
                    }}
                  >
                    <div class="relative z-10">
                      <h3
                        className="text-2xl md:text-3xl font-bold text-gray-800 mb-6"
                        style={{
                          ...(isPdfExport
                            ? {
                                fontSize: '1.875rem',
                                lineHeight: '2.25rem',
                                color: '#1f2937',
                                fontWeight: '700',
                                marginBottom: '1.5rem',
                                background: 'none',
                                WebkitBackgroundClip: 'unset',
                                WebkitTextFillColor: '#1f2937',
                                backgroundImage: 'none'
                              }
                            : {
                                fontSize: '1.875rem',
                                lineHeight: '2.25rem',
                                color: '#1f2937',
                                fontWeight: '700',
                                marginBottom: '1.5rem',
                                background:
                                  'linear-gradient(135deg, #1f2937 0%, #4b5563 100%)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                              })
                        }}
                      >
                        Job Details
                      </h3>
                      <div class="grid grid-cols-1 md:grid-cols-1 gap-8">
                        {/* <!-- Middle Section (Job Summary, Requirements, Skills, etc.) --> */}
                        <div class="space-y-6">
                          {/* <!-- Job Summary --> */}
                          <div
                            className="shadow-sm no-break"
                            style={{
                              padding: '1rem',
                              background: '#f9fafb',
                              borderRadius: '0.5rem',
                              marginBottom: '1.5rem'
                              // border: "1px solid #E5E7EB",
                            }}
                          >
                            <h4
                              className=""
                              style={{
                                fontWeight: '600',
                                color: '#1f2937',
                                fontSize: '1.25rem',
                                lineHeight: '1.75rem'
                              }}
                            >
                              Summary
                            </h4>
                            <p
                              className="text-gray-700"
                              style={{ lineHeight: '1.5rem', color: '#374151' }}
                            >
                              {jobDetails.description}
                            </p>
                          </div>

                          {/* <!-- Job Requirements --> */}
                          <div
                            class="bg-gray-50 p-4 rounded-lg shadow-sm no-break"
                            style={{
                              padding: '1rem',
                              background: '#f9fafb',
                              borderRadius: '0.5rem',
                              marginBottom: '1.5rem'
                              // border: "1px solid #E5E7EB",
                            }}
                          >
                            <h4
                              class=""
                              style={{
                                fontWeight: '600',
                                color: '#1f2937',
                                fontSize: '1.25rem',
                                lineHeight: '1.75rem'
                              }}
                            >
                              Requirements
                            </h4>
                            <ul
                              class="list-disc list-inside text-gray-700 space-y-2 mt-2"
                              style={{ padding: '1rem' }}
                            >
                              {jobDetails.responsibilities.map((item) => (
                                <li
                                  style={{
                                    marginBottom: '0.5rem',
                                    listStyleType: 'disc',
                                    color: '#374151'
                                  }}
                                >
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </div>

                          <div
                            class="bg-gray-50 p-4 shadow-sm no-break"
                            style={{
                              padding: '1rem',
                              background: '#f9fafb',
                              borderRadius: '0.5rem',
                              marginBottom: '1.5rem'
                              // border: "1px solid #E5E7EB",
                            }}
                          >
                            <h4
                              class=""
                              style={{
                                fontWeight: '600',
                                color: '#1f2937',
                                fontSize: '1.25rem',
                                lineHeight: '1.75rem'
                              }}
                            >
                              Qualifications
                            </h4>
                            <ul
                              class="list-disc list-inside text-gray-700 space-y-2 mt-2"
                              style={{ padding: '1rem' }}
                            >
                              {jobDetails.qualifications
                                .split('. ')
                                .map((item) => (
                                  <li
                                    style={{
                                      marginBottom: '0.5rem',
                                      listStyleType: 'disc',
                                      color: '#374151'
                                    }}
                                  >
                                    {item}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          {/* <!-- Skills & Experience --> */}
                          <div
                            className="bg-gray-50 p-4 rounded-lg shadow-sm"
                            style={{
                              padding: '1rem',
                              overflow: 'hidden',
                              background: '#f9fafb',
                              borderRadius: '0.5rem',
                              marginBottom: '1.5rem',
                              border: '1px solid #E5E7EB',
                              marginTop: isPdfExport ? '12rem' : '0' // Add margin for PDF export
                            }}
                          >
                            <h4
                              className="text-xl font-semibold text-gray-800 mb-2"
                              style={{
                                fontWeight: '600',
                                color: '#1f2937',
                                fontSize: '1.25rem',
                                lineHeight: '1.75rem'
                              }}
                            >
                              Skills & Experience
                            </h4>
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '0.5rem',
                                maxWidth: '100%'
                              }}
                            >
                              {jobDetails.skills.map((skill) => (
                                <span
                                  key={skill}
                                  style={{
                                    backgroundColor: '#d4edda',
                                    color: '#155724',
                                    padding: '0.25em 0.6em',
                                    borderRadius: '10rem',
                                    fontSize: '0.75rem',
                                    whiteSpace: 'nowrap',
                                    display: 'inline-block',
                                    margin: '0.25rem 0'
                                  }}
                                >
                                  {skill || 'N/A'}
                                </span>
                              ))}
                            </div>
                          </div>
                          {/* <!-- Job Type, Location, Experience, etc. --> */}
                          <div
                            class="bg-gray-50 p-4 rounded-lg shadow-sm no-break"
                            style={{
                              padding: '1rem',
                              overflow: 'hidden',
                              background: '#f9fafb',
                              borderRadius: '0.5rem',
                              marginBottom: '1.5rem',
                              border: '1px solid #E5E7EB'
                            }}
                          >
                            <h4
                              class="text-xl font-semibold text-gray-800"
                              style={{
                                fontWeight: '600',
                                color: '#1f2937',
                                fontSize: '1.25rem',
                                lineHeight: '1.75rem'
                              }}
                            >
                              Job Details
                            </h4>
                            <div class="text-gray-700 space-y-2 mt-2">
                              <p style={{ color: '#374151' }}>
                                <strong style={{ color: '#374151' }}>
                                  Work Location Type:
                                </strong>{' '}
                                {formatStringForFrontend(
                                  jobDetails.workLocationType
                                )}
                              </p>
                              <p style={{ color: '#374151' }}>
                                <strong style={{ color: '#374151' }}>
                                  {renderExperienceLabel(
                                    jobDetails.minimumExperience,
                                    jobDetails.maximumExperience
                                  )}
                                  :
                                </strong>{' '}
                                {renderExperienceField(
                                  jobDetails.minimumExperience,
                                  jobDetails.maximumExperience
                                )}
                              </p>
                              <p style={{ color: '#374151' }}>
                                <strong style={{ color: '#374151' }}>
                                  Salary:
                                </strong>{' '}
                                {jobDetails.minimumSalary ? (
                                  <>
                                    <span className="salary-amount">
                                      {formatToCurrency(
                                        jobDetails.minimumSalary
                                      ) || 0}
                                    </span>
                                    <span className="salary-separator">
                                      &nbsp;-&nbsp;
                                    </span>
                                    <span className="salary-amount">
                                      {formatToCurrency(
                                        jobDetails.maximumSalary
                                      ) || 0}
                                    </span>
                                  </>
                                ) : jobDetails.maximumSalary ? (
                                  <span className="salary-amount">
                                    {formatToCurrency(
                                      jobDetails.maximumSalary
                                    ) || 0}
                                  </span>
                                ) : (
                                  'NA'
                                )}
                              </p>
                              <p style={{ color: '#374151' }}>
                                <strong style={{ color: '#374151' }}>
                                  Location:
                                </strong>{' '}
                                {jobDetails.location || 'N/A'}
                              </p>
                              <p style={{ color: '#374151' }}>
                                <strong style={{ color: '#374151' }}>
                                  Job Type:
                                </strong>{' '}
                                {`${formatStringForFrontend(
                                  jobDetails.jobType
                                )} (${formatStringForFrontend(
                                  jobDetails.workHours
                                )})`}
                              </p>
                              <p style={{ color: '#374151' }}>
                                <strong style={{ color: '#374151' }}>
                                  Posted Date:
                                </strong>{' '}
                                {timeAgo(jobDetails.createdAt)}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <!-- Right Section (Detailed Job Description - Hidden) --> */}
                        <div class="hidden"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isMobile && !isPdfExport && (
              <div className="profile-stats-grid">
                {/* Stats Row */}
                <div className="stats-row">
                  {/* Current Company Stats */}
                  <div className="stat-card">
                    <div className="stat-icon">
                      <FaBriefcase />
                    </div>
                    <div className="stat-content">
                      <div className="stat-label">Current Organization</div>
                      <div className="stat-value">
                        {applicationDetails?.resumeAnalysis?.workExperience?.[0]
                          ?.company || 'Not Specified'}
                      </div>
                    </div>
                  </div>

                  {/* Total Experience Stats */}
                  <div className="stat-card">
                    <div className="stat-icon">
                      <FaClock />
                    </div>
                    <div className="stat-content">
                      <div className="stat-label">Professional Experience</div>
                      <div className="stat-value">
                        {applicationDetails?.resumeAnalysis?.totalExperience
                          ? `${applicationDetails.resumeAnalysis.totalExperience} Years`
                          : 'Not Specified'}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Resume Button */}
                <div className="resume-card">
                  <button
                    className="view-resume-btn"
                    onClick={() =>
                      handleViewResume(applicationDetails.resumeUrl)
                    }
                  >
                    <span>View Complete Resume</span>
                    <span className="btn-icon">
                      <FaFileAlt />
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>

          <Modal
            show={updateApplicationModal}
            onHide={() => setUpdateApplicationModal(false)}
            centered
            className="status-update-modal"
          >
            <Modal.Header closeButton={false}>
              <Modal.Title>Update Application Status</Modal.Title>
              <FaTimes
                className="close-icon"
                onClick={() => setUpdateApplicationModal(false)}
              />
            </Modal.Header>
            <Modal.Body>
              <div className="status-select-container">
                <label className="status-select-label">
                  Select the new status for this application:
                </label>
                <Select
                  options={statusOptions}
                  value={statusOptions.find(
                    (option) => option.value === applicationStatus
                  )}
                  onChange={(selectedOption) => {
                    setApplicationStatus(selectedOption.value);
                    setTouched((prev) => ({ ...prev, status: true }));
                  }}
                  onBlur={() =>
                    setTouched((prev) => ({ ...prev, status: true }))
                  }
                  placeholder="Select Application Status"
                  isSearchable={false}
                  className="status-select"
                  classNamePrefix="status-select"
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor:
                        touched.status && !applicationStatus
                          ? '#ef4444'
                          : base.borderColor
                    })
                  }}
                />
                {touched.status && !applicationStatus && (
                  <div className="form-field-required">
                    <FaExclamationCircle />
                    <span>Please select a status</span>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label className="status-select-label">
                  Provide a reason for this status update:
                </label>
                <textarea
                  className={`reason-textarea ${touched.reason && applicationReason.trim().length < 10 ? 'reason-textarea--is-invalid' : ''}`}
                  placeholder="Enter the reason for the status change..."
                  value={applicationReason}
                  onChange={(e) => {
                    setApplicationReason(e.target.value);
                    setTouched((prev) => ({ ...prev, reason: true }));
                  }}
                  onBlur={() =>
                    setTouched((prev) => ({ ...prev, reason: true }))
                  }
                  rows="4"
                />
                {touched.reason && applicationReason.trim().length < 10 && (
                  <div className="form-field-required">
                    <FaExclamationCircle />
                    <span>Reason must be at least 10 characters long</span>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn-cancel"
                onClick={() => setUpdateApplicationModal(false)}
              >
                Cancel
              </button>
              <button
                className="btn-update"
                onClick={updateApplication}
                disabled={!isFormValid() || isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="spinner"
                  />
                ) : (
                  'Update Status'
                )}
              </button>
            </Modal.Footer>
          </Modal>

          {isResumeModalOpen && (
            <div
              className="modal fade show d-block"
              style={
                isMobile && !isPdfExport
                  ? { backgroundColor: 'rgba(0, 0, 0, 0.5)', width: '100vw' }
                  : { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
              }
              tabIndex="-1"
            >
              <style>
                {`
                  .modal-content {
        top: 20px;
        width: 90vw;
        height: 80vh;
        }`}
              </style>
              <div
                className={
                  isMobile && !isPdfExport
                    ? 'modal-xl '
                    : 'modal-dialog modal-xl '
                }
                style={
                  isMobile && !isPdfExport
                    ? {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 0
                      }
                    : {}
                }
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Resume</h5>
                    <FaTimes
                      className="h4 cursor-pointer"
                      aria-label="close"
                      onClick={() => setIsResumeModalOpen(false)}
                    />
                  </div>
                  <div
                    className="modal-body"
                    style={{
                      height: '75vh',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <Document
                        file={resumeLink}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className="d-flex flex-column justify-content-center"
                      >
                        <Page
                          pageNumber={pageNumber}
                          className="border border-secondary shadow-sm"
                          width={Math.min(window.innerWidth * 0.9, 800)}
                        />
                      </Document>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center gap-3 w-100 mb-3 mb-md-0 me-md-auto">
                      <div></div>
                      {numPages > 1 ? (
                        <div className="d-flex justify-content-center align-items-center gap-3">
                          <button
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                            style={{ cursor: pageNumber <= 1 && 'not-allowed' }}
                            className={`btn ${
                              pageNumber <= 1
                                ? 'btn-primary'
                                : 'btn-outline-light'
                            }`}
                          >
                            Previous
                          </button>
                          <span className="border rounded p-2">
                            Page {pageNumber} of {numPages}
                          </span>
                          <button
                            style={{
                              cursor: pageNumber >= numPages && 'not-allowed'
                            }}
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                            className={`btn ${
                              pageNumber >= numPages
                                ? 'btn-primary'
                                : 'btn-outline-light'
                            }`}
                          >
                            Next
                          </button>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      <a
                        href={resumeLink}
                        download
                        className="btn btn-success text-center w-100 d-md-none"
                      >
                        Download
                      </a>
                      <a
                        href={resumeLink}
                        download
                        className="btn btn-success text-center d-none d-md-block"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default JobApplicationView;
