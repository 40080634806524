import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import '../../../assets/css/superAdmin.css';

const SuperAdmin = () => {
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const fetchAnalytics = async () => {
    try {
      const userDetails = JSON.parse(localStorage.getItem('superAdminUser'));
      const accessToken = userDetails?.session.accessToken || null;
      if (!accessToken) {
        throw new Error('Access token not found');
      }
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/analytics/super-admin`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch analytics');
      }

      const data = await response.json();
      setAnalytics(data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching analytics:', error);
      setLoading(false);
    }
  };

  return (
    <>
      {/* Header Section - Always Visible */}
      <header className="text-center text-dark py-5">
        <div className="container">
          <h1 className="display-4 fw-bold mb-3">Dashboard Overview</h1>
          <p className="lead text-muted">
            Insights and performance metrics to guide your business decisions.
          </p>
        </div>
      </header>

      {loading ? (
        <div
          className="loader-container"
          style={{
            minHeight: '80vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%)'
          }}
        >
          <div
            className="loader-content"
            style={{
              textAlign: 'center',
              padding: '3rem',
              borderRadius: '24px',
              background: 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 20px 60px rgba(0, 0, 0, 0.1)',
              maxWidth: '500px',
              width: '90%'
            }}
          >
            <div className="loader-animation mb-4">
              <div className="spinner-wrapper position-relative d-inline-block">
                <div className="spinner-ring"></div>
                <div className="spinner-ring"></div>
                <div className="spinner-ring"></div>
                <Spinner
                  animation="border"
                  variant="primary"
                  style={{
                    width: '3rem',
                    height: '3rem',
                    borderWidth: '0.2em',
                    position: 'relative',
                    zIndex: 2
                  }}
                />
              </div>
            </div>

            <h3
              className="loading-title mb-3"
              style={{
                fontSize: '1.75rem',
                fontWeight: '700',
                background: 'linear-gradient(45deg, #0d6efd, #0dcaf0)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                marginBottom: '1rem'
              }}
            >
              Loading Dashboard
            </h3>

            <p
              className="loading-text"
              style={{
                color: '#6c757d',
                fontSize: '1rem',
                maxWidth: '350px',
                margin: '0 auto',
                lineHeight: '1.6'
              }}
            >
              Preparing your analytics dashboard with the latest insights...
            </p>

            <style>
              {`
                .spinner-wrapper {
                  padding: 2rem;
                }

                .spinner-ring {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  border-radius: 50%;
                  border: 2px solid transparent;
                  border-top-color: #0d6efd;
                  animation: spin 1.5s linear infinite;
                }

                .spinner-ring:nth-child(1) {
                  width: 90px;
                  height: 90px;
                  border-top-color: rgba(13, 110, 253, 0.2);
                }

                .spinner-ring:nth-child(2) {
                  width: 70px;
                  height: 70px;
                  border-top-color: rgba(13, 110, 253, 0.4);
                  animation-duration: 1.2s;
                }

                .spinner-ring:nth-child(3) {
                  width: 50px;
                  height: 50px;
                  border-top-color: rgba(13, 110, 253, 0.6);
                  animation-duration: 0.9s;
                }

                @keyframes spin {
                  0% {
                    transform: translate(-50%, -50%) rotate(0deg);
                  }
                  100% {
                    transform: translate(-50%, -50%) rotate(360deg);
                  }
                }

                .loader-content {
                  animation: fadeIn 0.5s ease-out;
                }

                @keyframes fadeIn {
                  from {
                    opacity: 0;
                    transform: translateY(20px);
                  }
                  to {
                    opacity: 1;
                    transform: translateY(0);
                  }
                }

                .loading-title {
                  animation: shimmer 2s infinite linear;
                }

                @keyframes shimmer {
                  0% {
                    opacity: 1;
                  }
                  50% {
                    opacity: 0.7;
                  }
                  100% {
                    opacity: 1;
                  }
                }
              `}
            </style>
          </div>
        </div>
      ) : (
        <>
          {/* Business Metrics Section */}
          <section className="container my-5">
            <h2 className="fw-bold text-center mb-4">Business Performance</h2>
            <div className="row g-4">
              {/* Organizations Card */}
              <div className="col-md-6 col-lg-3">
                <div className="overview-card card shadow-sm p-4 h-100">
                  {/* Card Header with Icon */}
                  <div className="d-flex align-items-center mb-3">
                    <div className="icon-wrapper me-3">
                      <div className="plan-circle bg-blue text-white">
                        <i className="fas fa-building"></i>
                        <div className="icon-glow blue-glow"></div>
                      </div>
                    </div>
                    <h5 className="card-title text-muted mb-0">
                      Organizations
                    </h5>
                  </div>

                  {/* Stats Box */}
                  <div className="stat-highlight-box primary">
                    <div className="d-flex flex-column">
                      <h4 className="stat-value-lg text-gradient-primary mb-1">
                        {analytics?.organizations?.totalActive}
                      </h4>
                      <span className="stat-label">Total Active</span>
                    </div>
                  </div>

                  {/* Additional Stats */}
                  <div className="growth-stats">
                    <div className="growth-item mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="stat-label">Monthly Growth</span>
                        <span className="growth-badge badge card-badge-text bg-soft-primary">
                          <i className="fas fa-arrow-up me-1"></i>
                          {analytics?.organizations?.monthlyGrowthRate}%
                        </span>
                      </div>
                      <div className="progress mt-2">
                        <div
                          className="progress-bar bg-primary"
                          style={{
                            width: `${analytics?.organizations?.monthlyGrowthRate}%`
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="growth-item">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="stat-label">Quarterly Growth</span>
                        <span className="growth-badge badge card-badge-text bg-soft-success">
                          <i className="fas fa-arrow-up me-1"></i>
                          {analytics?.organizations?.quarterlyGrowthRate}%
                        </span>
                      </div>
                      <div className="progress mt-2">
                        <div
                          className="progress-bar bg-success"
                          style={{
                            width: `${analytics?.organizations?.quarterlyGrowthRate}%`
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Jobs Card */}
              <div className="col-md-6 col-lg-3">
                <div className="overview-card card shadow-sm p-4 h-100">
                  {/* Card Header with Icon */}
                  <div className="d-flex align-items-center mb-3">
                    <div className="icon-wrapper me-3">
                      <div className="plan-circle bg-green text-white">
                        <i className="fas fa-briefcase"></i>
                        <div className="icon-glow green-glow"></div>
                      </div>
                    </div>
                    <h5 className="card-title text-muted mb-0">Jobs</h5>
                  </div>

                  {/* Stats Box */}
                  <div className="stat-highlight-box success">
                    <div className="d-flex flex-column">
                      <h4 className="stat-value-lg text-gradient-success mb-1">
                        {analytics?.jobs?.total}
                      </h4>
                      <span className="stat-label">Total Jobs</span>
                    </div>
                  </div>

                  {/* Additional Stats */}
                  <div className="job-stats">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <span className="stat-label">Active Jobs</span>
                      <span className="stat-badge badge card-badge-text bg-soft-success">
                        {analytics?.jobs?.active}
                      </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="stat-label">Inactive Jobs</span>
                      <span className="stat-badge badge card-badge-text bg-soft-danger">
                        {analytics?.jobs?.inactive}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Applications Card */}
              <div className="col-md-6 col-lg-3">
                <div className="overview-card card shadow-sm p-4 h-100">
                  {/* Card Header with Icon */}
                  <div className="d-flex align-items-center mb-3">
                    <div className="icon-wrapper me-3">
                      <div className="plan-circle bg-warning text-white">
                        <i className="fas fa-file-alt"></i>
                        <div className="icon-glow warning-glow"></div>
                      </div>
                    </div>
                    <h5 className="card-title text-muted mb-0">Applications</h5>
                  </div>

                  {/* Stats Box */}
                  <div className="stat-highlight-box warning">
                    <div className="d-flex flex-column">
                      <h4 className="stat-value-lg text-gradient-warning mb-1">
                        {analytics?.applications?.total}
                      </h4>
                      <span className="stat-label">Total Applications</span>
                    </div>
                  </div>

                  {/* Additional Stats */}
                  <div className="application-stats">
                    <div className="d-flex pt-2 align-items-center justify-content-between">
                      <span className="stat-label">Avg Time to Hire</span>
                      <span className="stat-badge badge card-badge-text bg-soft-warning">
                        <i className="fas fa-clock me-1"></i>
                        {Math.round(
                          analytics?.applications?.avgTimeToHire / 24
                        )}{' '}
                        hours
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Testing Overview Card */}
              <div className="col-md-6 col-lg-3">
                <div className="overview-card card shadow-sm p-4 h-100">
                  {/* Card Header with Icon */}
                  <div className="d-flex align-items-center mb-3">
                    <div className="icon-wrapper me-3">
                      <div className="plan-circle bg-purple text-white">
                        <i className="fas fa-clipboard-check"></i>
                        <div className="icon-glow purple-glow"></div>
                      </div>
                    </div>
                    <h5 className="card-title text-muted mb-0">
                      Assessment Tests
                    </h5>
                  </div>

                  {/* Stats Box */}
                  <div className="stat-highlight-box purple">
                    <div className="d-flex flex-column">
                      <h4 className="stat-value-lg text-gradient-purple mb-1">
                        {analytics?.testing?.count}
                      </h4>
                      <span className="stat-label">Assessment Tests</span>
                    </div>
                  </div>

                  {/* Additional Stats */}
                  <div className="hire-time">
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="stat-label">Average Duration</span>
                      <span className="stat-badge badge card-badge-text bg-soft-primary">
                        <i className="fas fa-clock me-1"></i>
                        45 mins
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* AI Analytics Section */}
          <section className="container my-5">
            <h2 className="fw-bold text-center mb-4">AI Performance</h2>

            {/* Overall AI Stats */}
            <div className="row mb-4">
              <div className="col-12">
                <div className="overview-card card shadow-sm p-4 h-100">
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-wrapper">
                      <div className="plan-circle bg-purple">
                        <i className="fas fa-robot text-white fs-4"></i>
                      </div>
                      <div className="icon-glow purple-glow"></div>
                    </div>
                    <h3 className="card-title h6 mb-0 fw-bold ms-3">
                      AI Analysis
                    </h3>
                  </div>
                  <div className="stat-content">
                    <div className="stat-value-wrapper mb-4">
                      <h4 className="stat-value-lg text-gradient-purple mb-0">
                        {analytics?.aiAnalysis?.overall?.totalAnalyses}
                      </h4>
                      <span className="stat-label">Total Analysis</span>
                    </div>
                    <div className="success-rate">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="stat-label">Success Rate</span>
                        <span className="stat-badge badge card-badge-text bg-soft-purple">
                          <i className="fas fa-chart-line me-1"></i>
                          {analytics?.aiAnalysis?.overall?.successRate}%
                        </span>
                      </div>
                      <div
                        className="progress"
                        style={{
                          height: '1rem',
                          borderRadius: '5px',
                          marginBottom: '1rem',
                          padding: '0px'
                        }}
                      >
                        <div
                          className="progress-bar bg-purple"
                          style={{
                            width: `${analytics?.aiAnalysis?.overall?.successRate || 0}%`,
                            height: '1rem'
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* AI Model Comparison */}
            <div className="row g-4">
              {/* CLAUDE Card */}
              <div className="col-lg-6">
                <div
                  className="card border-0 shadow-sm p-4 h-100 position-relative"
                  style={{ background: '#fef7e0' }}
                >
                  <div className="d-flex align-items-center mb-4">
                    <div className="plan-circle bg-warning text-white fs-5 me-3">
                      <span>C</span>
                    </div>
                    <h3 className="h6 fw-bold mb-0 text-warning">CLAUDE</h3>
                  </div>
                  <div className="analysis-stats mb-4">
                    <div className="stat-item mb-3">
                      <p className="text-dark mb-1">
                        <strong>Analysis Completed</strong>
                      </p>
                      <h4 className="display-6 fw-bold text-warning mb-0">
                        {analytics?.aiAnalysis?.byModel[0]?.analyses}
                      </h4>
                    </div>
                    <div className="stat-item">
                      <p className="text-dark mb-1">
                        <strong>Tokens Used</strong>
                      </p>
                      <h4 className="display-6 fw-bold text-warning mb-0">
                        {analytics?.aiAnalysis?.byModel[0]?.tokensUsed?.toLocaleString()}
                      </h4>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <label className="text-muted fw-bold">Success Rate</label>
                      <span className="badge bg-warning fs-6 px-3 py-2">
                        {Number(
                          analytics?.aiAnalysis?.byModel[0]?.successRate
                        ).toFixed(2)}
                        %
                      </span>
                    </div>
                    <div
                      className="progress"
                      style={{
                        height: '10px',
                        borderRadius: '5px',
                        marginBottom: '1rem'
                      }}
                    >
                      <div
                        className="progress-bar bg-warning progress-bar-striped"
                        style={{
                          width: `${analytics?.aiAnalysis?.byModel[0]?.successRate || 0}%`
                        }}
                      ></div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <label className="text-muted fw-bold">Failure Rate</label>
                      <span className="badge bg-danger fs-6 px-3 py-2">
                        {Number(
                          analytics?.aiAnalysis?.byModel[0]?.failureRate
                        ).toFixed(2)}
                        %
                      </span>
                    </div>
                    <div
                      className="progress"
                      style={{ height: '10px', borderRadius: '5px' }}
                    >
                      <div
                        className="progress-bar bg-danger progress-bar-striped"
                        style={{
                          width: `${analytics?.aiAnalysis?.byModel[0]?.failureRate || 0}%`
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              {/* CHATGPT Card */}
              <div className="col-lg-6">
                <div
                  className="card border-0 shadow-sm p-4 h-100 position-relative"
                  style={{ background: '#e3f2fd' }}
                >
                  <div className="d-flex align-items-center mb-4">
                    <div className="plan-circle bg-primary text-white fs-5 me-3">
                      <span>G</span>
                    </div>
                    <h3 className="h6 fw-bold mb-0 text-primary">CHATGPT</h3>
                  </div>
                  <div className="analysis-stats mb-4">
                    <div className="stat-item mb-3">
                      <p className="text-dark mb-1">
                        <strong>Analysis Completed</strong>
                      </p>
                      <h4 className="display-6 fw-bold text-primary mb-0">
                        {analytics?.aiAnalysis?.byModel[1]?.analyses}
                      </h4>
                    </div>
                    <div className="stat-item">
                      <p className="text-dark mb-1">
                        <strong>Tokens Used</strong>
                      </p>
                      <h4 className="display-6 fw-bold text-primary mb-0">
                        {analytics?.aiAnalysis?.byModel[1]?.tokensUsed?.toLocaleString()}
                      </h4>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <label className="text-muted fw-bold">Success Rate</label>
                      <span className="badge bg-primary fs-6 px-3 py-2">
                        {Number(
                          analytics?.aiAnalysis?.byModel[1]?.successRate
                        ).toFixed(2)}
                        %
                      </span>
                    </div>
                    <div
                      className="progress"
                      style={{
                        height: '10px',
                        borderRadius: '5px',
                        marginBottom: '1rem'
                      }}
                    >
                      <div
                        className="progress-bar bg-primary progress-bar-striped"
                        style={{
                          width: `${analytics?.aiAnalysis?.byModel[1]?.successRate || 0}%`
                        }}
                      ></div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <label className="text-muted fw-bold">Failure Rate</label>
                      <span className="badge bg-danger fs-6 px-3 py-2">
                        {Number(
                          analytics?.aiAnalysis?.byModel[1]?.failureRate
                        ).toFixed(2)}
                        %
                      </span>
                    </div>
                    <div
                      className="progress"
                      style={{ height: '10px', borderRadius: '5px' }}
                    >
                      <div
                        className="progress-bar bg-danger progress-bar-striped"
                        style={{
                          width: `${analytics?.aiAnalysis?.byModel[1]?.failureRate || 0}%`
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Financial Section */}
          <section className="container my-5">
            <h2 className="fw-bold text-center mb-4">Financial Overview</h2>

            {/* Revenue & Profitability */}
            <div className="row g-4 mb-4">
              {/* Revenue Overview Card */}
              <div className="col-md-6 col-lg-3">
                <div className="overview-card card shadow-sm p-4 h-100">
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-wrapper">
                      <div className="plan-circle bg-blue">
                        <i className="fas fa-dollar-sign text-white fs-4"></i>
                      </div>
                      <div className="icon-glow blue-glow"></div>
                    </div>
                    <h3 className="card-title h6 mb-0 fw-bold ms-3">
                      Revenue Overview
                    </h3>
                  </div>
                  <div className="stat-content">
                    <div className="stat-value-wrapper mb-4">
                      <h4 className="stat-value-lg text-gradient-primary mb-0">
                        ${analytics?.financials?.revenue?.total}
                      </h4>
                      <span className="stat-label">Total Revenue</span>
                    </div>
                    <div className="growth-stats">
                      <div className="growth-item mb-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="stat-label">Transactions</span>
                          <span className="growth-badge badge card-badge-text bg-soft-primary">
                            <i className="fas fa-arrow-up me-1"></i>
                            {analytics?.financials?.revenue?.transactionCount}
                          </span>
                        </div>
                        <div className="progress mt-2">
                          <div
                            className="progress-bar bg-primary"
                            style={{
                              width: '75%'
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="growth-item">
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="stat-label">Avg Value</span>
                          <span className="growth-badge badge card-badge-text bg-soft-success">
                            <i className="fas fa-chart-line me-1"></i>$
                            {analytics?.financials?.revenue?.avgTransactionValue.toFixed(
                              2
                            )}
                          </span>
                        </div>
                        <div className="progress mt-2">
                          <div
                            className="progress-bar bg-success"
                            style={{
                              width: '60%'
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Profitability Card */}
              <div className="col-md-6 col-lg-3">
                <div className="overview-card card shadow-sm p-4 h-100">
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-wrapper">
                      <div className="plan-circle bg-green">
                        <i className="fas fa-chart-line text-white fs-4"></i>
                      </div>
                      <div className="icon-glow green-glow"></div>
                    </div>
                    <h3 className="card-title h6 mb-0 fw-bold ms-3">
                      Profitability
                    </h3>
                  </div>
                  <div className="stat-content">
                    <div className="stat-value-wrapper mb-4">
                      <h4 className="stat-value-lg text-gradient-success mb-0">
                        $
                        {analytics?.financials?.profitability?.netProfit.toFixed(
                          2
                        )}
                      </h4>
                      <span className="stat-label">Net Profit</span>
                    </div>
                    <div className="growth-stats">
                      <div className="growth-item mb-3">
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="stat-label">Revenue</span>
                          <span className="growth-badge badge card-badge-text bg-soft-success">
                            <i className="fas fa-arrow-up me-1"></i>$
                            {analytics?.financials?.profitability?.totalRevenue}
                          </span>
                        </div>
                        <div className="progress mt-2">
                          <div
                            className="progress-bar bg-success"
                            style={{
                              width: '100%'
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="growth-item">
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="stat-label">Expenses</span>
                          <span className="growth-badge badge card-badge-text bg-soft-danger">
                            <i className="fas fa-arrow-down me-1"></i>$
                            {
                              analytics?.financials?.profitability
                                ?.totalExpenses
                            }
                          </span>
                        </div>
                        <div className="progress mt-2">
                          <div
                            className="progress-bar bg-danger"
                            style={{
                              width: `${(analytics?.financials?.profitability?.totalExpenses / analytics?.financials?.profitability?.totalRevenue) * 100}%`
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Transactions Card */}
              <div className="col-md-6 col-lg-3">
                <div className="overview-card card shadow-sm p-4 h-100">
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-wrapper">
                      <div className="plan-circle bg-warning">
                        <i className="fas fa-exchange-alt text-white fs-4"></i>
                      </div>
                      <div className="icon-glow warning-glow"></div>
                    </div>
                    <h3 className="card-title h6 mb-0 fw-bold ms-3">
                      Transactions
                    </h3>
                  </div>
                  <div className="stat-content">
                    <div className="stat-value-wrapper mb-4">
                      <h4 className="stat-value-lg text-gradient-warning mb-0">
                        {analytics?.financials?.revenue?.transactionCount}
                      </h4>
                      <span className="stat-label">Total Transactions</span>
                    </div>
                    <div className="growth-stats">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <span className="stat-label">Credit</span>
                        <span className="stat-badge badge card-badge-text bg-soft-success">
                          {
                            analytics?.financials?.billing?.creditTransactions
                              ?.count
                          }
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="stat-label">Debit</span>
                        <span className="stat-badge badge card-badge-text bg-soft-danger">
                          {
                            analytics?.financials?.billing?.debitTransactions
                              ?.count
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Average Value Card */}
              <div className="col-md-6 col-lg-3">
                <div className="overview-card card shadow-sm p-4 h-100">
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-wrapper">
                      <div className="plan-circle bg-purple">
                        <i className="fas fa-calculator text-white fs-4"></i>
                      </div>
                      <div className="icon-glow purple-glow"></div>
                    </div>
                    <h3 className="card-title h6 mb-0 fw-bold ms-3">
                      Average Value
                    </h3>
                  </div>
                  <div className="stat-content">
                    <div className="stat-value-wrapper mb-4">
                      <h4 className="stat-value-lg text-gradient-purple mb-0">
                        $
                        {analytics?.financials?.revenue?.avgTransactionValue.toFixed(
                          2
                        )}
                      </h4>
                      <span className="stat-label">Per Transaction</span>
                    </div>
                    <div className="success-rate">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="stat-label">Success Rate</span>
                        <span className="stat-badge badge card-badge-text bg-soft-purple">
                          <i className="fas fa-chart-line me-1"></i>
                          98.5%
                        </span>
                      </div>
                      <div
                        className="progress mt-2"
                        style={{
                          height: '1rem',
                          borderRadius: '5px',
                          marginBottom: '1rem',
                          padding: '0px'
                        }}
                      >
                        <div
                          className="progress-bar bg-purple"
                          style={{
                            width: '98.5%'
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Expenses Overview */}
            <div className="row g-4">
              <div className="col-12">
                <div
                  className="card border-0 shadow-sm p-4 h-100"
                  style={{ background: '#f5f9ff' }}
                >
                  <h3 className="h6 fw-bold text-primary mb-4">
                    Expenses Overview
                  </h3>
                  <div className="analysis-stats mb-4">
                    {/* CLAUDE Expense - Now First */}
                    <div className="stat-item mb-3">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <span className="text-dark fw-bold">CLAUDE</span>
                        <span className="badge bg-warning fs-6 px-3 py-2">
                          ${analytics?.expenses?.byModel[0]?.cost}
                        </span>
                      </div>
                      <div
                        className="progress mb-3"
                        style={{ height: '8px', borderRadius: '5px' }}
                      >
                        <div
                          className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                          style={{ width: '60%' }}
                        ></div>
                      </div>
                    </div>

                    {/* CHATGPT Expense - Now Second */}
                    <div className="stat-item mb-3">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <span className="text-dark fw-bold">CHATGPT</span>
                        <span className="badge bg-primary fs-6 px-3 py-2">
                          ${analytics?.expenses?.byModel[1]?.cost}
                        </span>
                      </div>
                      <div
                        className="progress mb-3"
                        style={{ height: '8px', borderRadius: '5px' }}
                      >
                        <div
                          className="progress-bar bg-primary progress-bar-striped progress-bar-animated"
                          style={{ width: '80%' }}
                        ></div>
                      </div>
                    </div>

                    {/* Total Expense */}
                    <div className="stat-item">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <span className="text-dark fw-bold">
                          Total Expenses
                        </span>
                        <span className="badge bg-success fs-6 px-3 py-2">
                          ${analytics?.expenses?.total}
                        </span>
                      </div>
                      <div
                        className="progress"
                        style={{ height: '8px', borderRadius: '5px' }}
                      >
                        <div
                          className="progress-bar bg-success progress-bar-striped progress-bar-animated"
                          style={{ width: '100%' }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Transaction Details */}
            <div className="row g-4 mt-4">
              {/* Credit Transactions Card */}
              <div className="col-lg-6">
                <div
                  className="card border-0 shadow-sm p-4 h-100 position-relative"
                  style={{ background: '#e3f2fd' }}
                >
                  <div className="d-flex align-items-center mb-4">
                    <div className="plan-circle bg-primary text-white fs-5 me-3">
                      <i className="fas fa-arrow-down"></i>
                    </div>
                    <h3 className="h6 fw-bold mb-0 text-primary">
                      Credit Transactions
                    </h3>
                  </div>
                  <div className="analysis-stats mb-4">
                    <div className="stat-item mb-3">
                      <p className="text-dark mb-1">
                        <strong>Total Amount</strong>
                      </p>
                      <h4 className="display-6 fw-bold text-primary mb-0">
                        $
                        {
                          analytics?.financials?.billing?.creditTransactions
                            ?.totalAmount
                        }
                      </h4>
                    </div>
                    <div className="stat-item">
                      <p className="text-dark mb-1">
                        <strong>Transaction Count</strong>
                      </p>
                      <h4 className="display-6 fw-bold text-primary mb-0">
                        {
                          analytics?.financials?.billing?.creditTransactions
                            ?.count
                        }
                      </h4>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <label className="text-muted fw-bold">Success Rate</label>
                      <span className="badge bg-primary fs-6 px-3 py-2">
                        98.5%
                      </span>
                    </div>
                    <div
                      className="progress"
                      style={{ height: '10px', borderRadius: '5px' }}
                    >
                      <div
                        className="progress-bar bg-primary progress-bar-striped"
                        style={{ width: '98.5%' }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Debit Transactions Card */}
              <div className="col-lg-6">
                <div
                  className="card border-0 shadow-sm p-4 h-100 position-relative"
                  style={{ background: '#fef7e0' }}
                >
                  <div className="d-flex align-items-center mb-4">
                    <div className="plan-circle bg-warning text-white fs-5 me-3">
                      <i className="fas fa-arrow-up"></i>
                    </div>
                    <h3 className="h6 fw-bold mb-0 text-warning">
                      Debit Transactions
                    </h3>
                  </div>
                  <div className="analysis-stats mb-4">
                    <div className="stat-item mb-3">
                      <p className="text-dark mb-1">
                        <strong>Total Amount</strong>
                      </p>
                      <h4 className="display-6 fw-bold text-warning mb-0">
                        $
                        {
                          analytics?.financials?.billing?.debitTransactions
                            ?.totalAmount
                        }
                      </h4>
                    </div>
                    <div className="stat-item">
                      <p className="text-dark mb-1">
                        <strong>Transaction Count</strong>
                      </p>
                      <h4 className="display-6 fw-bold text-warning mb-0">
                        {
                          analytics?.financials?.billing?.debitTransactions
                            ?.count
                        }
                      </h4>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <label className="text-muted fw-bold">Success Rate</label>
                      <span className="badge bg-warning fs-6 px-3 py-2">
                        96.8%
                      </span>
                    </div>
                    <div
                      className="progress"
                      style={{ height: '10px', borderRadius: '5px' }}
                    >
                      <div
                        className="progress-bar bg-warning progress-bar-striped"
                        style={{ width: '96.8%' }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Testing Details Section */}
          <section className="container my-5">
            <h2 className="fw-bold text-center mb-4">Assessment Analytics</h2>
            <div className="row g-4">
              {/* Completed Tests Card */}
              <div className="col-md-6 col-lg-4">
                <div className="overview-card card shadow-sm p-4 h-100">
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-wrapper">
                      <div className="plan-circle bg-green">
                        <i className="fas fa-check-circle text-white fs-4"></i>
                      </div>
                      <div className="icon-glow green-glow"></div>
                    </div>
                    <h3 className="card-title h6 mb-0 fw-bold ms-3">
                      Completed Tests
                    </h3>
                  </div>
                  <div className="stat-content">
                    <div className="stat-value-wrapper mb-4">
                      <h4 className="stat-value-lg text-gradient-success mb-0">
                        {analytics?.testing?.completed}
                      </h4>
                      <span className="stat-label">Tests Completed</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="stat-label">Pass Rate</span>
                      <span className="stat-badge badge card-badge-text bg-soft-success">
                        <i className="fas fa-chart-line me-1"></i>
                        85%
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Completion Rate Card */}
              <div className="col-md-6 col-lg-4">
                <div className="overview-card card shadow-sm p-4 h-100">
                  <div className="d-flex align-items-center mb-4">
                    <div className="icon-wrapper">
                      <div className="plan-circle bg-warning">
                        <i className="fas fa-chart-pie text-white fs-4"></i>
                      </div>
                      <div className="icon-glow warning-glow"></div>
                    </div>
                    <h3 className="card-title h6 mb-0 fw-bold ms-3">
                      Completion Rate
                    </h3>
                  </div>
                  <div className="stat-content">
                    <div className="stat-value-wrapper mb-4">
                      <h4 className="stat-value-lg text-gradient-warning mb-0">
                        {analytics?.testing?.completionRate}%
                      </h4>
                      <span className="stat-label">Success Rate</span>
                    </div>
                    <div className="success-rate">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="stat-label">Completion Trend</span>
                        <span className="stat-badge badge card-badge-text bg-soft-warning">
                          <i className="fas fa-chart-line me-1"></i>
                          Growing
                        </span>
                      </div>
                      <div
                        className="progress mt-2"
                        style={{
                          height: '1rem',
                          borderRadius: '5px',
                          marginBottom: '1rem',
                          padding: '0px'
                        }}
                      >
                        <div
                          className="progress-bar bg-warning"
                          style={{
                            width: `${analytics?.testing?.completionRate}%`
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Testing Card */}
              <div className="col-md-6 col-lg-3">
                <div className="overview-card card shadow-sm p-4 h-100">
                  {/* Card Header with Icon */}
                  <div className="d-flex align-items-center mb-3">
                    <div className="icon-wrapper me-3">
                      <div className="plan-circle bg-purple text-white">
                        <i className="fas fa-vial"></i>
                        <div className="icon-glow purple-glow"></div>
                      </div>
                    </div>
                    <h5 className="card-title text-muted mb-0">Testing</h5>
                  </div>

                  {/* Stats Box */}
                  <div className="stat-highlight-box purple">
                    <div className="d-flex flex-column">
                      <h4 className="stat-value-lg text-gradient-purple mb-1">
                        {analytics?.testing?.total}
                      </h4>
                      <span className="stat-label">Total Tests</span>
                    </div>
                  </div>

                  {/* Additional Stats */}
                  <div className="testing-stats">
                    {/* ... existing testing stats ... */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default SuperAdmin;
