import React from 'react';
import { Spinner, Tab } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import ChartLabel from './chartLabel';

const PreAssesmentStatusChart = ({ chartData, isLoading }) => {
  const rejectedData = [chartData?.failed];

  const acceptedData = [chartData?.passed];

  const data = {
    labels: [''],
    datasets: [
      {
        label: 'Failed',
        data: rejectedData,
        backgroundColor: 'rgb(245, 79, 82)',
        hoverBackgroundColor: 'rgb(234, 75, 77)',
        borderSkipped: false
      },
      {
        label: 'Passed',
        data: acceptedData,
        backgroundColor: 'rgb(62, 193, 92)',
        hoverBackgroundColor: 'rgb(56, 184, 86)',
        borderSkipped: false
      }
    ]
  };

  const options = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    // barThickness: 'flex',
    maxBarThickness: 30,
    plugins: {
      legend: false
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: 'rgba(89, 59, 219,0.1)',
          drawBorder: true
        }
      },
      y: {
        grid: {
          color: 'rgba(89, 59, 219,0.1)',
          drawBorder: true,
          display: false
        }
      }
    }
  };

  return (
    <Tab.Container defaultActiveKey="Daily">
      <div className="card" style={{ height: 'auto' }}>
        <div className="card-header border-0 pb-0 flex-wrap">
          <h4 className="fs-20 font-w500 mb-1">Pre-assessment Distribution</h4>
        </div>
        <div className="card-body">
          <div className="pb-4 d-flex flex-wrap justify-content-between">
            <ChartLabel title={'Passed'} color={'#3EC15C'} />
            <ChartLabel title={'Failed'} color={'#F54F52'} />
          </div>
          <Tab.Content>
            <div style={{ height: '20rem' }}>
              {isLoading ? (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <Spinner
                    animation="border mb-3"
                    style={{ color: '#858585' }}
                  />
                </div>
              ) : (
                <>
                  {chartData ? (
                    <Bar options={options} data={data} />
                  ) : (
                    <h5>No Data Found</h5>
                  )}
                </>
              )}
            </div>
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
};

export default PreAssesmentStatusChart;
