export function NotificationTriggerList({ triggers = [], onRemove }) {
  if (triggers.length === 0) {
    return (
      <div className="empty-triggers">
        <div className="empty-icon">
          <i className="fas fa-bell-slash"></i>
        </div>
        <h4>No Active Triggers</h4>
        <p>Create your first notification trigger to get started</p>
      </div>
    );
  }

  const handleRemoveTrigger = async (integrationId) => {
    try {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      const orgId = userDetails?.user?.organizationId;
      if (!orgId) {
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/integration/slack/channel-integration/${orgId}/${integrationId}`,
        {
          method: 'DELETE'
        }
      );

      if (!response.ok) {
        throw new Error('Failed to delete channel integration');
      }

      // Call the parent component's onRemove to update UI
      onRemove(integrationId);
    } catch (error) {
      console.error('Error removing trigger:', error);
      // You might want to add error handling/notification here
    }
  };

  return (
    <div className="triggers-list">
      {triggers.map((trigger) => (
        <div key={trigger.id} className="trigger-card">
          <div className="trigger-header">
            <div className="trigger-type">
              {/* <div className={`type-icon ${trigger.type}`}> */}
              <div className={'type-icon slack'}>
                {/* <i className={`${trigger.type === 'slack' ? 'fab fa-slack' : 'fas fa-code'}`}></i> */}
                <i className={'fas fa-slack'}></i>
              </div>
              {/* <span className="type-label">{trigger.type === 'slack' ? 'Slack' : 'Webhook'}</span> */}
              <span className="type-label">Slack</span>
            </div>
            <div className="trigger-actions">
              <button
                className="delete-button"
                onClick={() => handleRemoveTrigger(trigger._id)}
                title="Delete trigger"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>

          <div className="trigger-content">
            <div className="trigger-info">
              <h4>{trigger.trigger}</h4>
              <span className="trigger-for">
                <i className="fas fa-user-circle"></i>
                {trigger.notificationFor === 'candidate'
                  ? 'Candidate Notification'
                  : 'Admin Notification'}
              </span>
            </div>

            {trigger.type === 'slack' ? (
              <div className="trigger-details slack">
                <div className="detail-item">
                  <i className="fas fa-hashtag"></i>
                  <span>{trigger.channelName}</span>
                </div>
                <div className="detail-item">
                  <i
                    className={`fas fa-${trigger.channelType === 'public' ? 'globe' : 'lock'}`}
                  ></i>
                  <span>{trigger.channelType} channel</span>
                </div>
              </div>
            ) : (
              <div className="trigger-details webhook">
                <div className="detail-item">
                  <i className="fas fa-link"></i>
                  <span>{trigger.webhookUrl}</span>
                </div>
                <div className="detail-item">
                  <i className="fas fa-code-branch"></i>
                  <span>{trigger.method} Request</span>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
