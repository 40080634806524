import { Navigate, Outlet } from 'react-router-dom';
import { decodeTokenRole } from '../../../services/AuthService';

const ProtectedRoute = ({ role }) => {
  if (!role) {
    return <Outlet />;
  }
  const isAdminRoute = role === 'ADMIN';

  const userDetails = JSON.parse(
    localStorage.getItem(isAdminRoute ? 'userDetails' : 'superAdminUser')
  );
  const token = userDetails?.session?.accessToken;

  if (!token) {
    return <Navigate to={'/404'} />;
  }

  const isAuthenticated = decodeTokenRole(token) === role;

  return isAuthenticated ? <Outlet /> : <Navigate to={'/404'} />;
};

export default ProtectedRoute;
