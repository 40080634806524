import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import cc from 'currency-codes';
import currencySymbols from 'currency-symbol-map';

const CurrencyCustomization = () => {
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState('₹');

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;
  const storedCurrency = localStorage.getItem('currency');

  useEffect(() => {
    const options = cc.codes().map((code) => {
      const currency = cc.code(code);
      return {
        value: code,
        label: `${code} (${currency.currency})`
      };
    });
    setCurrencyOptions(options);

    if (storedCurrency) {
      const defaultCurrency = options.find(
        (option) => option.value === storedCurrency
      );
      setSelectedCurrency(defaultCurrency);
      setCurrencySymbol(currencySymbols(storedCurrency) || '₹');
    }
  }, [storedCurrency]);

  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption);
    setCurrencySymbol(currencySymbols(selectedOption.value) || '₹');
  };

  const handleSaveChanges = async () => {
    if (!selectedCurrency) {
      toast.error('Please select a currency.');
      return;
    }

    setLoading(true);
    try {
      const accessToken = userDetails?.session.accessToken;
      if (!accessToken) {
        throw new Error('Access token not found');
      }

      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/currency`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify({ currency: selectedCurrency.value })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update currency');
      }

      await response.json();
      toast.success('Currency updated successfully!');
      localStorage.setItem('currency', selectedCurrency.value);
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="theme-customization">
      <div className="d-flex align-items-center mb-4">
        <h3 className="mb-0 me-auto">Change Currency</h3>
      </div>
      <div className="row">
        <div className="col-12 col-md-8 col-lg-6">
          <div className="card shadow-sm border-light">
            <div className="card-body">
              <Form>
                <Form.Group controlId="currencySelect" className="mb-4">
                  <Form.Label>Select Currency</Form.Label>
                  <Select
                    value={selectedCurrency}
                    onChange={handleCurrencyChange}
                    options={currencyOptions}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select a currency"
                    isSearchable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '100%'
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999
                      })
                    }}
                  />
                </Form.Group>
                <p className="mb-3">
                  Selected Currency Symbol: {currencySymbol}
                </p>
                <Button
                  variant="primary"
                  type="button"
                  className="w-100 d-flex justify-content-center align-items-center"
                  onClick={handleSaveChanges}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Loading...
                    </>
                  ) : (
                    'Save Changes'
                  )}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyCustomization;
