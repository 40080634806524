import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spinner, Alert } from 'react-bootstrap';
import Chart from 'chart.js/auto';
import { toast } from 'react-toastify';
import html2pdf from 'html2pdf.js';
import { FaTasks, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import PDFDownloadButton from '../AppsMenu/CustomComponents/PDFDownloadButton';

const JobDiscView = () => {
  const { testId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [discReport, setDiscReport] = useState(null);
  const [adaptedStyleChart, setAdaptedStyleChart] = useState(null);
  const [naturalStyleChart, setNaturalStyleChart] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const pdfContentRef = useRef(null);

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  const behavioralTendencies = {
    prioritizing: {
      helper: 'How well you prioritize tasks and goals.',
      natural: {
        low: 'Struggles to prioritize tasks effectively.',
        medium: 'Balances task priority moderately well.',
        high: 'Excels at prioritizing tasks efficiently.'
      },
      adapted: {
        low: 'Difficulty prioritizing in demanding environments.',
        medium: 'Maintains balanced prioritization under pressure.',
        high: 'Efficiently prioritizes even in high-stress.'
      },
      frequency: {
        rare: 'Rarely prioritizes tasks or objectives.',
        moderate: 'Occasionally prioritizes tasks when needed.',
        frequent: 'Frequently prioritizes tasks and goals.'
      },
      comparison: {
        belowAverage: 'Prioritization skills are below average.',
        average: 'Prioritization ability is at average level.',
        aboveAverage: 'Excels at prioritizing compared to others.'
      }
    },
    reasoning: {
      helper: 'Ability to think critically and logically.',
      natural: {
        low: 'Relies less on reasoning naturally.',
        medium: 'Applies reasoning moderately in decisions.',
        high: 'Instinctively strong in logical reasoning.'
      },
      adapted: {
        low: 'Struggles with reasoning under pressure.',
        medium: 'Balances reasoning in challenging environments.',
        high: 'Excels at reasoning in high-stress situations.'
      },
      frequency: {
        rare: 'Reasoning is rarely observed in behavior.',
        moderate: 'Occasionally applies logical reasoning skills.',
        frequent: 'Frequently uses reasoning in decision-making.'
      },
      comparison: {
        belowAverage: 'Reasoning abilities are below average.',
        average: 'Reasoning abilities are at average level.',
        aboveAverage: 'Reasoning abilities surpass most others.'
      }
    },
    change_resistance: {
      helper: 'Tendency to resist or embrace change.',
      natural: {
        low: 'Adapts well to change naturally.',
        medium: 'Shows moderate resistance to change.',
        high: 'Strong resistance to change in behavior.'
      },
      adapted: {
        low: 'Remains open to change under pressure.',
        medium: 'Moderately resistant to change when challenged.',
        high: 'Resistant to change in difficult environments.'
      },
      frequency: {
        rare: 'Rarely resists change in situations.',
        moderate: 'Occasionally shows resistance to change.',
        frequent: 'Frequently resists change when encountered.'
      },
      comparison: {
        belowAverage: 'Less resistant to change than others.',
        average: 'Change resistance is average among peers.',
        aboveAverage: 'More resistant to change than others.'
      }
    },
    careful_decision_making: {
      helper: 'How carefully you evaluate options.',
      natural: {
        low: 'Tends to make quick decisions naturally.',
        medium: 'Balanced approach to decision-making naturally.',
        high: 'Careful and deliberate decision-making instinctively.'
      },
      adapted: {
        low: 'Makes quick decisions under stress.',
        medium: 'Balances decision-making in stressful situations.',
        high: 'Remains careful when making decisions under pressure.'
      },
      frequency: {
        rare: 'Rarely takes care in decision-making.',
        moderate: 'Occasionally exercises careful decision-making.',
        frequent: 'Frequently makes careful, considered decisions.'
      },
      comparison: {
        belowAverage: 'Less careful in decisions than others.',
        average: 'Carefulness in decisions is average.',
        aboveAverage: 'More careful in decisions than peers.'
      }
    },
    accuracy: {
      helper: 'Attention to detail and precision.',
      natural: {
        low: 'May overlook details in behavior.',
        medium: 'Shows moderate attention to detail naturally.',
        high: 'Naturally precise and detail-oriented.'
      },
      adapted: {
        low: 'Misses details under pressure.',
        medium: 'Balances accuracy in stressful situations.',
        high: 'Highly accurate even under pressure.'
      },
      frequency: {
        rare: 'Rarely focuses on accuracy and precision.',
        moderate: 'Occasionally shows attention to accuracy.',
        frequent: 'Frequently demonstrates attention to detail.'
      },
      comparison: {
        belowAverage: 'Less accurate compared to most peers.',
        average: 'Accuracy is average compared to others.',
        aboveAverage: 'More accurate than others typically.'
      }
    },
    building_rapport: {
      helper: 'Ability to build connections with others.',
      natural: {
        low: 'Struggles to build rapport with others.',
        medium: 'Moderately connects with others naturally.',
        high: 'Easily builds rapport and relationships.'
      },
      adapted: {
        low: 'Struggles to build rapport under pressure.',
        medium: 'Moderately builds connections in stressful environments.',
        high: 'Consistently builds rapport, even under stress.'
      },
      frequency: {
        rare: 'Rarely builds rapport with others.',
        moderate: 'Occasionally builds rapport when necessary.',
        frequent: 'Frequently builds rapport with ease.'
      },
      comparison: {
        belowAverage: 'Struggles more than others to connect.',
        average: 'Average ability to build rapport.',
        aboveAverage: 'Builds rapport better than most.'
      }
    },
    self_reliance: {
      helper: 'Degree of independence and autonomy.',
      natural: {
        low: 'Leans on others for support naturally.',
        medium: 'Moderate level of self-reliance.',
        high: 'Highly independent and self-reliant naturally.'
      },
      adapted: {
        low: 'Relies on others under pressure.',
        medium: 'Balances self-reliance in stressful environments.',
        high: 'Maintains self-reliance under pressure.'
      },
      frequency: {
        rare: 'Rarely demonstrates self-reliance.',
        moderate: 'Occasionally shows self-reliance when necessary.',
        frequent: 'Frequently demonstrates independence.'
      },
      comparison: {
        belowAverage: 'Less self-reliant compared to peers.',
        average: 'Self-reliance is average compared to others.',
        aboveAverage: 'More self-reliant than most others.'
      }
    },
    work_process_alignment: {
      helper: 'How well you align with workflows.',
      natural: {
        low: 'Struggles to align with processes.',
        medium: 'Moderately aligned with workflows naturally.',
        high: 'Strong alignment with work processes naturally.'
      },
      adapted: {
        low: 'Struggles with process alignment under pressure.',
        medium: 'Balances process alignment in stress.',
        high: 'Maintains process alignment in high-pressure environments.'
      },
      frequency: {
        rare: 'Rarely aligns with work processes.',
        moderate: 'Occasionally aligns with processes.',
        frequent: 'Frequently aligns with work processes.'
      },
      comparison: {
        belowAverage: 'Struggles to align more than peers.',
        average: 'Process alignment is average.',
        aboveAverage: 'Aligns with processes better than most.'
      }
    },
    customer_team_interaction: {
      helper: 'How well you interact with teams.',
      natural: {
        low: 'Struggles to interact with teams naturally.',
        medium: 'Moderate ability to interact with teams.',
        high: 'Naturally excels at team interaction.'
      },
      adapted: {
        low: 'Struggles to interact under pressure.',
        medium: 'Moderate interaction with teams in stress.',
        high: 'Maintains strong team interaction under pressure.'
      },
      frequency: {
        rare: 'Rarely interacts effectively with teams.',
        moderate: 'Occasionally interacts well with teams.',
        frequent: 'Frequently interacts effectively with teams.'
      },
      comparison: {
        belowAverage: 'Less effective at team interaction than others.',
        average: 'Team interaction is average.',
        aboveAverage: 'Better at team interaction than most.'
      }
    }
  };

  const fetchDiscReport = async () => {
    try {
      const accessToken = userDetails?.session.accessToken || null;
      if (!accessToken) {
        throw new Error('Not Authorized');
      }

      const apiUrl = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/personality-test/${testId}/response?disc=true`;
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      };

      const response = await fetch(apiUrl, options);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response text:', errorText);

        if (errorText.startsWith('<!DOCTYPE')) {
          throw new Error('Received HTML response, expected JSON.');
        } else {
          const errorJson = JSON.parse(errorText);
          throw new Error(errorJson.message || 'Failed to fetch DISC report.');
        }
      }

      const result = await response.json();
      setDiscReport(result.discTest.analysis.analysis);
    } catch (error) {
      console.error('Error fetching DISC report:', error);
      toast.error(error.message || 'An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 940);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchDiscReport();
  }, [testId]);

  useEffect(() => {
    if (discReport) {
      // Destroy existing charts if any
      if (adaptedStyleChart) {
        adaptedStyleChart.destroy();
      }
      if (naturalStyleChart) {
        naturalStyleChart.destroy();
      }

      // Render Adapted Style Chart
      const adaptedStyleCtx = document
        .getElementById('adaptedStyleChart')
        .getContext('2d');
      const newAdaptedStyleChart = new Chart(adaptedStyleCtx, {
        type: 'bar',
        data: {
          labels: ['D', 'I', 'S', 'C'],
          datasets: [
            {
              label: 'Adapted Style',
              data: [
                discReport.adaptedStyle.D,
                discReport.adaptedStyle.I,
                discReport.adaptedStyle.S,
                discReport.adaptedStyle.C
              ],
              backgroundColor: createGradient(
                adaptedStyleCtx,
                'rgba(255, 99, 132, 0.6)',
                'rgba(54, 162, 235, 0.6)'
              ),
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 2,
              borderRadius: 10
            }
          ]
        },
        options: getChartOptions()
      });
      setAdaptedStyleChart(newAdaptedStyleChart);

      // Render Natural Style Chart
      const naturalStyleCtx = document
        .getElementById('naturalStyleChart')
        .getContext('2d');
      const newNaturalStyleChart = new Chart(naturalStyleCtx, {
        type: 'bar',
        data: {
          labels: ['D', 'I', 'S', 'C'],
          datasets: [
            {
              label: 'Natural Style',
              data: [
                discReport.naturalStyle.D,
                discReport.naturalStyle.I,
                discReport.naturalStyle.S,
                discReport.naturalStyle.C
              ],
              backgroundColor: createGradient(
                naturalStyleCtx,
                'rgba(153, 102, 255, 0.6)',
                'rgba(255, 159, 64, 0.6)'
              ),
              borderColor: 'rgba(153, 102, 255, 1)',
              borderWidth: 2,
              borderRadius: 10
            }
          ]
        },
        options: getChartOptions()
      });
      setNaturalStyleChart(newNaturalStyleChart);
    }
  }, [discReport]);

  const createGradient = (ctx, colorStart, colorEnd) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, colorStart);
    gradient.addColorStop(1, colorEnd);
    return gradient;
  };

  const getChartOptions = () => ({
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        grid: { color: 'rgba(0, 0, 0, 0.05)' }
      },
      x: { grid: { color: 'rgba(0, 0, 0, 0.05)' } }
    },
    plugins: { legend: { display: false } }
  });
  const downloadPDF = async () => {
    setPdfLoading(true);
    const element = pdfContentRef.current;
    const options = {
      filename: 'DISC_Report.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: 'mm', format: [335, 397], orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };

    try {
      const pdf = await html2pdf().set(options).from(element).save();
      toast.success('PDF generated successfully!');
    } catch (error) {
      console.error('Error generating PDF:', error);
      toast.error('Failed to generate PDF. Please try again.');
    } finally {
      setPdfLoading(false);
    }
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (!discReport) {
    return <Alert variant="danger">Failed to load DISC report.</Alert>;
  }

  return (
    <>
      <div className="container pb-5">
        <header className="text-center">
          <button
            onClick={() => navigate('/personality-test')}
            className="btn btn-primary btn-lg shadow-lg animate-fadeIn mb-1 back-button rounded-pill"
          >
            Back
          </button>
        </header>
        <div className="container-fluid py-2" ref={pdfContentRef}>
          <style>
            {`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
          .animate-fadeIn {
            animation: fadeIn 1.5s ease-in-out;
          }
          .bar-chart-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            min-height: 340px;
            max-height: 400px;
            background: linear-gradient(135deg, rgba(96, 165, 250, 0.1), rgba(59, 130, 246, 0.1));
            border-radius: 15px;
            padding: 20px;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
            display: block;
            justify-content: center;
            align-items: center;
            overflow: hidden; /* Prevents content from overflowing */
          }

          .bar-chart-container canvas {
            width: 100% !important;
            height: 100% !important; /* Adjusts the canvas to fit the container's height */
          }

          @media (max-width: 768px) {
            .bar-chart-container {
              max-height: 300px; /* Reduces maximum height for mobile screens */
              padding: 15px; /* Adjust padding for smaller screens */
            }
          }

          .pattern-card {
            background: linear-gradient(135deg, rgba(96,165,250,0.2), rgba(59,130,246,0.1));
            border: 1px solid rgba(59,130,246,0.2);
          }
          .tendencies-card {
            background: linear-gradient(135deg, rgba(96,165,250,0.2), rgba(59,130,246,0.1));
            border: 1px solid rgba(59,130,246,0.2);
            position: relative;
            overflow: hidden;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            width:100%;
          }
          .tendencies-card:hover {
            transform: translateY(-10px);
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
          }
          .tendencies-icon {
            background: rgba(96,165,250,0.2);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 28px;
            color: rgba(59,130,246,1);
            transition: background 0.3s ease, transform 0.3s ease;
          }
          .tendencies-card:hover .tendencies-icon {
            background: rgba(96,165,250,0.4);
            transform: scale(1.1);
          }
          .tendencies-content {
            position: relative;
            z-index: 2;
          }
          .tendencies-card:before {
            content: '';
            position: absolute;
            top: -50%;
            left: -50%;
            width: 200%;
            height: 200%;
            background: rgba(96,165,250,0.1);
            transition: transform 0.5s ease;
            transform: rotate(45deg) translate(0, 0);
            z-index: 1;
          }
          .tendencies-card:hover:before {
            transform: rotate(45deg) translate(20%, 20%);
          }
          .grid-label {
            font-weight: 600;
            color: rgba(59,130,246,1);
            font-size: 1.25rem;
          }
          .grid-value {
            font-weight: 700;
            color: #374151;
            font-size: 1.5rem;
          }
          .section-container {
            margin-bottom: 3rem;
            padding: 2rem;
            background: linear-gradient(135deg, #ebf4ff, #e2e8f0);
            border-radius: 15px;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease, box-shadow 0.3s ease;
          }
          .section-container:hover {
            transform: translateY(-5px);
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
          }
          .section-header {
            font-size: 2.5rem;
            font-weight: 800;
            color: #1f2937;
            margin-bottom: 2rem;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            position: relative;
          }
          .section-header::after {
            content: '';
            width: 80px;
            height: 4px;
            background-color: #3b82f6;
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
          }
          .section-content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            font-size: 1.25rem;
            color: #4b5563;
          }
          .content-card {
            background-color: #ffffff;
            padding: 1.5rem 2rem;
            border-radius: 15px;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            position: relative;
          }
          .content-card:hover {
            transform: translateY(-3px);
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
          }
          .content-card::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            background-color: #3b82f6;
            border-radius: 15px 0 0 15px;
          }
          .content-text {
            color: #4b5563;
            font-size: 1.125rem;
            line-height: 1.75rem;
          }
          .content-title {
            font-weight: 700;
            color: #1f2937;
            margin-bottom: 1rem;
            font-size: 1.5rem;
          }
          .bullet-list {
            list-style-type: disc;
            padding-left: 1.5rem;
            margin-top: 0.5rem;
          }
          .bullet-list li {
            margin-bottom: 0.5rem;
          }
          .equal-height {
            display: flex;
            flex-direction: column;
          }
          .equal-height > div {
            flex: 1;
          }
          .word-matrix{
            display:grid;
            grid-template-columns: repeat(3,1fr);
            grid-template-columns: repeat(auto,1fr);
          }
          .pattern-heading-desktop{
            display:block;
          }
          .pattern-heading-mobile{
            display:none;
          }
          .tendencies-card-body{
            display:grid;
            grid-template-columns: repeat(2,1fr);
            grid-template-rows: repeat(2,1fr);
          }
          @media screen and (max-width: 600px) {
            .tendencies-card-body {
              grid-template-columns: repeat(1,1fr);
              grid-template-rows: repeat(4,auto);
            }
            .pattern-heading-desktop{
              display:none;
            }
            .pattern-heading-mobile{
              display:block;
            }
          }
          @media screen and (min-width: 600px) and (max-width: 1024px) {

          }
          @media print {
            body {
              -webkit-print-color-adjust: exact !important;
              print-color-adjust: exact !important;
            }
            .page-break {
              page-break-before: always;
            }
            .container-fluid {
              width: 100%;
              padding-right: 15px;
              padding-left: 15px;
              margin-right: auto;
              margin-left: auto;
            }
            .text-center {
              text-align: center !important;
            }
          }
          `}
          </style>
          <div className="text-center">
            <header className="mb-5">
              <h1 className="display-3 fw-bold mt-1">
                DISC Personality Report
              </h1>
            </header>

            <section class="mb-5 p-4 bg-white rounded-3xl shadow-lg position-relative overflow-hidden rounded">
              <div class=" text-center">
                <h2
                  className="display-5"
                  style={{ color: 'rgba(33, 37, 41, 0.75)' }}
                >
                  You are{' '}
                  <span style={{ color: '#3b82f6' }}>
                    {discReport.discStyle}
                  </span>
                </h2>

                <p
                  class="lead mt-4 mx-auto"
                  style={{
                    color: 'rgba(33, 37, 41, 0.75)',
                    maxWidth: '700px',
                    fontWeight: '600'
                  }}
                >
                  {discReport.discStyleDescription}
                </p>
              </div>
            </section>

            {/* What is the DISC Report? Section */}
            <section class="mb-5 p-5 bg-white rounded-3xl shadow-lg position-relative overflow-hidden rounded">
              <div class=" text-center">
                <h3
                  class="display-6 fw-bold text-black mb-5 position-relative"
                  style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }}
                >
                  What is the DISC Report?
                  <span
                    class="position-absolute"
                    style={{
                      bottom: '-10px', // Adjusted value
                      width: '120px',
                      left: 'calc(50% - 60px)',
                      height: '4px',
                      backgroundColor: '#3b82f6',
                      zIndex: 1 // Ensure it is on top
                    }}
                  ></span>
                </h3>
                <p
                  class="mx-auto"
                  style={{
                    color: 'rgba(33, 37, 41, 0.75)',
                    fontSize: '1.2rem',
                    lineHeight: '1.6'
                  }}
                >
                  The DISC Personality Report helps uncover your behavioral
                  tendencies, showing how you naturally behave in comfortable
                  environments and how you adapt to different situations. It is
                  a tool for understanding your communication styles, not a
                  measure of skill or intelligence. This report provides
                  insights valuable for self-reflection, as well as for
                  employers or colleagues looking to foster better working
                  relationships.
                </p>
              </div>
            </section>

            {/* DISC Patterns Section */}
            <section className="bg-white rounded-3xl shadow-lg p-5 mb-5 rounded">
              <h3 className="display-6 fw-bold mb-5 text-center position-relative">
                DISC PATTERNS
                <span
                  className="position-absolute"
                  style={{
                    bottom: '-10px', // Adjusted value
                    width: '120px',
                    left: 'calc(50% - 60px)',
                    height: '4px',
                    backgroundColor: '#3b82f6',
                    zIndex: 1 // Ensure it is on top
                  }}
                />
              </h3>
              <p
                class="text-center mb-5"
                style={{
                  color: 'rgba(33, 37, 41, 0.75)'
                }}
              >
                DISC Patterns break your behavior into four primary traits:{' '}
                <strong>Dominance (D)</strong>, <strong>Influence (I)</strong>,{' '}
                <strong>Steadiness (S)</strong>, and{' '}
                <strong>Conscientiousness (C)</strong>. Below, you'll see how
                your behavior manifests in both your Adapted and Natural
                patterns. Understanding these can offer valuable insight into
                how you approach challenges, interact with others, and make
                decisions.
              </p>
              <div className="row g-4">
                <div className="col-md-6 d-flex">
                  <div className="pattern-card p-4 rounded-3xl shadow-lg d-flex flex-column justify-content-between  rounded-lg shadow-sm hover-shadow-lg">
                    <div className="d-flex flex-column justify-content-between">
                      <div class="display-4 fw-bold text-primary pattern-heading-mobile">
                        {discReport.patterns.adapted}
                      </div>
                      <h4 className="h4 fw-bold text-center mb-2">
                        Adapted Pattern
                      </h4>
                    </div>
                    <div class="d-flex align-items-center justify-content-center text-center position-relative">
                      <div class="display-4 fw-bold text-primary me-4 pattern-heading-desktop">
                        {' '}
                        {discReport.patterns.adapted}
                      </div>
                      <p
                        class="lead mb-0"
                        style={{ color: 'rgba(33, 37, 41, 0.75)' }}
                      >
                        This pattern represents how you modify your behavior to
                        fit your environment (e.g., at work or in challenging
                        situations).
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="pattern-card p-4 rounded-3xl shadow-lg d-flex flex-column justify-content-between  p-4 rounded-lg shadow-sm hover-shadow-lg">
                    <div className="d-flex flex-column justify-content-between">
                      <div class="display-4 fw-bold text-primary pattern-heading-mobile">
                        {discReport.patterns.adapted}
                      </div>
                      <h4 className="h4 fw-bold text-center mb-2">
                        Natural Pattern
                      </h4>
                    </div>
                    <div class="d-flex align-items-center justify-content-center text-center position-relative">
                      <div class="display-4 fw-bold text-primary me-4  pattern-heading-desktop">
                        {discReport.patterns.natural}
                      </div>
                      <p
                        class="lead mb-0"
                        style={{ color: 'rgba(33, 37, 41, 0.75)' }}
                      >
                        This pattern reflects your natural behavior when you are
                        relaxed or comfortable, showing your true tendencies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Style Analysis Section */}
            <section className="bg-white rounded-3xl shadow-lg p-5 mb-5 rounded">
              <h3 className="display-6 fw-bold mb-5 text-center position-relative">
                STYLE ANALYSIS
                <span
                  className="position-absolute"
                  style={{
                    bottom: '-10px', // Adjusted value
                    width: '120px',
                    left: 'calc(50% - 60px)',
                    height: '4px',
                    backgroundColor: '#3b82f6',
                    zIndex: 1 // Ensure it is on top
                  }}
                />
              </h3>
              <p class=" mb-5" style={{ color: 'rgba(33, 37, 41, 0.75)' }}>
                The following charts provide an analysis of your behavior across
                the four primary DISC traits: <strong>Dominance (D)</strong>{' '}
                (how you assert control), <strong>Influence (I)</strong> (how
                you connect with others), <strong>Steadiness (S)</strong> (your
                patience and persistence), and{' '}
                <strong>Conscientiousness (C)</strong> (your attention to
                detail). The Adapted Style chart shows how you adjust in your
                environment, while the Natural Style chart reflects your innate
                behaviors.
              </p>
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="bar-chart-container">
                    <canvas id="adaptedStyleChart"></canvas>
                    <p class="text-center text-muted mt-3">
                      <strong>Adapted Style</strong> <br />
                      How you modify your behavior in response to external
                      demands (e.g., work).
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="bar-chart-container">
                    <canvas id="naturalStyleChart"></canvas>
                    <p class="text-center text-muted mt-3">
                      <strong>Natural Style</strong> <br />
                      Your natural, instinctive behaviors in a comfortable
                      environment.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* Actionable Recommendations Section */}
            <section
              className={`mb-5 bg-white rounded-3xl shadow-lg rounded ${
                isMobile ? 'pt-5' : 'p-5'
              }`}
            >
              <h3 className="display-6 fw-bold mb-5 text-center position-relative mx-5">
                ACTIONABLE RECOMMENDATIONS
                <span
                  className="position-absolute"
                  style={{
                    bottom: '-10px', // Adjusted value
                    width: '120px',
                    left: 'calc(50% - 60px)',
                    height: '4px',
                    backgroundColor: '#3b82f6',
                    zIndex: 1 // Ensure it is on top
                  }}
                />
              </h3>
              <div className={`${isMobile ? 'pb-5 pl-5 pr-5' : ''}`}>
                <p
                  class="text-center mb-5"
                  style={{
                    color: 'rgba(33, 37, 41, 0.75)'
                  }}
                >
                  This section outlines specific actions you can take to
                  leverage your natural DISC style in both personal and
                  professional settings. The items listed here are designed to
                  help you maximize your strengths, adapt to challenges, and
                  improve your effectiveness in various scenarios. Consider
                  these as practical steps for growth and improvement.
                </p>
                <div className="row g-4">
                  {discReport.actionableRecommendations.map((rec, index) => (
                    <div key={index} className="col-md-6 d-flex">
                      <div className="pattern-card p-4 bg-white rounded-3xl shadow-lg d-flex flex-column w-100">
                        <h4 className="h4 fw-bold text-center mb-2">
                          {rec.title}{' '}
                        </h4>

                        <p
                          class="lead mb-0"
                          style={{ color: 'rgba(33, 37, 41, 0.75)' }}
                        >
                          {rec.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            <div className="page-break"></div>

            {/* General Characteristics Section */}
            <section className="mb-5 p-5 bg-white rounded-3xl shadow-lg rounded">
              <h3 className="display-6 fw-bold mb-5 text-center position-relative">
                GENERAL CHARACTERISTICS
                <span
                  className="position-absolute"
                  style={{
                    bottom: '-10px', // Adjusted value
                    width: '120px',
                    left: 'calc(50% - 60px)',
                    height: '4px',
                    backgroundColor: '#3b82f6',
                    zIndex: 1 // Ensure it is on top
                  }}
                />
              </h3>
              <p
                class="text-center mb-5"
                style={{
                  color: 'rgba(33, 37, 41, 0.75)'
                }}
              >
                In this section, you will find an overview of your core
                personality traits based on your DISC assessment. These
                characteristics reflect how you naturally behave in different
                situations, such as how you communicate, respond to stress, and
                interact with others. This information will give you a deeper
                understanding of your inherent tendencies and how they influence
                your decisions and relationships.
              </p>

              <div className="row g-4">
                {discReport.general_characteristics.narrative.map(
                  (nar, index) => (
                    <div key={index} className="col-md-6 d-flex">
                      <div className="pattern-card characteristic-card p-4 bg-white rounded-3xl shadow-lg d-flex flex-column w-100">
                        <h4 className="h4 fw-bold text-center mb-2">
                          {nar.title}{' '}
                        </h4>
                        <p
                          class="lead mb-0"
                          style={{ color: 'rgba(33, 37, 41, 0.75)' }}
                        >
                          {nar.description}
                        </p>
                      </div>
                    </div>
                  )
                )}
              </div>
            </section>

            {/* WHAT YOU BRING TO THE ORGANIZATION Section */}
            <section className="mb-5 p-5 bg-white rounded-3xl shadow-lg rounded">
              <h3 className="display-6 fw-bold mb-5 text-center position-relative">
                WHAT YOU BRING TO THE ORGANIZATION
                <span
                  className="position-absolute"
                  style={{
                    bottom: '-10px', // Adjusted value
                    width: '120px',
                    left: 'calc(50% - 60px)',
                    height: '4px',
                    backgroundColor: '#3b82f6',
                    zIndex: 1 // Ensure it is on top
                  }}
                />
              </h3>
              <p
                class="text-center mb-5"
                style={{
                  color: 'rgba(33, 37, 41, 0.75)'
                }}
              >
                This section provides insights into the strengths, qualities,
                and skills you contribute, such as your approach to teamwork,
                problem-solving, and leadership. Use this information to better
                understand how your natural traits positively impact the
                organization and how you can further enhance your contributions.
              </p>
              <div className="row g-4">
                {/* Strengths Section */}
                <div className="col-md-6 mb-4">
                  <div className="pattern-card characteristic-card p-4 bg-white rounded-3xl shadow-lg d-flex flex-column w-100">
                    <h4 className="h4 fw-bold text-center mb-2">Strengths</h4>
                    {discReport.what_you_bring_to_the_organization.strengths.map(
                      (strength, index) => (
                        <li
                          key={index}
                          className="lead mb-2"
                          style={{ color: 'rgba(33, 37, 41, 0.75)' }}
                          mb-0
                        >
                          {strength}
                        </li>
                      )
                    )}
                  </div>
                </div>

                {/* Work Style Tendencies Section */}
                <div className="col-md-6 mb-4">
                  <div className="pattern-card characteristic-card p-4 bg-white rounded-3xl shadow-lg d-flex flex-column w-100">
                    <h4 className="h4 fw-bold text-center mb-2">
                      Work Style Tendencies
                    </h4>
                    {discReport.what_you_bring_to_the_organization.work_style_tendencies.map(
                      (tendency, index) => (
                        <li
                          key={index}
                          className="lead mb-2"
                          style={{ color: 'rgba(33, 37, 41, 0.75)' }}
                        >
                          {tendency}
                        </li>
                      )
                    )}
                  </div>
                </div>

                {/* Effective Environments Section */}
                <div className="col-md-6 mb-4">
                  <div className="pattern-card characteristic-card p-4 bg-white rounded-3xl shadow-lg d-flex flex-column w-100">
                    <h4 className="h4 fw-bold text-center mb-2">
                      Effective Environments
                    </h4>
                    {discReport.what_you_bring_to_the_organization.effective_environments.map(
                      (environment, index) => (
                        <li
                          key={index}
                          className="lead mb-2"
                          style={{ color: 'rgba(33, 37, 41, 0.75)' }}
                        >
                          {environment}
                        </li>
                      )
                    )}
                  </div>
                </div>
              </div>
            </section>

            <div className="page-break"></div>

            {/* Behavioral Tendencies Details Section */}
            <section className="mb-5 p-5 bg-white rounded-3xl shadow-lg rounded">
              <h3 className="display-6 fw-bold mb-5 text-center position-relative">
                BEHAVIORAL TENDENCIES DETAILS
                <span
                  className="position-absolute"
                  style={{
                    bottom: '-10px', // Adjusted value
                    width: '120px',
                    left: 'calc(50% - 60px)',
                    height: '4px',
                    backgroundColor: '#3b82f6',
                    zIndex: 1 // Ensure it is on top
                  }}
                />
              </h3>
              <p className="mb-5" style={{ color: 'rgba(33, 37, 41, 0.75)' }}>
                This section breaks down your behavioral tendencies into
                detailed metrics: <strong>Natural</strong> (your instinctive
                behavior), <strong>Adapted</strong> (how you adjust to your
                environment), <strong>Frequency</strong> (how often this
                behavior occurs), and <strong>Comparison</strong> (how you
                compare to others).
              </p>

              <div className="row g-4">
                {Object.entries(
                  discReport.behavioral_tendencies_details_graphs
                ).map(([key, details], index) => {
                  const helperText = behavioralTendencies[key] || {};
                  const {
                    natural: naturalValue,
                    adapted: adaptedValue,
                    frequency: frequencyValue,
                    general_population_comparison: comparisonValue
                  } = details;

                  // Normalize comparisonValue
                  const comparison =
                    {
                      'above average': 'aboveAverage',
                      'below average': 'belowAverage',
                      average: 'average'
                    }[comparisonValue.trim().toLowerCase()] || comparisonValue;

                  // Helper texts
                  const naturalHelper =
                    helperText.natural?.[naturalValue.toLowerCase()];
                  const adaptedHelper =
                    helperText.adapted?.[adaptedValue.toLowerCase()];
                  const frequencyHelper =
                    helperText.frequency?.[frequencyValue.toLowerCase()];
                  const comparisonHelper =
                    helperText.comparison?.[comparison] || comparisonValue;

                  return (
                    <div key={index} className="col-md-6 d-flex">
                      <div className="tendencies-card p-4 rounded-lg shadow-sm hover-shadow-lg">
                        <div className="d-flex align-items-center mb-4">
                          <div
                            className="tendencies-icon"
                            style={
                              isMobile
                                ? { padding: '.6rem' }
                                : { width: 60, height: 60 }
                            }
                          >
                            <FaTasks
                              style={isMobile ? { fontSize: '1.2rem' } : {}}
                            />
                          </div>
                          <h4 className="h4 grid-value text-black ms-3">
                            {key
                              .replace(/_/g, ' ')
                              .replace(/^\w/, (c) => c.toUpperCase())}
                          </h4>
                        </div>
                        <p
                          class="text-left mb-4"
                          style={{ color: 'rgba(33, 37, 41, 0.75)' }}
                        >
                          {helperText.helper}{' '}
                        </p>
                        <div className="tendencies-card-body">
                          {[
                            {
                              label: 'Natural',
                              value: naturalValue,
                              helper: naturalHelper
                            },
                            {
                              label: 'Adapted',
                              value: adaptedValue,
                              helper: adaptedHelper
                            },
                            {
                              label: 'Frequency',
                              value: frequencyValue,
                              helper: frequencyHelper
                            },
                            {
                              label: 'Comparison',
                              value: comparisonValue,
                              helper: comparisonHelper
                            }
                          ].map(({ label, value, helper }, idx) => (
                            <div key={idx} className=" text-left">
                              <p className="grid-label">{label}:</p>
                              <p className="h4 fw-bold mb-2">{value}</p>
                              <p style={{ color: 'rgba(33, 37, 41, 0.75)' }}>
                                {helper}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>

            {/* Communication Tips Section */}
            <section className="mb-2 p-5 bg-white rounded-3xl shadow-lg rounded">
              <h3 className="display-6 fw-bold mb-5 text-center position-relative">
                COMMUNICATION TIPS
                <span
                  className="position-absolute"
                  style={{
                    bottom: '-10px', // Adjusted value
                    width: '120px',
                    left: 'calc(50% - 60px)',
                    height: '4px',
                    backgroundColor: '#3b82f6',
                    zIndex: 1 // Ensure it is on top
                  }}
                />
              </h3>
              <p
                class="text-center mb-5"
                style={{ color: 'rgba(33, 37, 41, 0.75)' }}
              >
                These tips are for those who want to communicate effectively
                with the individual who took the test. Understanding these dos
                and don'ts will help improve communication, foster better
                relationships, and enhance teamwork with this person.
              </p>
              <div className="row g-4">
                <div className="col-md-6 d-flex">
                  <div className="pattern-card communication-card p-4 bg-white rounded-3xl shadow-lg d-flex flex-column w-100 shadow-sm hover-shadow-lg">
                    <h4
                      className="h3 fw-bold text-left mb-3"
                      style={{ color: '#198754' }}
                    >
                      {"Do's"}
                    </h4>
                    <ul className="list-unstyled flex-grow-1">
                      {discReport.communication_tips.do.map((tip, index) => (
                        <li
                          key={index}
                          className="mb-3 d-flex align-items-center"
                        >
                          <FaCheckCircle
                            className="h5 mb-0 mr-2"
                            style={{ color: '#198754', flexShrink: 0 }}
                          />
                          <p className="mb-0 h5 text-left flex-grow-1">{tip}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="pattern-card communication-card p-4 bg-white rounded-3xl shadow-lg d-flex flex-column w-100 shadow-sm hover-shadow-lg">
                    <h4
                      className="h3 fw-bold text-left mb-3"
                      style={{ color: '#dc3545' }}
                    >
                      {"Don'ts"}
                    </h4>
                    <ul className="list-unstyled flex-grow-1">
                      {discReport.communication_tips.dont.map((tip, index) => (
                        <li
                          key={index}
                          className="mb-3 d-flex align-items-center"
                        >
                          <FaTimesCircle
                            className="h5 mb-0 mr-2"
                            style={{ color: '#dc3545', flexShrink: 0 }}
                          />
                          <p className="mb-0 h5 text-left flex-grow-1">{tip}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <footer className="my-4 d-flex flex-column align-items-center justify-content-center">
          <PDFDownloadButton
            label={'Download PDF'}
            loadingLabel={'Generating PDF...'}
            isLoading={pdfLoading}
            onClick={downloadPDF}
          />
          <p className="mt-3" style={{ color: 'rgba(33, 37, 41, 0.75)' }}>
            Download your full DISC report in PDF format to review anytime.
          </p>
        </footer>
      </div>
    </>
  );
};

export default JobDiscView;
