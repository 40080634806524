import React, { useState, useRef, useEffect } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import {
  changePassAction,
  loadingToggleAction,
  passChangeAction
} from '../../store/actions/AuthActions';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ChangePasswordModal = ({
  isOpen,
  toggleModal,
  onClose,
  showLoading,
  passChanged
}) => {
  let errorsObj = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  };
  const [errors, setErrors] = useState(errorsObj);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const firstInputRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (passChanged == true) {
      onClose();
      dispatch(passChangeAction(false));
    }
  }, [passChanged]);

  useEffect(() => {
    if (isOpen && firstInputRef.current) {
      firstInputRef.current.focus();
      setOldPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setShowOldPassword(false);
      setShowNewPassword(false);
      setShowConfirmNewPassword(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  function onChangePassword(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (oldPassword == '') {
      errorObj.oldPassword = 'Old Password is Required';
      error = true;
    }

    if (newPassword == '') {
      errorObj.newPassword = 'New Password is Required';
      error = true;
    }
    if (newPassword.length < 6) {
      errorObj.newPassword = 'New Password is very short';
      error = true;
    }
    if (newPassword == oldPassword) {
      errorObj.newPassword = 'New Password cannot be same as Old Password';
      error = true;
    }
    if (confirmNewPassword == '' || confirmNewPassword != newPassword) {
      errorObj.confirmNewPassword =
        'New Password and Confirm New Password does not match';
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(changePassAction(oldPassword, newPassword));
  }

  return (
    <>
      {isOpen && (
        <div
          class="position-fixed top-0 start-0 w-100 h-100 custom-bg-opacity-60 d-flex align-items-center justify-content-center custom-z-50"
          onClick={toggleModal}
        >
          <div
            class="bg-white p-4 rounded shadow-lg mx-auto w-100 position-relative custom-max-w-md"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              type="button"
              class="custom-text-gray-400 bg-transparent custom-hover-bg-gray-200 custom-hover-text-gray-900 rounded text-sm p-1.5 ms-auto d-inline-flex align-items-center custom-dark-hover-bg-gray-600 custom-dark-hover-text-white"
              onClick={onClose}
            >
              <svg
                aria-hidden="true"
                class="custom-w-5 custom-h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="visually-hidden">Close modal</span>
            </button>
            <div id="change-password" className="auth-form   form-validation">
              {/* {props.errorMessage && (
                          <div className="bg-danger text-danger border border-danger p-1 my-2">
                            {props.errorMessage}
                          </div>
                        )}
                        {props.successMessage && (
                          <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                            {props.successMessage}
                          </div>
                        )} */}
              <form
                // onSubmit={onResetOldPassword}
                className="form-validate"
              >
                <h3 className="text-center mb-4 text-black">Change Password</h3>
                <div className="form-group mb-3 position-relative">
                  <label className="mb-1">
                    <strong>Old Password</strong>
                  </label>
                  <input
                    type={showOldPassword ? 'text' : 'password'}
                    className="form-control"
                    value={oldPassword}
                    placeholder="Type Your Password"
                    onChange={(e) => setOldPassword(e.target.value)}
                    ref={firstInputRef}
                  />
                  <div
                    className="position-absolute ms-2 d-flex align-items-center justify-content-center fa-eye-icon"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  >
                    {showOldPassword ? (
                      <FaEye size="20px" />
                    ) : (
                      <FaEyeSlash size="21px" />
                    )}
                  </div>
                  {errors.oldPassword && (
                    <div className="text-danger fs-12">
                      {errors.oldPassword}
                    </div>
                  )}
                </div>
                <div className="form-group mb-3 position-relative">
                  <label className="mb-1">
                    <strong>New Password</strong>
                  </label>
                  <input
                    type={showNewPassword ? 'text' : 'password'}
                    className="form-control"
                    value={newPassword}
                    placeholder="Password"
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <div
                    className="position-absolute ms-2 d-flex align-items-center justify-content-center fa-eye-icon "
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? (
                      <FaEye size="20px" />
                    ) : (
                      <FaEyeSlash size="21px" />
                    )}
                  </div>
                  {errors.newPassword && (
                    <div className="text-danger fs-12">
                      {errors.newPassword}
                    </div>
                  )}
                </div>
                <div className="form-group mb-3 position-relative">
                  <label className="mb-1">
                    <strong>Confirm New Password</strong>
                  </label>
                  <input
                    type={showConfirmNewPassword ? 'text' : 'password'}
                    className="form-control"
                    value={confirmNewPassword}
                    placeholder="Confirm Password"
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />
                  <div
                    className="position-absolute ms-2 d-flex align-items-center justify-content-center fa-eye-icon"
                    onClick={() =>
                      setShowConfirmNewPassword(!showConfirmNewPassword)
                    }
                  >
                    {showConfirmNewPassword ? (
                      <FaEye size="20px" />
                    ) : (
                      <FaEyeSlash size="21px" />
                    )}
                  </div>
                  {errors.confirmNewPassword && (
                    <div className="text-danger fs-12">
                      {errors.confirmNewPassword}
                    </div>
                  )}
                </div>

                <div className="text-center form-group mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                    disabled={showLoading}
                    onClick={onChangePassword}
                  >
                    {showLoading ? (
                      <div class="custom-spinner"></div>
                    ) : (
                      'Change Password'
                    )}
                  </button>
                </div>
              </form>
              {/* <div className="mb-4 text-center">
                <span>
                  Go to&nbsp;
                  <span
                    type="button"
                    className="text-blue-500 hover-underline"
                    onClick={() => {
                      setIsResetModalOpen(!isResetModalOpen);
                      // dispatch(generateOtpAction(false));
                      setEnableResendOtp(false);
                    }}
                    // disabled={loading}
                  >
                    Sign in
                  </span>
                </span>
              </div> */}
              {/* <div className="new-account mt-3">
						<p>
							Don't have an account?{" "}
							<Link className="text-primary" to="/page-register">
								Sign up
							</Link>
						</p>
					</div> */}
            </div>
          </div>
          {/* {loading && (
            <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75">
              <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
            </div>
          )} */}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
    passChanged: state.auth.passChanged
  };
};
export default connect(mapStateToProps)(ChangePasswordModal);
