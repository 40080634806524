import { useState, useEffect } from 'react';
import './styles.css';

export function SlackConnect({ slackDetails, setSlackDetails }) {
  const [isConnected, setIsConnected] = useState(false);

  const fetchSlackIntegrationDetails = async () => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const orgId = userDetails?.user?.organizationId;
    if (!orgId) {
      return;
    }

    try {
      const [integrationResponse, channelsResponse] = await Promise.all([
        fetch(
          `${process.env.REACT_APP_DEV_API}/v1/integration/slack/integration-details?organizationId=${orgId}`
        ),
        fetch(
          `${process.env.REACT_APP_DEV_API}/v1/integration/slack/channels/${orgId}`
        )
      ]);

      const [integrationData, channelsData] = await Promise.all([
        integrationResponse.json(),
        channelsResponse.json()
      ]);

      const updatedUsers =
        channelsData.users?.filter(
          (user) => !user.deleted && !user.is_bot && !!user.is_email_confirmed
        ) || [];

      setSlackDetails({
        channels: channelsData.channels || [],
        users: updatedUsers,
        selectedChannels: integrationData.channels || [],
        integrationId: integrationData._id,
        isLoaded: true,
        slackTeamId: integrationData.slackTeamId,
        slackTeamName: integrationData.slackTeamName
      });

      if (integrationData._id) {
        setIsConnected(true);
      }
    } catch (error) {
      console.error('Error fetching Slack details:', error);
      setSlackDetails((prev) => ({
        ...prev,
        isLoaded: true
      }));
    }
  };

  useEffect(() => {
    fetchSlackIntegrationDetails();
  }, []);

  const handleConnect = () => {
    const currentUrl =
      typeof window !== 'undefined' ? window.location.href : '';
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const orgId = userDetails?.user?.organizationId;
    if (!orgId) {
      return;
    }
    // const slackAuthUrl = `https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=chat:write,channels:read,groups:read,channels:join,groups:write,users:read,im:write&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URI}&state=${orgId}____${currentUrl}`;

    const slackAuthUrl = `https://slack.com/oauth/v2/authorize?client_id=3172119403893.8265148527684&scope=chat:write,channels:read,groups:read,channels:join,groups:write,users:read,im:write&redirect_uri=https://api-dev.talentswap.ai/v1/integration/slack/oauth&state=${orgId}____${currentUrl}`;

    window.location.href = slackAuthUrl;
  };

  const handleDisconnect = async () => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const orgId = userDetails?.user?.organizationId;
    if (!orgId) {
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/integration/slack/${orgId}`,
        {
          method: 'DELETE'
        }
      );
      const data = await response.json();
      setIsConnected(false);
    } catch (error) {
      console.error('Error disconnecting from Slack:', error);
      // toast.error('Error disconnecting from Slack');
    }
  };

  return (
    <div className="slack-integration-container">
      <div className="slack-status-section">
        <div className="slack-logo-wrapper">
          <i className="fab fa-slack fa-2x slack-logo"></i>
        </div>
        <div className="slack-status-content">
          <div className="slack-status-header">
            <div className="status-indicator-wrapper">
              <div
                className={`status-indicator ${isConnected ? 'status-connected' : 'status-disconnected'}`}
              />
              <span className="status-text">
                {isConnected ? 'Connected to Slack' : 'Not connected'}
              </span>
            </div>
            <button
              className={`slack-button ${isConnected ? 'disconnect' : 'connect'}`}
              onClick={isConnected ? handleDisconnect : handleConnect}
            >
              <i
                className={`fas ${isConnected ? 'fa-unlink' : 'fa-link'} me-2`}
              ></i>
              {isConnected ? 'Disconnect' : 'Connect to Slack'}
            </button>
          </div>

          {isConnected ? (
            <div className="connected-content">
              <div className="workspace-info">
                <div className="workspace-header">
                  <div className="workspace-title">
                    <div className="workspace-icon">
                      <i className="fas fa-building"></i>
                    </div>
                    <div className="workspace-details">
                      <h4>{slackDetails.slackTeamName}</h4>
                      <div className="workspace-meta">
                        <span className="status-tag">
                          <i className="fas fa-circle"></i>
                          Active
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="slack-benefits">
              <div className="benefit-item">
                <i className="fas fa-bolt"></i>
                <span>Get instant notifications</span>
              </div>
              <div className="benefit-item">
                <i className="fas fa-users"></i>
                <span>Collaborate with your team</span>
              </div>
              <div className="benefit-item">
                <i className="fas fa-shield-alt"></i>
                <span>Secure OAuth integration</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
