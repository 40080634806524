import React, { useContext, useEffect, useState } from 'react';

/// React router dom
import { Routes, Route, Outlet } from 'react-router-dom';

/// Css
import './index.css';
import './chart.css';
import './step.css';

/// Organisation
import CreateOrganisation from './components/Organisation/CreateOrganisation';
import ListOrganisation from './components/Organisation/ListOrganisation';

/// Layout
import Nav from './layouts/nav';
import Footer from './layouts/Footer';
import ScrollToTop from './pages/ScrollToTop';
import PrivacyPolicy from './pages/PrivacyPolicy';

/// Dashboard
import Home from './components/Dashboard/Home';

//Jobs
import JobLists from './components/Jobs/JobLists';
import JobView from './components/Jobs/JobView';
import JobApplication from './components/Jobs/JobApplication';
import JobApplicationView from './components/Jobs/JobApplicationView';
import JobDiscView from './components/Jobs/JobDiscView';
import JobHvpView from './components/Jobs/JobHvpView';
import NewJob from './components/Jobs/NewJob';
import ThemeCustomization from './components/Jobs/themeCustomization';
import AssistantCustomization from './components/Jobs/assistantCustomization';

/// Pages
import LockScreen from './pages/LockScreen';
import Error400 from './pages/Error400';
import Error403 from './pages/Error403';
import Error404 from './pages/Error404';
import Error500 from './pages/Error500';
import Error503 from './pages/Error503';
import { ThemeContext } from '../context/ThemeContext';
import SuperAdmin from './components/SuperAdmin/superAdmin';
import ProtectedRoute from './layouts/protectedRoute/protectedRoute';
import ReRoute from './layouts/protectedRoute/reroutePage';
import LogoCustomisation from './components/Jobs/logoCustomisation';
import CurrencyCustomization from './components/Jobs/CurrencyCustomization';
import MotivatorsReport from './components/Jobs/MotivatorsReport';
import PersonalityTest from './components/Jobs/PersonalityTest';
import GlobalLoader from './components/AppsMenu/CustomComponents/GlobalLoader';
import Billing from './components/billing';

import Notifications from './components/Notifications';

const Markup = () => {
  // const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  const allroutes = [
    /// allroutes
    { url: '*', component: <ReRoute /> },
    { url: 'dashboard', component: <Home />, role: 'ADMIN' },

    //Jobs
    { url: 'job-list', component: <JobLists />, role: 'ADMIN' },
    { url: 'job-view/:jobId', component: <JobView />, role: 'ADMIN' },
    { url: 'job-application', component: <JobApplication />, role: 'ADMIN' },
    { url: 'notifications', component: <Notifications />, role: 'ADMIN' },
    {
      url: 'job-application/:jobId',
      component: <JobApplication />,
      role: 'ADMIN'
    },
    {
      url: 'job-application-view/:jobId/:appId',
      component: <JobApplicationView />,
      role: 'ADMIN'
    },
    {
      url: 'job-disc-view/:testId',
      component: <JobDiscView />,
      role: 'ADMIN'
    },
    {
      url: 'job-hvp-view/:testId',
      component: <JobHvpView />,
      role: 'ADMIN'
    },
    {
      url: 'motivators-report/:testId',
      component: <MotivatorsReport />,
      role: 'ADMIN'
    },
    { url: 'new-job', component: <NewJob />, role: 'ADMIN' },
    { url: 'update-job/:jobId', component: <NewJob />, role: 'ADMIN' },
    { url: '/clone-job/:jobId', component: <NewJob />, role: 'ADMIN' },
    {
      url: 'theme-customization',
      component: <ThemeCustomization />,
      role: 'ADMIN'
    },
    {
      url: 'logo-customisation',
      component: <LogoCustomisation />,
      role: 'ADMIN'
    },
    {
      url: 'currency-customisation',
      component: <CurrencyCustomization />,
      role: 'ADMIN'
    },
    {
      url: 'assistant-customisation',
      component: <AssistantCustomization />,
      role: 'ADMIN'
    },
    {
      url: 'billing',
      component: <Billing />,
      role: 'ADMIN'
    },
    {
      url: 'privacy-policy',
      component: <PrivacyPolicy />,
      role: 'ADMIN'
    },
    {
      url: 'personality-test',
      component: <PersonalityTest />,
      role: 'ADMIN'
    },

    // Organisations
    {
      url: '/super-admin/create-organisation',
      component: <CreateOrganisation />,
      role: 'SUPERADMIN'
    },
    {
      url: '/super-admin/logo-customisation/:orgId',
      component: <LogoCustomisation />,
      role: 'SUPERADMIN'
    },
    {
      url: '/super-admin/edit-organisation/:orgId',
      component: <CreateOrganisation />,
      role: 'SUPERADMIN'
    },
    {
      url: '/super-admin/list-organisation',
      component: <ListOrganisation />,
      role: 'SUPERADMIN'
    },

    // super admin
    { url: 'super-admin', component: <SuperAdmin />, role: 'SUPERADMIN' }
  ];
  //let path = window.location.pathname;
  // path = path.split("/");
  // path = path[path.length - 1];

  // let pagePath = path.split("-").includes("page");
  return (
    <>
      <Routes>
        <Route path="page-lock-screen" element={<LockScreen />} />
        <Route path="page-error-400" element={<Error400 />} />
        <Route path="page-error-403" element={<Error403 />} />
        <Route path="page-error-404" element={<Error404 />} />
        <Route path="page-error-500" element={<Error500 />} />
        <Route path="page-error-503" element={<Error503 />} />

        {/* <Route element={<ProtectedRoute role={"ADMIN"} />}>
          <Route
            exact
            path={`motivators-report/:testId`}
            element={<MotivatorsReport />}
          />
        </Route> */}
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route element={<ProtectedRoute role={data.role} />}>
              <Route
                key={i}
                exact
                path={`${data.url}`}
                element={data.component}
              />
            </Route>
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);

  return (
    <>
      <div
        id="main-wrapper"
        className={`show ${sidebariconHover ? 'iconhover-toggle' : ''} ${
          menuToggle ? 'menu-toggle' : ''
        }`}
      >
        <Nav />
        <div
          className="content-body"
          style={{ minHeight: window.screen.height - 45 }}
        >
          <div className="container-fluid position-relative">
            <Outlet />
            <GlobalLoader />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Markup;
