import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import './description-generator.css';
import { FaFaceAngry } from 'react-icons/fa6';
import { FaTimes } from 'react-icons/fa';

const AIJobDescriptionGenerator = ({
  onDescriptionGenerated,
  activeTab,
  preFillJobForm
}) => {
  const { jobId } = useParams();
  const [showInitialModal, setShowInitialModal] = useState(
    jobId ? false : true
  );
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('');
  const [titleValidation, setTitleValidation] = useState(false);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const titleInputRef = useRef(null);

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setShowInitialModal(false);
      }
    };

    if (showInitialModal) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showInitialModal]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setShowModal(false);
      }
    };

    if (showModal) {
      window.addEventListener('keydown', handleKeyDown);
      if (titleInputRef.current) {
        setTimeout(() => {
          titleInputRef.current.focus();
        }, 500);
      }
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showModal]);

  const handleInitialClose = () => {
    setShowInitialModal(false);
  };

  const handleClose = () => {
    if (!loading) {
      setShowModal(false);
    }
  };

  const handleShow = () => {
    setShowInitialModal(false);
    setShowModal(true);
  };

  const fetchLatestJob = async () => {
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/
	${orgId}/
	job?page=1&limit=1&sortBy=createdAt&sortOrder=desc`;
    try {
      const response = await fetch(URL);
      if (!response.ok) {
        throw new Error('Fetching Latest Job failed');
      }
      const body = await response.json();
      return body;
    } catch (error) {
      console.error('Error fetching job list', error);
    }
  };

  const generateDescription = async () => {
    if (!title.trim()) {
      toast.error('Please enter a job title');
      setTitleValidation(true);
      return;
    }

    const accessToken = localStorage.getItem('userDetails')
      ? JSON.parse(localStorage.getItem('userDetails')).session.accessToken
      : null;

    if (!accessToken) {
      throw new Error('Access token not found in local storage');
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/generate-description`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ title, description })
        }
      );

      const data = await response.json();
      const latestJobDetails = await fetchLatestJob();
      if (latestJobDetails.total) {
        const latestJob = latestJobDetails.data[0];
        preFillJobForm(latestJob);
      }
      if (response.ok) {
        onDescriptionGenerated(data.data);
        setTitle('');
        setDescription('');
        handleClose();
        toast.success('Job description generated successfully');
      } else {
        throw new Error(data.message || 'Failed to generate job description');
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <>
      <style>
        {`
    @media (min-width: 768px) {
      .modal-dialog-centered {
        width: 50vw !important;
        max-width: 90vw !important;
      }
    }
  `}
      </style>
      {!!(activeTab === 'basic') && !jobId && (
        <Button variant="primary" onClick={handleShow} className="btn-sm">
          Create with AI
        </Button>
      )}

      {/* Initial Modal */}
      <Modal show={showInitialModal} onHide={handleInitialClose} centered>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid #EEEEEE'
          }}
        >
          <div>
            <Modal.Title style={{ padding: '1.875rem', paddingBottom: '1rem' }}>
              Welcome to AI Job Description Builder
            </Modal.Title>
          </div>
          <div>
            <Modal.Header style={{ borderBottom: 'none' }} closeButton={false}>
              <FaTimes
                className="h4 cursor-pointer"
                onClick={handleInitialClose}
              />
            </Modal.Header>
          </div>
        </div>
        <Modal.Body style={{ paddingTop: '0px' }}>
          <p style={{ marginBottom: '1.5rem', paddingTop: '1rem' }}>
            Quickly create professional job listings tailored to your needs. Our
            AI generates accurate titles, descriptions, qualifications, and
            requirements based on your input, saving you time and improving the
            hiring process.
          </p>
          <div className="d-flex justify-content-center">
            <Button variant="primary" onClick={handleShow} className="btn-sm">
              Create Job Description with AI
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Main Modal */}
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop={loading ? 'static' : true}
        keyboard={!loading}
        centered
      >
        <div>
          <Modal.Header
            style={{ padding: '.5rem 1rem 1.2rem 1rem' }}
            closeButton={false}
            className="border position-relative"
          >
            <Modal.Title
              style={{ padding: '0.4rem 0rem 0rem', fontSize: '1.25rem' }}
            >
              Maximize Your Efficiency - Create Job Like a Pro
            </Modal.Title>
            <FaTimes className="h4 cursor-pointer" onClick={handleClose} />
          </Modal.Header>
        </div>

        <Modal.Body className="modal-body-custom">
          <div className="modal-body-tip">
            <h4 className="modal-body-tip-title">
              Tip of the Day: Optimizing Job Descriptions with AI
            </h4>
            <p className="modal-body-tip-description">
              Leverage AI to craft job descriptions that attract top-tier
              candidates. Our system analyzes similar roles and industry trends
              to suggest descriptions that resonate with talent. Try it now by
              autofilling job details based on your provided information!
            </p>
            <p className="modal-body-tip-description">
              <b>How it works:</b> Simply enter the <b>Job Title</b> and any
              additional details you have, and our AI will intelligently
              generate a tailored job description, responsibilities,
              qualifications, and even location suggestions.
            </p>
          </div>

          <Form onKeyDown={handleKeyDown}>
            <Form.Group className="mb-3">
              <Form.Label
                style={{ color: 'black' }}
                className="modal-form-field-label"
              >
                Job Title
              </Form.Label>
              <Form.Control
                className={'modal-form-field-label-input'}
                type="text"
                ref={titleInputRef}
                placeholder="Software Developer"
                value={title}
                isInvalid={titleValidation}
                onChange={(e) => {
                  if (!e.target.value) {
                    setTitleValidation(true);
                  } else {
                    setTitleValidation(false);
                  }
                  setTitle(e.target.value);
                }}
                disabled={loading}
              />
              {titleValidation && (
                <Form.Text className="text-danger">
                  Job Title is required.
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ color: 'black' }}>
                Additional Details (optional)
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter any details you want to include in the Job description: Provide specific information such as key responsibilities, preferred qualifications ..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                disabled={loading}
              />
            </Form.Group>
            <Button
              variant="primary"
              onClick={generateDescription}
              disabled={loading}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                fontSize: '1rem'
              }}
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="ms-2">Generating...</span>
                </>
              ) : (
                'Generate with AI'
              )}
            </Button>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={handleClose}
            disabled={loading}
            tabIndex="-1"
            style={{ fontSize: "1rem" }}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default AIJobDescriptionGenerator;
