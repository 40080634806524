import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './search.css';

const Search = ({ placeHolder, searchVal, setSearchVal, styles = {} }) => {
  const [localSearchVal, setLocalSearchVal] = useState(searchVal);
  const [searchTimeOut, setSearchTimeOut] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const regexSpecialChars = [
    '.',
    '^',
    '$',
    '*',
    '+',
    '?',
    '{',
    '}',
    '[',
    ']',
    '\\',
    '|',
    '(',
    ')'
  ];

  useEffect(() => {
    if (isFocused) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  const startSearchDebounce = (text) => {
    clearTimeout(searchTimeOut);
    setSearchTimeOut(
      setTimeout(() => {
        setSearchVal(encodeURIComponent(text));
      }, 1000)
    );
  };

  const handleSearch = () => {
    setSearchVal(encodeURIComponent(localSearchVal));
  };

  const handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <div
      className={`input-group d-flex align-items-center gap-2 justify-content-between search-area border form-control ${
        isFocused ? 'focused' : ''
      } ${localSearchVal && !isFocused ? 'has-value' : ''}`}
      style={{
        width: '100%',
        maxWidth:
          window.innerWidth > 991 ? (isFocused ? '35rem' : '29rem') : '100%',
        // minWidth:
        //   window.innerWidth > 991 ? (isFocused ? '35rem' : '29rem') : '100%',
        transition: window.innerWidth > 991 ? 'all 0.3s ease' : 'none',
        borderRadius: '25px',
        overflow: 'hidden',
        boxShadow: isFocused ? '0 0 10px rgba(0, 0, 0, 0.1)' : 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...styles
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <input
        ref={inputRef}
        type="text"
        className="flex-grow-1 border-0"
        style={{
          color: '#8E8E8E',
          background: 'transparent',
          outline: 'none',
          fontSize: '1rem',
          lineHeight: '1.5',
          paddingRight: '3rem',
          width: '100%'
        }}
        placeholder={placeHolder || 'Search here'}
        value={localSearchVal}
        onChange={(e) => {
          const text = e.target.value;
          if (regexSpecialChars.find((char) => text.includes(char))) {
            return;
          }
          setLocalSearchVal(text);
          startSearchDebounce(text);
        }}
        onKeyDown={handleSearchKeyDown}
      />
      <span
        onClick={handleSearch}
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'all 0.3s ease',
          position: 'absolute',
          right: '1rem',
          width: '2rem',
          height: '100%'
        }}
      >
        <Link
          className="border-0"
          onClick={handleSearch}
          style={{ color: isFocused ? '#007bff' : '#8E8E8E' }}
        >
          <i className="fs-4 flaticon-381-search-2"></i>
        </Link>
      </span>
    </div>
  );
};

export default Search;
