let direction = 'ltr';

export const dezThemeSet = [
  {
    /* Default */ typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    headerBg: 'color_1',
    navheaderBg: 'color_1',
    sidebarBg: 'color_1',
    sidebarStyle: 'full',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'full',
    direction: direction
  },
  {
    /* Demo Theme 1 */ typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    navheaderBg: 'color_4',
    headerBg: 'color_1',
    sidebarStyle: 'full',
    sidebarBg: 'color_4',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'wide',
    primary: 'color_4',
    direction: direction
  },
  {
    /* Demo Theme 2 */ typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    navheaderBg: 'color_15',
    headerBg: 'color_1',
    sidebarStyle: 'compact',
    sidebarBg: 'color_15',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'wide',
    primary: 'color_15',
    direction: direction
  },
  {
    /* Demo Theme 3 */
    typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    navheaderBg: 'color_1',
    headerBg: 'color_14',
    sidebarStyle: 'modern',
    sidebarBg: 'color_1',
    sidebarPosition: 'static',
    headerPosition: 'fixed',
    containerLayout: 'wide',
    primary: 'color_14',
    direction: direction
  },
  {
    /* Demo Theme 4 */
    typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    navheaderBg: 'color_10',
    headerBg: 'color_1',
    sidebarStyle: 'icon-hover',
    sidebarBg: 'color_10',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'wide',
    primary: 'color_10',
    direction: direction
  },
  {
    /* Demo Theme 5 */
    typography: 'poppins',
    version: 'light',
    layout: 'horizontal',
    navheaderBg: 'color_1',
    headerBg: 'color_1',
    sidebarStyle: 'full',
    sidebarBg: 'color_1',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'wide',
    primary: 'color_4',
    direction: direction
  },
  {
    /* Demo Theme 6 */
    typography: 'poppins',
    version: 'light',
    layout: 'vertical',
    navheaderBg: 'color_5',
    headerBg: 'color_1',
    sidebarStyle: 'full',
    sidebarBg: 'color_5',
    sidebarPosition: 'fixed',
    headerPosition: 'fixed',
    containerLayout: 'wide',
    primary: 'color_5',
    direction: direction
  }
];
