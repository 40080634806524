import { useNavigate } from 'react-router-dom';
import { decodeTokenRole } from '../../../services/AuthService';
import { useEffect } from 'react';

const ReRoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const adminToken = JSON.parse(localStorage.getItem('userDetails'))?.session
      ?.accessToken;
    const superAdminToken = JSON.parse(localStorage.getItem('superAdminUser'))
      ?.session?.accessToken;

    if (superAdminToken) {
      const isAuthenticated = decodeTokenRole(superAdminToken) === 'SUPERADMIN';

      if (isAuthenticated) {
        navigate('/super-admin');
        return;
      }
    }

    if (adminToken) {
      const isAuthenticated = decodeTokenRole(adminToken) === 'ADMIN';

      if (isAuthenticated) {
        navigate('/dashboard');
        return;
      }
    }
  });

  return null;
};

export default ReRoute;
