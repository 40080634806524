import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';

const colors = [
  { name: 'Red', hex: '#EF4444' },
  { name: 'Orange', hex: '#F97316' },
  { name: 'Yellow', hex: '#EAB308' },
  { name: 'Lime', hex: '#84CC16' },
  { name: 'Green', hex: '#22C55E' },
  { name: 'Emerald', hex: '#10B981' },
  { name: 'Teal', hex: '#14B8A6' },
  { name: 'Sky', hex: '#0EA5E9' },
  { name: 'Blue', hex: '#3B82F6' },
  { name: 'Indigo', hex: '#6366F1' },
  { name: 'Violet', hex: '#8B5CF6' },
  { name: 'Purple', hex: '#A855F7' },
  { name: 'Fuchsia', hex: '#D946EF' },
  { name: 'Pink', hex: '#EC4899' },
  { name: 'Stone', hex: '#78716C' },
  { name: 'Gray', hex: '#6B7280' }
];

const ThemeCustomization = () => {
  const [selectedColor, setSelectedColor] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchThemeColor = async () => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const orgId = userDetails?.user?.organizationId;
    const accessToken = userDetails?.session.accessToken || null;

    if (!accessToken || !orgId) {
      console.error('Access token or organization ID not found');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch the theme color');
      }

      const data = await response.json();
      const currentColor = colors.find(
        (color) => color.name === data.data.organization.themeColor
      );
      setSelectedColor(currentColor);
    } catch (error) {
      console.error(error.message);
    }
  };

  const updateThemeColor = async (color) => {
    setLoading(true);
    const previousColor = selectedColor;
    setSelectedColor(color);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const orgId = userDetails?.user?.organizationId;
    const accessToken = userDetails?.session.accessToken || null;

    if (!accessToken || !orgId) {
      console.error('Access token or organization ID not found');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify({ themeColor: color.name })
        }
      );
      if (!response.ok) {
        throw new Error('Failed to update the theme color');
      }

      const { message } = await response.json();
      toast.success(message);
    } catch (error) {
      console.error(error.message);
      setSelectedColor(previousColor); // Revert to previous color if API call fails
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchThemeColor();
  }, []);

  return (
    <div className="theme-customization">
      <div className="d-flex align-items-center mb-4">
        <h3 className="mb-0 me-auto">Theme Customization</h3>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-header-title">
                Choose a theme to personalize the appearance of the Candidate
                Panel. Simply click on any color to apply it.
              </h5>
            </div>
            <div className="card-body">
              <div className="d-flex flex-wrap gap-3">
                {colors.map((color, index) => (
                  <div key={index} className="position-relative">
                    <input
                      type="radio"
                      name="primary_color"
                      id={`primary_${index}`}
                      checked={selectedColor?.name === color.name}
                      onChange={() => updateThemeColor(color)}
                      className="d-none"
                      disabled={loading && selectedColor?.name !== color.name}
                    />
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{color.name}</Tooltip>}
                    >
                      <label
                        htmlFor={`primary_${index}`}
                        className="rounded position-relative"
                        style={{
                          backgroundColor: color.hex,
                          borderRadius: '8px',
                          width: '50px',
                          height: '50px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          boxShadow:
                            selectedColor?.name === color.name
                              ? `0 0 0 2px ${color.hex}` // Simulates the border inside
                              : '0 0 0 1px #ccc', // Light border
                          cursor: loading
                            ? selectedColor?.name === color.name
                              ? 'default'
                              : 'not-allowed'
                            : 'pointer',
                          boxSizing: 'border-box' // Ensure size includes border
                        }}
                      >
                        {loading && selectedColor?.name === color.name ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            style={{
                              color: 'white',
                              width: '1.5rem',
                              height: '1.5rem',
                              borderWidth: '0.2rem'
                            }}
                          />
                        ) : selectedColor?.name === color.name ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="white"
                            viewBox="0 0 16 16"
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)'
                            }}
                          >
                            <path
                              d="M13.485 1.929a.75.75 0 010 1.06l-7.878 7.879a.75.75 0 01-1.06 0L2.516 8.818a.75.75 0 111.06-1.06l1.88 1.88 7.516-7.517a.75.75 0 011.06 0z"
                              style={{ fontWeight: 'bold' }}
                            />
                          </svg>
                        ) : null}
                      </label>
                    </OverlayTrigger>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeCustomization;
