import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { IoSearch } from 'react-icons/io5';
import { MdOutlineWifiTetheringErrorRounded } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import {
  HiOutlineEye,
  HiOutlineSearch,
  HiOutlineExclamation,
  HiOutlineChevronLeft,
  HiOutlineChevronRight
} from 'react-icons/hi';

const JobApplicationBlog = ({
  applicationData,
  totalEntries,
  totalPages,
  currentPage,
  onPageChange,
  loading = 1,
  itemsPerPage
}) => {
  const getStatusColor = (status) => {
    switch (status) {
      case 'REJECTED':
        return 'danger';
      case 'PROFILE_ANALYZED':
        return 'warning';
      case 'SELECTED':
        return 'success';
      default:
        return 'primary';
    }
  };

  const transformStatus = (status) => {
    // Remove curly braces and split by spaces
    const cleanedStatus = status.replace(/[{}]/g, '').split(' ');

    // Replace underscores with spaces and capitalize words
    const transformedStatus = cleanedStatus
      .map((word) =>
        word
          .replace(/_/g, ' ')
          .toLowerCase()
          .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())
      )
      .join('\n');

    return transformedStatus;
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const getPaginationItems = () => {
    let items = [];
    const maxVisiblePages = 3;
    const effectiveTotalPages = Math.max(1, totalPages);

    if (effectiveTotalPages <= maxVisiblePages) {
      for (let i = 1; i <= effectiveTotalPages; i++) {
        items.push(i);
      }
    } else {
      if (currentPage <= 2) {
        items = [1, 2, 3];
      } else if (currentPage >= effectiveTotalPages - 1) {
        items = [
          effectiveTotalPages - 2,
          effectiveTotalPages - 1,
          effectiveTotalPages
        ];
      } else {
        items = [currentPage - 1, currentPage, currentPage + 1];
      }
    }

    return items;
  };

  const paginationItems = getPaginationItems();
  const effectiveTotalPages = Math.max(1, totalPages);
  const JOBS_PER_PAGE = 7;

  const getScoreClass = (score) => {
    if (score >= 80) {
      return 'excellent';
    }
    if (score >= 60) {
      return 'good';
    }
    if (score >= 40) {
      return 'fair';
    }
    return 'poor';
  };

  const getStatusConfig = (status) => {
    const statusMap = {
      application_submitted: {
        class: 'submitted',
        icon: '📝',
        label: 'Application Submitted'
      },
      profile_review_completed: {
        class: 'review-completed',
        icon: '✔️',
        label: 'Profile Review Completed'
      },
      ai_review_completed: {
        class: 'ai-reviewed',
        icon: '🤖',
        label: 'AI Review Completed'
      },
      assessments_in_progress: {
        class: 'assessment-progress',
        icon: '⏳',
        label: 'Assessments In Progress'
      },
      assessments_completed: {
        class: 'assessment-completed',
        icon: '📋',
        label: 'Assessments Completed'
      },
      interview_process_started: {
        class: 'interview-started',
        icon: '🗣️',
        label: 'Interview Process Started'
      },
      interviews_completed: {
        class: 'interviews-done',
        icon: '✅',
        label: 'Interviews Completed'
      },
      candidate_withdrawn: {
        class: 'withdrawn',
        icon: '⛔',
        label: 'Candidate Withdrawn'
      },
      rejected: {
        class: 'rejected',
        icon: '❌',
        label: 'Rejected'
      },
      offer_extended: {
        class: 'offer',
        icon: '📨',
        label: 'Offer Extended'
      },
      hired: {
        class: 'hired',
        icon: '🎉',
        label: 'Hired'
      }
    };

    const defaultStatus = {
      class: 'default',
      icon: 'ℹ️',
      label: status
        ? status
            .split('_')
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(' ')
        : 'N/A'
    };

    return statusMap[status?.toLowerCase()] || defaultStatus;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  return (
    <>
      <style>
        {`
        .badge-wrapper {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        /* Score Badge */
        .score-badge {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 6px 16px;
          border-radius: 8px;
          font-weight: 600;
          font-size: 15px;
          min-width: 90px;
          transition: all 0.2s ease;
          box-shadow: 0 1px 2px rgba(0,0,0,0.06);
        }

        .score-badge.excellent {
          background-color: #ECFDF5;
          color: #059669;
          border: 1px solid #A7F3D0;
        }

        .score-badge.good {
          background-color: #EFF6FF;
          color: #2563EB;
          border: 1px solid #BFDBFE;
        }

        .score-badge.fair {
          background-color: #FFFBEB;
          color: #D97706;
          border: 1px solid #FDE68A;
        }

        .score-badge.poor {
          background-color: #FEF2F2;
          color: #DC2626;
          border: 1px solid #FECACA;
        }

        /* Status Badge */
        .status-badge {
          display: inline-flex;
          align-items: center;
          gap: 8px;
          padding: 7px 14px;
          border-radius: 30px;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0.3px;
          min-width: 150px;
          transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          position: relative;
          background: rgba(255, 255, 255, 0.9);
          backdrop-filter: blur(8px);
          border: 1px solid rgba(0, 0, 0, 0.05);
        }

        .badge-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          font-size: 14px;
          position: relative;
          z-index: 1;
          transition: transform 0.3s ease;
        }

        .status-badge:hover .badge-icon {
          transform: scale(1.15);
        }

        /* Status-specific styles with gradients and shadows */
        .status-badge.submitted {
          background: linear-gradient(135deg, #EFF6FF 0%, #DBEAFE 100%);
          color: #1E40AF;
          box-shadow: 0 2px 12px rgba(30, 64, 175, 0.08);
        }

        .status-badge.review-completed {
          background: linear-gradient(135deg, #F0FDF4 0%, #DCFCE7 100%);
          color: #166534;
          box-shadow: 0 2px 12px rgba(22, 101, 52, 0.08);
        }

        .status-badge.ai-reviewed {
          background: linear-gradient(135deg, #F5F3FF 0%, #EDE9FE 100%);
          color: #5B21B6;
          box-shadow: 0 2px 12px rgba(91, 33, 182, 0.08);
        }

        .status-badge.assessment-progress {
          background: linear-gradient(135deg, #FEF3C7 0%, #FDE68A 100%);
          color: #92400E;
          box-shadow: 0 2px 12px rgba(146, 64, 14, 0.08);
        }

        .status-badge.interviews-done {
          background: linear-gradient(135deg, #E0F2FE 0%, #BAE6FD 100%);
          color: #0369A1;
          box-shadow: 0 2px 12px rgba(3, 105, 161, 0.08);
        }

        .status-badge.hired {
          background: linear-gradient(135deg, #D1FAE5 0%, #6EE7B7 100%);
          color: #065F46;
          box-shadow: 0 2px 12px rgba(6, 95, 70, 0.08);
        }

        .status-badge.rejected {
          background: linear-gradient(135deg, #FEE2E2 0%, #FECACA 100%);
          color: #991B1B;
          box-shadow: 0 2px 12px rgba(153, 27, 27, 0.08);
        }

        /* Shine effect on hover */
        .status-badge::before {
          content: '';
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            120deg,
            transparent,
            rgba(255, 255, 255, 0.3),
            transparent
          );
          transition: 0.6s;
        }

        .status-badge:hover::before {
          left: 100%;
        }

        /* Pulse animation for new status updates */
        @keyframes statusPulse {
          0% {
            box-shadow: 0 0 0 0 rgba(var(--status-color), 0.4);
          }
          70% {
            box-shadow: 0 0 0 10px rgba(var(--status-color), 0);
          }
          100% {
            box-shadow: 0 0 0 0 rgba(var(--status-color), 0);
          }
        }

        /* Subtle float animation */
        @keyframes float {
          0% { transform: translateY(0px); }
          50% { transform: translateY(-2px); }
          100% { transform: translateY(0px); }
        }

        .status-badge:hover {
          transform: translateY(-1px);
          animation: float 2s ease-in-out infinite;
        }

        /* Inner text glow effect */
        .status-badge span {
          text-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
        }

        /* Status icon styles */
        .badge-icon {
          background: rgba(255, 255, 255, 0.9);
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
          backdrop-filter: blur(4px);
        }

        /* New status indicator */
        .status-badge.new::after {
          content: '';
          position: absolute;
          top: -2px;
          right: -2px;
          width: 8px;
          height: 8px;
          background: #10B981;
          border-radius: 50%;
          border: 2px solid white;
          animation: pulse 2s infinite;
        }

        /* Hover effects */
        .badge-wrapper:hover .score-badge,
        .badge-wrapper:hover .status-badge {
          transform: translateY(-1px);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        }

        .status-badge::before {
          content: None !important;
        }

        .date-cell {
          font-size: 13px;
          color: #525252;
          font-weight: 500;
          white-space: nowrap;
          padding: 8px 16px;
          letter-spacing: 0.2px;
        }

        .status-badge.assessment-completed {
          background: linear-gradient(135deg, #065F46 0%, #047857 100%);
          color: #ffffff;
          box-shadow: 0 2px 12px rgba(6, 95, 70, 0.15);
        }

        /* Update the icon style for dark background */
        .status-badge.assessment-completed .badge-icon {
          background: rgba(255, 255, 255, 0.9);
          color: #065F46;
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        }

        /* Optional: Add a subtle border for better contrast */
        .status-badge.assessment-completed {
          border: 1px solid rgba(255, 255, 255, 0.1);
        }

        /* Optional: Adjust hover effect for dark background */
        .status-badge.assessment-completed:hover {
          box-shadow: 0 4px 15px rgba(6, 95, 70, 0.2);
        }

        /* Optional: Adjust shine effect for dark background */
        .status-badge.assessment-completed::before {
          background: linear-gradient(
            120deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
          );
        }

        /* Isolated Action Button Styles */
        .application-view-btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 0.5rem;
          border-radius: 8px;
          transition: all 0.2s ease;
          border: none;
          background: #F8FAFC;
          color: #64748B;
          text-decoration: none;
        }

        .application-view-btn:hover {
          transform: translateY(-2px);
          background: #D1FAE5;
          color: #059669;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        }
      `}
      </style>

      <div
        style={{ position: 'relative', height: 'calc(100vh - 350px)' }}
        className="mb-4 overflow-auto"
      >
        <table
          id="job-table"
          className="table table-fixed display dataTablesCard mb-4 job-table table-responsive-xl card-table dataTable no-footer"
        >
          <thead>
            <tr role="user-select-none ">
              <th style={{ width: '5%' }}>No</th>
              <th style={{ width: '15%' }}>Name</th>
              <th style={{ width: '10%' }}>Phone No</th>
              <th style={{ width: '2rem' }}>Email</th>
              <th style={{ width: '17rem' }}>Job Title</th>
              <th style={{ width: '10%' }}>Applied Date</th>
              <th style={{ width: '8%' }}>Score</th>
              <th style={{ width: '12%' }}>Status</th>
              <th style={{ width: '15%' }} className="text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {loading === 1 && (
              <tr>
                <td colSpan="9" className="text-center py-5">
                  <div className="d-flex flex-column align-items-center">
                    <Spinner
                      animation="border"
                      variant="primary"
                      style={{ width: '3rem', height: '3rem' }}
                      className="mb-3"
                    />
                    <p className="text-muted fs-5 fw-medium">
                      Loading applications...
                    </p>
                  </div>
                </td>
              </tr>
            )}
            {loading === 2 && (
              <tr>
                <td colSpan="9" className="text-center py-5">
                  <div className="d-flex flex-column align-items-center">
                    <HiOutlineExclamation
                      size={80}
                      className="mb-3"
                      style={{ color: '#878787B7' }}
                    />
                    <h4 className="text-muted">Error fetching applications</h4>
                    <p className="text-muted">
                      Check your network and try again
                    </p>
                  </div>
                </td>
              </tr>
            )}
            {loading === 0 && applicationData.length === 0 && (
              <tr>
                <td colSpan="9" className="text-center py-5">
                  <div className="d-flex flex-column align-items-center">
                    <HiOutlineSearch
                      size={80}
                      className="mb-3"
                      style={{ color: '#878787B7' }}
                    />
                    <h4 className="text-muted">No applications found</h4>
                    <p className="text-muted">
                      No applications have been submitted for this job yet
                    </p>
                  </div>
                </td>
              </tr>
            )}
            {loading === 0 && applicationData.length > 0 && (
              <>
                {applicationData.map((application, index) => (
                  <tr
                    key={application._id}
                    role="row"
                    className={index % 2 === 0 ? 'odd' : 'even'}
                  >
                    <td style={{ maxWidth: '2rem' }}>
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </td>
                    <td style={{ maxWidth: '10rem' }} className="text-truncate">
                      {application.userDetails?.name || 'N/A'}
                    </td>
                    <td style={{ maxWidth: '5rem' }} className="text-truncate">
                      {application.userDetails?.phone || 'N/A'}
                    </td>
                    <td style={{ maxWidth: '13rem' }} className="text-truncate">
                      {application.userDetails?.email}
                    </td>
                    <td style={{ maxWidth: '12rem' }} className="text-truncate">
                      {application.jobDetails?.title || 'N/A'}
                    </td>
                    <td className="date-cell">
                      {formatDate(application.applicationDate)}
                    </td>
                    <td>
                      <div className="badge-wrapper">
                        <span
                          className={`score-badge ${getScoreClass(
                            Number(
                              application.finalCandidateAnalysis
                                ?.profileMatchPercentage ||
                                application.candidateAnalysis
                                  ?.profileMatchPercentage ||
                                0
                            )
                          )}`}
                        >
                          {application.finalCandidateAnalysis
                            ?.profileMatchPercentage ||
                            application.candidateAnalysis
                              ?.profileMatchPercentage ||
                            'N/A'}
                          {application.finalCandidateAnalysis
                            ?.profileMatchPercentage ||
                          application.candidateAnalysis?.profileMatchPercentage
                            ? '%'
                            : ''}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="badge-wrapper">
                        {(() => {
                          const status = getStatusConfig(application.status);
                          return (
                            <span className={`status-badge ${status.class}`}>
                              <span className="badge-icon">{status.icon}</span>
                              <span>{status.label}</span>
                            </span>
                          );
                        })()}
                      </div>
                    </td>
                    <td style={{ width: '4rem' }} className="text-center">
                      <Link
                        to={`/job-application-view/${application.job}/${application._id}`}
                        className="application-view-btn"
                      >
                        <HiOutlineEye size={18} />
                      </Link>
                    </td>
                  </tr>
                ))}

                {/* Add empty filler rows */}
                {[
                  ...Array(Math.max(0, itemsPerPage - applicationData.length))
                ].map((_, i) => (
                  <tr key={`filler-${i}`} style={{ height: '4.5rem' }}>
                    <td colSpan="9"></td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>

      {loading === 0 && applicationData.length > 0 && (
        <div className="d-md-flex d-block align-items-center justify-content-between text-center  flex-wrap mt-md-0 mt-3">
          <div className="mb-md-0 mb-3">
            <p className="dataTables_info">
              {`Showing ${
                totalEntries !== 0 ? itemsPerPage * (currentPage - 1) + 1 : 0
              } to ${Math.min(
                itemsPerPage * currentPage,
                totalEntries
              )} of ${totalEntries} entries`}
            </p>
          </div>
          <nav>
            <ul className="pagination pagination-circle justify-content-center">
              <li className="page-item page-indicator">
                <button
                  onClick={handlePreviousPageClick}
                  disabled={currentPage === 1}
                  className="page-link"
                  style={{
                    backgroundColor: currentPage === 1 ? '#ECECEC' : '',
                    cursor: currentPage === 1 && 'not-allowed'
                  }}
                >
                  <HiOutlineChevronLeft
                    style={{ color: currentPage === 1 ? '#616161' : '' }}
                  />
                </button>
              </li>
              {paginationItems[0] > 1 && (
                <li className="page-item d-flex align-items-end">
                  <label className="fs-4">...</label>
                </li>
              )}
              {paginationItems.map((item) => (
                <li
                  key={item}
                  className={`page-item ${
                    item === currentPage ? 'active' : ''
                  }`}
                  onClick={() => onPageChange(item)}
                >
                  <Button className="page-link">{item}</Button>
                </li>
              ))}
              {paginationItems[paginationItems.length - 1] <
                effectiveTotalPages && (
                <li className="page-item d-flex align-items-end">
                  <label className="fs-4">...</label>
                </li>
              )}
              <li className="page-item page-indicator">
                <button
                  onClick={handleNextPageClick}
                  disabled={currentPage >= effectiveTotalPages}
                  className="page-link"
                  style={{
                    backgroundColor:
                      currentPage >= effectiveTotalPages ? '#ECECEC' : ''
                  }}
                >
                  <HiOutlineChevronRight
                    style={{
                      color:
                        currentPage >= effectiveTotalPages ? '#616161' : '',
                      cursor:
                        currentPage >= effectiveTotalPages && 'not-allowed'
                    }}
                  />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};

export default JobApplicationBlog;
