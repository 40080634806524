import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import logo from '../../images/logo.png';

const PrivacyPolicy = () => {
  useEffect(() => {
    // Add fade-in effect on mount
    const sections = document.querySelectorAll('.privacy-section');
    sections.forEach((section, index) => {
      section.style.animation = `fadeIn 0.5s ease forwards ${index * 0.1}s`;
    });
  }, []);

  return (
    <div className="talentswap-privacy-policy">
      <div className="container-fluid privacy-policy-wrapper">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="card privacy-card">
              <div className="card-header">
                <div className="header-content">
                  <div className="logo-section">
                    <img
                      src={logo}
                      alt="Talentswap Logo"
                      className="company-logo"
                    />
                  </div>
                  <div className="header-text">
                    <h2 className="card-title">Privacy Policy</h2>
                    <p className="header-subtitle">
                      Your Privacy Matters to Us
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <PerfectScrollbar className="privacy-policy">
                  <div className="privacy-section">
                    <div className="last-updated mb-4">
                      <h5>Last Updated: {new Date().toLocaleDateString()}</h5>
                      <p className="intro-text">
                        At Talentswap, we value your trust and are committed to
                        protecting your personal data. This Privacy Policy
                        outlines our practices for collecting, using, and
                        sharing your data, as well as your rights under GDPR and
                        other applicable laws. By using our platform, you
                        consent to the practices described herein.
                      </p>
                    </div>
                  </div>

                  {/* Section 1: Data Collection */}
                  <section className="mb-5">
                    <h5 className="section-title">1. Information We Collect</h5>

                    <div className="subsection mb-4">
                      <h6>1.1 Personal Data</h6>
                      <p>
                        We collect personal data that you voluntarily provide to
                        us when registering, creating a profile, or using our
                        services, including:
                      </p>
                      <ul>
                        <li>
                          <span className="point-title">
                            Name, email address, phone number, and other contact
                            details
                          </span>
                          <p className="point-description">
                            This information is essential for creating and
                            maintaining your account, enabling communication
                            about important updates, and ensuring secure access
                            to our platform.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Employment history, skills, and qualifications
                          </span>
                          <p className="point-description">
                            We collect your professional background to match you
                            with relevant opportunities, validate your
                            expertise, and provide personalized career
                            development recommendations.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Profile images and other user-generated content
                          </span>
                          <p className="point-description">
                            Your profile photo and content help build trust with
                            potential employers and showcase your professional
                            identity. All media is stored securely with
                            industry-standard encryption.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Payment details for subscriptions or services
                          </span>
                          <p className="point-description">
                            For premium features, we securely process payment
                            information through trusted payment processors. We
                            never store complete credit card details on our
                            servers.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Preferences and settings
                          </span>
                          <p className="point-description">
                            Your customized preferences help us tailor the
                            platform experience, including job alerts,
                            notification settings, and privacy controls.
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className="subsection mb-4">
                      <h6>1.2 Usage Data</h6>
                      <p>
                        We automatically collect information about how you
                        interact with our platform, including:
                      </p>
                      <ul>
                        <li>
                          <span className="point-title">
                            IP address and device information
                          </span>
                          <p className="point-description">
                            We collect this data to ensure platform security,
                            prevent fraud, and optimize our services for your
                            device. This helps us protect your account and
                            provide a seamless experience across different
                            devices.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Browser type and operating system
                          </span>
                          <p className="point-description">
                            This information helps us optimize our platform's
                            performance and compatibility with your specific
                            setup. It enables us to troubleshoot technical
                            issues and ensure all features work correctly on
                            your system.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Pages viewed and actions taken on the platform
                          </span>
                          <p className="point-description">
                            We analyze this data to improve user experience,
                            identify popular features, and understand how users
                            navigate our platform. This helps us make informed
                            decisions about feature improvements and platform
                            optimization.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Log files and error reports
                          </span>
                          <p className="point-description">
                            These technical logs help us maintain platform
                            stability, diagnose issues quickly, and ensure
                            optimal performance. Premium users receive priority
                            attention for error resolution and performance
                            optimization.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Session duration and interaction patterns
                          </span>
                          <p className="point-description">
                            Understanding how long users spend on different
                            features helps us optimize the platform's efficiency
                            and identify areas for improvement. Premium users
                            receive enhanced analytics about their platform
                            usage patterns.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Feature usage statistics
                          </span>
                          <p className="point-description">
                            We track which features are most valuable to our
                            users to guide our development priorities and ensure
                            we're providing the tools you need most. Premium
                            users get access to advanced features and priority
                            for new feature releases.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </section>

                  {/* Section 2: Data Use */}
                  <section className="mb-5">
                    <h5 className="section-title">2. How We Use Your Data</h5>

                    <div className="subsection mb-4">
                      <h6>2.1 Service Provision</h6>
                      <p>
                        We use your data to provide and improve our services in
                        the following ways:
                      </p>
                      <ul>
                        <li>
                          <span className="point-title">
                            Personalized Job Matching and Recommendations
                          </span>
                          <p className="point-description">
                            We analyze your profile, skills, and preferences to
                            provide highly relevant job matches and career
                            opportunities. Premium users receive priority
                            matching and advanced filtering options for better
                            job prospects.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Platform Performance and User Experience
                          </span>
                          <p className="point-description">
                            Your usage patterns help us optimize platform
                            performance, improve user interface, and develop new
                            features. Premium members get early access to new
                            features and can provide direct feedback for
                            platform improvements.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Communication and Updates
                          </span>
                          <p className="point-description">
                            We send important notifications about job matches,
                            profile views, and platform updates. Premium users
                            receive priority notifications and can customize
                            their communication preferences in detail.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Analytics and Insights
                          </span>
                          <p className="point-description">
                            We generate insights about industry trends, skill
                            demands, and career opportunities. Premium members
                            get access to advanced analytics, market insights,
                            and personalized career development recommendations.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">Account Security</span>
                          <p className="point-description">
                            Your data helps us maintain platform security,
                            prevent unauthorized access, and protect against
                            fraud. Premium accounts receive additional security
                            features and real-time threat monitoring.
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className="subsection mb-4">
                      <h6>2.2 Legal Compliance</h6>
                      <p>
                        We process your data to comply with applicable laws and
                        regulations, including:
                      </p>
                      <ul>
                        <li>
                          <span className="point-title">
                            GDPR and Data Protection Laws
                          </span>
                          <p className="point-description">
                            We maintain strict compliance with GDPR and other
                            international data protection regulations, ensuring
                            your data rights are protected. This includes data
                            portability, right to erasure, and transparent
                            processing.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Legal Requests and Obligations
                          </span>
                          <p className="point-description">
                            We may process data to respond to legal requests
                            from authorities or to comply with applicable laws.
                            This is done with strict oversight and only when
                            legally required.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Terms of Service Enforcement
                          </span>
                          <p className="point-description">
                            We use data to ensure compliance with our terms of
                            service and maintain platform integrity. This
                            includes preventing misuse, protecting intellectual
                            property, and maintaining service quality.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Dispute Resolution
                          </span>
                          <p className="point-description">
                            In case of disputes or claims, we may use relevant
                            data to resolve issues and protect our users'
                            interests. Premium users receive priority support
                            for dispute resolution.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Audit and Compliance Records
                          </span>
                          <p className="point-description">
                            We maintain necessary records for auditing and
                            compliance purposes, ensuring transparency and
                            accountability in our data processing activities.
                            All records are secured with enterprise-grade
                            encryption.
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className="subsection mb-4 premium-feature">
                      <h6>2.3 Premium Service Enhancements</h6>
                      <p>
                        Premium users benefit from enhanced data processing
                        features:
                      </p>
                      <ul>
                        <li>
                          <span className="point-title">
                            Advanced Analytics Dashboard
                          </span>
                          <p className="point-description">
                            Access detailed insights about your profile
                            performance, application statistics, and market
                            positioning. Includes AI-powered recommendations for
                            profile optimization.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Priority Data Processing
                          </span>
                          <p className="point-description">
                            Enjoy faster response times, priority updates, and
                            enhanced processing power for all platform features.
                            Your requests are handled by dedicated
                            high-performance servers.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Custom Privacy Controls
                          </span>
                          <p className="point-description">
                            Get granular control over your data visibility,
                            advanced privacy settings, and the ability to manage
                            your digital footprint with precision.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Enhanced Security Features
                          </span>
                          <p className="point-description">
                            Benefit from additional security layers, including
                            advanced encryption, multi-factor authentication
                            options, and real-time security monitoring.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </section>

                  {/* Section 3: Data Sharing */}
                  <section className="mb-5">
                    <h5 className="section-title">
                      3. Data Sharing and Disclosure
                    </h5>

                    <div className="subsection mb-4">
                      <h6>3.1 Third-Party Service Providers</h6>
                      <p>
                        We share data with trusted third-party service providers
                        to operate our platform effectively:
                      </p>
                      <ul>
                        <li>
                          <span className="point-title">
                            Cloud Infrastructure and Hosting Providers
                          </span>
                          <p className="point-description">
                            We use industry-leading cloud services to host our
                            platform securely and reliably. All data is
                            encrypted during transfer and storage, with premium
                            users receiving enhanced backup and recovery
                            options.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Payment Processing Services
                          </span>
                          <p className="point-description">
                            We partner with certified payment processors who
                            maintain PCI DSS compliance for secure transactions.
                            Premium users get access to additional payment
                            methods and enhanced transaction monitoring.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Analytics and Performance Monitoring
                          </span>
                          <p className="point-description">
                            We use analytics tools to improve our services and
                            user experience. Premium users receive detailed
                            insights about their profile performance and
                            engagement metrics.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Email and Communication Services
                          </span>
                          <p className="point-description">
                            We utilize professional communication services to
                            deliver notifications and updates. Premium users
                            enjoy priority message delivery and advanced
                            communication preferences.
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className="subsection mb-4">
                      <h6>3.2 Legal and Business Transfers</h6>
                      <p>Your data may be shared in specific circumstances:</p>
                      <ul>
                        <li>
                          <span className="point-title">
                            Legal Requirements and Law Enforcement
                          </span>
                          <p className="point-description">
                            We may share data when required by law, court order,
                            or governmental regulation. Premium users receive
                            immediate notification of any legal requests
                            regarding their data.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Business Transitions
                          </span>
                          <p className="point-description">
                            In case of merger, acquisition, or asset sale, your
                            data rights will be prioritized and protected.
                            Premium users receive advance notice and additional
                            data protection options during transitions.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Protection of Rights
                          </span>
                          <p className="point-description">
                            We may share data to protect our rights, privacy,
                            safety, or property. This includes investigating and
                            preventing fraud or technical issues affecting our
                            platform.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </section>

                  {/* Section 4: User Rights */}
                  <section className="mb-5">
                    <h5 className="section-title">4. Your Rights Under GDPR</h5>

                    <div className="subsection mb-4">
                      <h6>4.1 Access and Portability Rights</h6>
                      <p>
                        You have comprehensive rights regarding your personal
                        data:
                      </p>
                      <ul>
                        <li>
                          <span className="point-title">Right to Access</span>
                          <p className="point-description">
                            Request a copy of all personal data we hold about
                            you. Premium users receive priority processing and
                            enhanced data export options in multiple formats.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">Data Portability</span>
                          <p className="point-description">
                            Receive your data in a structured, commonly used
                            format for transfer to other services. Premium users
                            get advanced export tools and automated transfer
                            options.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">Data Transparency</span>
                          <p className="point-description">
                            View detailed information about how your data is
                            processed. Premium users access real-time data
                            processing logs and usage analytics.
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className="subsection mb-4">
                      <h6>4.2 Control and Modification Rights</h6>
                      <p>You have control over your personal data:</p>
                      <ul>
                        <li>
                          <span className="point-title">
                            Right to Rectification
                          </span>
                          <p className="point-description">
                            Update or correct any inaccurate personal data.
                            Premium users can use advanced profile editing tools
                            and receive assisted data verification.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">Right to Erasure</span>
                          <p className="point-description">
                            Request deletion of your personal data ("right to be
                            forgotten"). Premium users get secure data shredding
                            and verification of deletion across all systems. To
                            delete all organization data, please contact us at
                            no-reply@talentswap.ai.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Processing Restrictions
                          </span>
                          <p className="point-description">
                            Limit how we process your data. Premium users have
                            granular control over data processing preferences
                            and automated processing restrictions.
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className="subsection mb-4 premium-feature">
                      <h6>4.3 Premium Privacy Controls</h6>
                      <p>Premium users enjoy enhanced privacy features:</p>
                      <ul>
                        <li>
                          <span className="point-title">
                            Advanced Privacy Dashboard
                          </span>
                          <p className="point-description">
                            Access a comprehensive dashboard to manage all
                            privacy settings, data processing preferences, and
                            visibility controls in one place.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Priority Rights Management
                          </span>
                          <p className="point-description">
                            Enjoy expedited processing of privacy requests and
                            dedicated support for managing your data rights and
                            preferences.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Custom Privacy Alerts
                          </span>
                          <p className="point-description">
                            Receive real-time notifications about
                            privacy-related activities and potential data access
                            requests affecting your account.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </section>

                  {/* Section 5: Data Security */}
                  <section className="mb-5">
                    <h5 className="section-title">5. Data Security</h5>

                    <div className="subsection mb-4">
                      <h6>5.1 Security Measures</h6>
                      <p>
                        We implement comprehensive security measures to protect
                        your data:
                      </p>
                      <ul>
                        <li>
                          <span className="point-title">
                            Encryption and Data Protection
                          </span>
                          <p className="point-description">
                            We use industry-standard encryption protocols
                            (AES-256) for data at rest and in transit. Premium
                            users benefit from additional encryption layers and
                            enhanced security protocols.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Access Controls and Authentication
                          </span>
                          <p className="point-description">
                            Multi-factor authentication and role-based access
                            controls protect your account. Premium users get
                            advanced authentication options and detailed access
                            logs.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Regular Security Audits
                          </span>
                          <p className="point-description">
                            We conduct regular security assessments and
                            penetration testing. Premium accounts receive
                            priority security updates and detailed security
                            reports.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Infrastructure Security
                          </span>
                          <p className="point-description">
                            Our infrastructure is hosted in secure, certified
                            data centers with 24/7 monitoring. Premium users'
                            data gets additional backup frequency and enhanced
                            disaster recovery options.
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className="subsection mb-4">
                      <h6>5.2 Breach Notification</h6>
                      <p>
                        Our commitment to security includes incident response:
                      </p>
                      <ul>
                        <li>
                          <span className="point-title">
                            Incident Detection and Response
                          </span>
                          <p className="point-description">
                            We maintain advanced threat detection systems and
                            incident response procedures. Premium users receive
                            real-time security alerts and priority incident
                            handling.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Notification Timeline
                          </span>
                          <p className="point-description">
                            We will notify affected users within 72 hours of
                            discovering a breach, as required by GDPR. Premium
                            users receive immediate notifications and detailed
                            impact assessments.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Mitigation and Recovery
                          </span>
                          <p className="point-description">
                            Our team implements immediate measures to contain
                            and resolve security incidents. Premium accounts
                            receive dedicated support during recovery
                            procedures.
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className="subsection mb-4 premium-feature">
                      <h6>5.3 Premium Security Features</h6>
                      <p>Premium users enjoy additional security benefits:</p>
                      <ul>
                        <li>
                          <span className="point-title">
                            Advanced Security Dashboard
                          </span>
                          <p className="point-description">
                            Monitor your account security in real-time, view
                            access logs, and manage security settings through a
                            comprehensive security dashboard.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Dedicated Security Support
                          </span>
                          <p className="point-description">
                            Access our security experts for personalized
                            assistance and receive priority handling for
                            security-related inquiries.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">
                            Enhanced Protection Features
                          </span>
                          <p className="point-description">
                            Benefit from additional security features like IP
                            whitelisting, advanced session management, and
                            customizable security policies.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </section>

                  {/* Section 6: Contact Information */}
                  {/* <section className="mb-5">
                    <h5 className="section-title">6. Contact Us</h5>

                    <div className="subsection mb-4">
                      <h6>6.1 General Inquiries</h6>
                      <p>For questions about this Privacy Policy or your data:</p>
                      <ul>
                        <li>
                          <span className="point-title">Privacy Team Contact</span>
                          <p className="point-description">
                            Email: privacy@talentswap.com<br />
                            Response Time: Within 24 hours (Premium users receive priority response within 4 hours)
                          </p>
                        </li>
                        <li>
                          <span className="point-title">Customer Support</span>
                          <p className="point-description">
                            Phone: +1 (555) 123-4567<br />
                            Hours: 24/7 support for Premium users, Monday-Friday 9AM-6PM EST for standard users
                          </p>
                        </li>
                        <li>
                          <span className="point-title">Mailing Address</span>
                          <p className="point-description">
                            Talentswap Inc.<br />
                            123 Innovation Drive<br />
                            Tech City, TC 12345<br />
                            United States
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className="subsection mb-4">
                      <h6>6.2 Data Protection Officer</h6>
                      <p>For specific data protection concerns:</p>
                      <ul>
                        <li>
                          <span className="point-title">DPO Contact Information</span>
                          <p className="point-description">
                            Email: dpo@talentswap.com<br />
                            Direct Line: +1 (555) 123-4568 (Premium users get priority access)<br />
                            Office Hours: Monday-Friday 9AM-6PM EST
                          </p>
                        </li>
                        <li>
                          <span className="point-title">Regulatory Inquiries</span>
                          <p className="point-description">
                            For GDPR-related requests or regulatory concerns, our DPO ensures prompt handling and compliance 
                            with all applicable regulations. Premium users receive expedited processing of regulatory requests.
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className="subsection mb-4 premium-feature">
                      <h6>6.3 Premium Support Channels</h6>
                      <p>Premium users have access to additional support options:</p>
                      <ul>
                        <li>
                          <span className="point-title">Dedicated Support Line</span>
                          <p className="point-description">
                            Direct access to senior privacy specialists through our premium support hotline, available 24/7.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">Priority Email Channel</span>
                          <p className="point-description">
                            premium-privacy@talentswap.com - Guaranteed response within 4 hours for all privacy-related inquiries.
                          </p>
                        </li>
                        <li>
                          <span className="point-title">Video Consultation</span>
                          <p className="point-description">
                            Schedule one-on-one video calls with our privacy team for complex inquiries or detailed discussions.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </section> */}
                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        /* Scope all styles under the unique privacy policy class */
        .talentswap-privacy-policy .privacy-policy-wrapper {
          background: linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%);
          padding: 2rem 1rem;
          min-height: calc(100vh - 100px);
          width: 100%;
        }

        .talentswap-privacy-policy .privacy-card {
          border: none;
          border-radius: 20px;
          box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          background: white;
          margin-bottom: 2rem;
        }

        .talentswap-privacy-policy .card-header {
          background: linear-gradient(135deg, #2c3e50 0%, #3498db 100%);
          padding: 1rem 0;
          border: none;
          position: relative;
          overflow: hidden;
        }

        .talentswap-privacy-policy .header-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          z-index: 1;
          height: 80px;
          padding: 0 2.5rem;
        }

        .talentswap-privacy-policy .logo-section {
          height: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 0px !important;
        }

        .talentswap-privacy-policy .company-logo {
          height: 4rem;
          width: auto;
          filter: brightness(0) invert(1);
          display: block;
          object-fit: contain;
        }

        .talentswap-privacy-policy .header-text {
          text-align: right;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .talentswap-privacy-policy .card-title {
          color: white;
          margin: 0;
          font-size: 1.8rem;
          font-weight: 600;
          letter-spacing: -0.5px;
          line-height: 1.2;
        }

        .talentswap-privacy-policy .header-subtitle {
          color: rgba(255, 255, 255, 0.9);
          margin: 0.25rem 0 0;
          font-size: 0.9rem;
          font-weight: 400;
        }

        .talentswap-privacy-policy .privacy-policy {
          padding: 2rem;
          max-height: 75vh;
          overflow-y: auto;
        }

        .talentswap-privacy-policy .section-title {
          color: #2c3e50;
          margin: 2rem 0 1.5rem;
          font-size: 1.5rem;
          font-weight: 600;
          padding-bottom: 0.75rem;
          border-bottom: 2px solid #3498db;
        }

        .talentswap-privacy-policy .subsection {
          background: #f8fafc;
          border-radius: 12px;
          padding: 1.75rem;
          margin-bottom: 1.5rem;
        }

        .talentswap-privacy-policy .subsection h6 {
          color: #2c3e50;
          margin-bottom: 1rem;
          font-size: 1.1rem;
          font-weight: 600;
        }

        .talentswap-privacy-policy .point-title {
          font-weight: 600;
          color: #2c3e50;
          display: block;
          margin-bottom: 0.5rem;
          font-size: 0.95rem;
        }

        .talentswap-privacy-policy .point-description {
          color: #64748b;
          font-size: 0.9rem;
          line-height: 1.6;
          margin-bottom: 0.75rem;
        }

        .talentswap-privacy-policy .premium-feature {
          background: linear-gradient(145deg, #f6f8fd 0%, #e9f2fe 100%);
          border-left: 4px solid #3498db;
          position: relative;
        }

        .talentswap-privacy-policy .premium-feature::before {
          content: 'PREMIUM';
          position: absolute;
          top: 1rem;
          right: 1rem;
          background: #3498db;
          color: white;
          padding: 0.2rem 0.6rem;
          border-radius: 20px;
          font-size: 0.7rem;
          font-weight: 600;
        }

        /* Responsive Design */
        @media (max-width: 1200px) {
          .talentswap-privacy-policy .privacy-policy {
            max-height: 70vh;
          }
        }

        @media (max-width: 992px) {
          .talentswap-privacy-policy .card-title {
            font-size: 1.6rem;
          }
        }

        @media (max-width: 768px) {
          .talentswap-privacy-policy .header-content {
            height: 70px;
            padding: 0 1.5rem;
          }

          .talentswap-privacy-policy .company-logo {
            height: 35px;
          }

          .talentswap-privacy-policy .card-title {
            font-size: 1.4rem;
          }

          .talentswap-privacy-policy .privacy-policy {
            padding: 1.5rem;
          }

          .talentswap-privacy-policy .section-title {
            font-size: 1.3rem;
          }

          .talentswap-privacy-policy .subsection {
            padding: 1.25rem;
          }
        }

        @media (max-width: 576px) {
          .talentswap-privacy-policy .privacy-policy-wrapper {
            padding: 1rem 0.5rem;
          }

          .talentswap-privacy-policy .header-content {
            padding: 0 1rem;
          }

          .talentswap-privacy-policy .card-title {
            font-size: 1.2rem;
          }

          .talentswap-privacy-policy .header-subtitle {
            font-size: 0.8rem;
          }

          .talentswap-privacy-policy .point-description {
            font-size: 0.85rem;
          }
        }
      `}</style>
    </div>
  );
};

export default PrivacyPolicy;
