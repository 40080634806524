import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { SlackConnect } from './slack-connect';
import { NotificationTriggerForm } from './notification-trigger-form';
import { NotificationTriggerList } from './notification-trigger-list';
import './styles.css';

export function NotificationDashboard() {
  const [triggers, setTriggers] = useState([]);

  const [slackDetails, setSlackDetails] = useState({
    channels: [],
    users: [],
    selectedChannels: [],
    integrationId: null,
    isLoaded: false
  });

  const handleAddTrigger = (trigger) => {
    setTriggers([...triggers, trigger]);
  };

  const handleRemoveTrigger = (integrationId) => {
    let updatedSlackDetails = { ...slackDetails };
    updatedSlackDetails.selectedChannels =
      updatedSlackDetails.selectedChannels.filter(
        (channel) => channel._id !== integrationId
      );
    setSlackDetails(updatedSlackDetails);
  };

  return (
    <div className="d-flex w-100 flex-column align-items-start gap-5">
      <div className="notification-header">
        <div className="notification-titles">
          <h1 className="notification-page-title">Notifications Dashboard</h1>
          <p className="mb-0">
            Configure and manage your notification preferences seamlessly.
          </p>
        </div>
      </div>

      <div className="alert custom-alert d-flex align-items-center w-100">
        <div className="d-flex align-items-center gap-4 py-2">
          <div className="alert-icon-wrapper">
            <i className="fas fa-bell fa-lg"></i>
          </div>
          <div className="alert-content">
            <p className="mb-0">
              Stay informed with real-time notifications about important updates
              and activities.
            </p>
          </div>
        </div>
      </div>

      <div className="w-100">
        <h3 className="section-heading">Integration Settings</h3>
        <SlackConnect
          slackDetails={slackDetails}
          setSlackDetails={setSlackDetails}
        />
      </div>

      <div className="d-flex flex-column w-100 gap-3">
        <div className="notification-section">
          <h3 className="section-heading">Notification Triggers</h3>
          <div className="row g-4">
            <div className="col-md-6">
              <Card className="notification-form-card h-100">
                <Card.Body>
                  <div className="dashboard-section-header">
                    <div className="header-icon">
                      <i className="fas fa-bell"></i>
                    </div>
                    <div className="header-content">
                      <h3>Create Notification Trigger</h3>
                      <span>
                        Configure when and where to send notifications
                      </span>
                    </div>
                  </div>
                  <NotificationTriggerForm
                    slackDetails={slackDetails}
                    setSlackDetails={setSlackDetails}
                    onSubmit={handleAddTrigger}
                  />
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6">
              <Card className="notification-list-card h-100">
                <Card.Body>
                  <Card.Title className="d-flex align-items-center gap-2 mb-4">
                    <i className="fas fa-bell text-primary"></i>
                    Active Triggers
                  </Card.Title>
                  <NotificationTriggerList
                    triggers={slackDetails.selectedChannels}
                    onRemove={handleRemoveTrigger}
                  />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
