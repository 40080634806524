import React from 'react';

import {
  changePassword,
  decodeTokenRole,
  forgotPassword,
  formatError,
  login,
  resetPassword,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
  fetchLogos
} from '../../services/AuthService';
import { toast } from 'react-toastify';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const GENERATE_OTP_ACTION =
  '[generate otp action] confirmed otp generated';
export const PASSWORD_RESET_ACTION =
  '[password reset action] confirmed password reset';
export const PASSWORD_RESET_FALSE =
  '[password reset false action] password reset false';

export const PASSWORD_RESET_FAILED_ACTION =
  '[pass Reset Action] failed passWORD Reset';
export const PASSWORD_CHANGE_ACTION =
  '[password change action] confirmed password change';

export const PASSWORD_CHANGE_FAILED_ACTION =
  '[pass change Action] failed passWORD change';
export const GENERATE_OTP_FAILED_ACTION =
  '[generate Otp Failed Action] failed generate Otp';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = 'LOADING_TOGGLE_ACTION';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(email, password, navigate) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(
          dispatch,
          response.data.expiresIn * 1000
          //history,
        );
        dispatch(confirmedSignupAction(response.data));
        navigate('/dashboard');
        //history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function Logout(navigate) {
  const superAdminUser = localStorage.getItem('superAdminUser');

  // Get the current path
  const path = window.location.pathname.split('/')[1];

  if (path === 'super-admin') {
    localStorage.removeItem('superAdminUser');
    localStorage.removeItem('userDetails');
    navigate('/login');
  } else {
    if (superAdminUser && superAdminUser.length > 0) {
      localStorage.removeItem('userDetails');
      window.open('/super-admin', '_self');
    } else {
      localStorage.removeItem('userDetails');
      navigate('/login');
    }
  }

  localStorage.removeItem('horizontalLogo');
  localStorage.removeItem('squareLogo');
  localStorage.removeItem('currency');

  return {
    type: LOGOUT_ACTION
  };
}

export function loginAction(email, password, navigate) {
  return async (dispatch) => {
    try {
      const response = await login(email, password);
      const role = response.data.data.user.role;

      if (role !== 'SUPERADMIN' && role !== 'ADMIN') {
        throw new Error('You are not Authorized');
      }

      saveTokenInLocalStorage(response.data.data);
      if (role !== 'SUPERADMIN') {
        await fetchLogos();
      }

      dispatch(loginConfirmedAction(response.data.data));

      toast.success('Logged in successfully');

      const token = response.data.data?.session?.accessToken;

      if (token) {
        const userRole = decodeTokenRole(token);

        if (userRole === 'SUPERADMIN') {
          navigate('/super-admin');
        } else if (userRole === 'ADMIN') {
          navigate('/dashboard');
        }
      }
    } catch (error) {
      const toastMessage =
        error.response?.data.message ||
        error.message ||
        'An error occurred, please try again.';
      toast.error(toastMessage);

      dispatch(loginFailedAction(toastMessage));
    }
  };
}

export function forgotPassAction(email, password, navigate) {
  return (dispatch) => {
    forgotPassword(email, password)
      .then((response) => {
        if (response.status === 'success') {
          toast.success('OTP sent successfully');
          dispatch(generateOtpAction(true));
        }
        dispatch(
          generateOtpFailedAction(
            response?.data?.message || 'An error occurred, please try again.'
          )
        );
      })
      .catch((error) => {
        console.error('error', error, error.message);
        toast.error(
          error.response?.data.message ||
            error.message ||
            'An error occurred, please try again.'
        );
        dispatch(
          generateOtpFailedAction(
            error.response?.data?.message ||
              error.message ||
              'An error occurred, please try again.'
          )
        );
        // const errorMessage = formatError(
        //   error?.response?.data || error.response?.data.message
        // );
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}
export function resetPassAction(email, otp, password, recaptchaValue) {
  return (dispatch) => {
    resetPassword(email, otp, password, recaptchaValue)
      .then((response) => {
        if (response.status === 'success') {
          toast.success('Password reset successfully');
          dispatch(passResetAction(true));
          dispatch(generateOtpAction(true));
          return;
        }
        dispatch(
          passResetFailedAction(
            response?.data?.message || 'An error occurred, please try again.'
          )
        );
      })
      .catch((error) => {
        console.error('Error: ', error);
        toast.error(error.toString() || 'An error occurred, please try again.');
        dispatch(
          passResetFailedAction(
            error.response?.data?.message ||
              'An error occurred, please try again.'
          )
        );
        // const errorMessage = formatError(
        //   error?.response?.data || error.response?.data.message
        // );
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function changePassAction(oldPassword, newPassword) {
  return (dispatch) => {
    changePassword(oldPassword, newPassword)
      .then((response) => {
        if (response.message.includes('success')) {
          toast.success('Password Changed successfully');
          dispatch(passChangeAction(true));
          //   dispatch(generateOtpAction(true));
          return;
        }
        dispatch(
          passChangeFailedAction(
            response?.data?.message || 'An error occurred, please try again.'
          )
        );
      })
      .catch((error) => {
        console.error('Error: ', error);
        toast.error(error.toString() || 'An error occurred, please try again.');
        dispatch(
          passChangeFailedAction(
            error.response?.data?.message ||
              'An error occurred, please try again.'
          )
        );
        // const errorMessage = formatError(
        //   error?.response?.data || error.response?.data.message
        // );
        // dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data
  };
}

export function generateOtpAction(data) {
  return {
    type: GENERATE_OTP_ACTION,
    payload: data
  };
}
export function generateOtpFailedAction(data) {
  return {
    type: GENERATE_OTP_FAILED_ACTION,
    payload: data
  };
}

export function passResetAction(data) {
  return {
    type: PASSWORD_RESET_ACTION,
    payload: data
  };
}
export function passResetFalseAction() {
  return {
    type: PASSWORD_RESET_FALSE
  };
}
export function passResetFailedAction(data) {
  return {
    type: PASSWORD_RESET_FAILED_ACTION,
    payload: data
  };
}

export function passChangeAction(data) {
  return {
    type: PASSWORD_CHANGE_ACTION,
    payload: data
  };
}
export function passChangeFailedAction(data) {
  return {
    type: PASSWORD_CHANGE_FAILED_ACTION,
    payload: data
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status
  };
}
