import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';

const LocationInput = ({ setUserLocation, setLocationSelected, location }) => {
  const [query, setQuery] = useState(location);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isValid, setIsValid] = useState(true);
  const [loadingSuggestions, setLoadingSuggestionsFlag] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [disableSearch, setDisableSearch] = useState(true);
  const inputRef = useRef(null);
  const svgRef = useRef(null);
  const dropdownRef = useRef(null);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(
        event.target ||
          (svgRef.current && !svgRef.current.contains(event.target))
      )
    ) {
      setTimeout(() => {
        setShowDropdown(false);
      }, 200);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const extractCity = (displayName) => {
    return displayName.split(',')[0].trim();
  };

  const fetchLocationSuggestions = async (city) => {
    try {
      setLoadingSuggestionsFlag(true);
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/default/city-suggestions?query=${city}`,
        {
          method: 'GET'
        }
      );
      const data = await response.json();
      setSuggestions(data.data || []);
      setShowDropdown(true);
    } catch (error) {
      console.error('Error fetching location suggestions:', error);
    } finally {
      setLoadingSuggestionsFlag(false);
    }
  };

  const debouncedFetchLocationSuggestions = useCallback(
    debounce(fetchLocationSuggestions, 1000),
    []
  );

  const handleInputChange = (e) => {
    const city = e.target.value;

    const isValidInput = /^[a-zA-Z0-9\s,.'-]*$/.test(city);

    if (!isValidInput) {
      return;
    }

    setUserLocation(city);
    const isActualCitySelectedFromSuggestions =
      e.target?.value.split(',').length > 1;
    let query = '';
    if (city) {
      setLoadingSuggestionsFlag(true);
      if (isActualCitySelectedFromSuggestions) {
        query = extractCity(city);
      } else {
        query = city;
      }
      setQuery(query);
      setSelectedCity('');
      setActiveIndex(-1);
      setIsValid(false);
      setLocationSelected(false);
    } else {
      setQuery('');
      setSuggestions([]);
      // setShowDropdown(false);
    }

    // if (city) {
    //   setLoadingSuggestionsFlag(true);
    //   // debouncedFetchLocationSuggestions(city);
    //   setShowDropdown(true);
    // } else {
    //   setSuggestions([]);
    //   setShowDropdown(false);
    // }
  };

  const handleSelectSuggestion = (suggestion) => {
    const selectedCity = extractCity(suggestion.displayName);
    setSelectedCity(selectedCity);
    setQuery(suggestion.displayName);
    setUserLocation(suggestion.displayName);
    // setSuggestions([]);
    setShowDropdown(false);
    setActiveIndex(-1);
    setIsValid(true);
    setLocationSelected(true);
    inputRef.current.focus();
  };

  const handleBlur = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.relatedTarget)) {
      setTimeout(() => setShowDropdown(false), 200);
    }
  };

  const handleKeyDown = (e) => {
    if (!showDropdown) {
      return;
    }

    const inputElement = e.target;
    const isAllTextSelected =
      inputElement.selectionStart === 0 &&
      inputElement.selectionEnd === inputElement.value.length;

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1
      );
    } else if (e.key === 'Enter' && activeIndex >= 0) {
      e.preventDefault();
      handleSelectSuggestion(suggestions[activeIndex]);
    } else if (e.key === 'Escape') {
      setShowDropdown(false);
    }
    // else if (e.key === "Backspace") {
    //   if (isAllTextSelected) {
    //     setQuery("");
    //     setSuggestions([]);
    //   } else {
    //     const city = extractCity(query);
    //     setQuery(city);
    //     debouncedFetchLocationSuggestions(city);
    //     setShowDropdown(true);
    //   }
    // }
  };

  const selectedCityIsInSuggestions = (cityName) => {
    return !!suggestions.find(
      (suggestion) => suggestion.displayName === cityName
    );
  };
  useEffect(() => {
    if (query && !disableSearch) {
      if (!selectedCityIsInSuggestions(query)) {
        debouncedFetchLocationSuggestions(extractCity(query));
      }
    }
  }, [query, disableSearch]);

  useEffect(() => {
    if (showDropdown && dropdownRef.current) {
      const activeElement = dropdownRef.current.querySelector('.active');
      if (activeElement) {
        activeElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [activeIndex, showDropdown]);

  useEffect(() => {
    setQuery(location);
  }, [location]);

  return (
    <div className="position-relative" onBlur={handleBlur}>
      <div className="position-relative">
        <input
          ref={inputRef}
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={() => {
            setDisableSearch(false);
            setShowDropdown(true);
          }}
          //   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
          className={`bg-gray-50 border form-control solid${
            isValid ? 'border-gray-300' : 'border-red-500'
          } text-gray-900 text-sm rounded focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 placeholder-gray-300`}
          placeholder="Enter city"
          aria-label="location"
        />
        <svg
          className="position-absolute cursor-pointer"
          ref={svgRef}
          onClick={() => {
            setShowDropdown(true);
          }}
          onFocus={() => {
            setDisableSearch(false);
            setShowDropdown(true);
          }}
          style={{
            top: '50%',
            right: '0px',
            transform: 'translate(-50%, -50%)'
          }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path d="M7 10l5 5 5-5H7z" />
        </svg>
      </div>
      {showDropdown && (
        <div
          ref={dropdownRef}
          className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded max-h-60 overflow-auto shadow-lg"
        >
          {!query ? (
            <div className="p-2 text-sm text-gray-500">Type your city</div>
          ) : loadingSuggestions ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" className="m-2" />
            </div>
          ) : suggestions.length > 0 ? (
            <div className="p-2 text-sm text-gray-500">
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className={`cursor-pointer p-2 hover:bg-gray-200 ${
                    index === activeIndex ? 'bg-gray-200 active' : ''
                  }`}
                  onClick={() => handleSelectSuggestion(suggestion)}
                >
                  {suggestion.displayName}
                </div>
              ))}
            </div>
          ) : (
            <div className="p-2 text-gray-500">No suggestions found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
