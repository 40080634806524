import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import currencySymbols from 'currency-symbol-map';
import { formatToCurrency } from '../../utils/CommonFunctions';
import { BsFillSuitcaseLgFill } from 'react-icons/bs';
import { FaDotCircle, FaMapMarkedAlt } from 'react-icons/fa';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { IoIosArrowRoundBack } from 'react-icons/io';

const JobView = () => {
  const { jobId } = useParams() || {};
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(0); // 0=loading 1=loaded 2=errored
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;
  const [currencySymbol, setCurrencySymbol] = useState('₹');

  useEffect(() => {
    const storedCurrency = localStorage.getItem('currency');

    if (storedCurrency) {
      const symbol = currencySymbols(storedCurrency) || '₹';
      setCurrencySymbol(symbol);
    }
  }, []);

  const fetchJobDetails = async () => {
    const URL = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/job/${jobId}`;
    try {
      setLoading(0);
      const response = await fetch(URL);
      if (!response.ok) {
        setLoading(2);
        navigate('/page-error-404');
        return;
      }
      const body = await response.json();
      const data = body.data;
      const responsibilitiesArray = data.responsibilities
        .split('. ')
        .map((item) => item.trim())
        .filter((item) => item);

      data['responsibilities'] = responsibilitiesArray;
      setJobDetails(data);
      setLoading(1);
    } catch (error) {
      console.error('Error fetching job details', error);
      navigate('/page-error-404');
    }
  };

  useEffect(() => {
    fetchJobDetails();
  }, [jobId]);

  const formatStringForFrontend = (str) => {
    const words = str.split('_');

    const formatWord = (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    };

    const formattedWords = words.map((word) => {
      if (word.includes(' ')) {
        return word
          .split(' ')
          .map((w) => {
            return formatWord(w);
          })
          .join(' ');
      } else {
        return formatWord(word);
      }
    });

    return formattedWords.join(' ');
  };

  const handleCloneJob = () => {
    navigate(`/clone-job/${jobId}`);
  };

  const timeAgo = (pastDate) => {
    const currentDate = new Date();
    const pastDateTime = new Date(pastDate);

    const diffInMs = currentDate - pastDateTime;

    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return years === 1 ? '1 year ago' : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? '1 month ago' : `${months} months ago`;
    } else if (weeks > 0) {
      return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
    } else if (days > 0) {
      return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    } else {
      return seconds === 1 ? '1 second ago' : `${seconds} seconds ago`;
    }
  };

  const handleAddNewJob = () => {
    navigate('/new-job');
  };

  const handleBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/jobs'); // Default fallback
    }
  };

  const renderExperienceField = (min, max) => {
    if (min || min === 0) {
      if (max) {
        return `${min} - ${max} Years`;
      } else {
        return `${min} Years`;
      }
    } else {
      return '';
    }
  };
  const renderExperienceLabel = (min, max) => {
    if (min || min === 0) {
      if (max) {
        return 'Experience';
      } else {
        return 'Minimum Experience';
      }
    } else {
      return '';
    }
  };

  const DetailItem = ({ icon, label, value }) => (
    <div
      className="detail-item"
      style={{
        padding: '16px',
        borderRadius: '8px',
        backgroundColor: '#f8f9fa',
        marginBottom: '12px',
        transition: 'all 0.3s ease',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <div
        className="icon-wrapper"
        style={{
          position: 'absolute',
          top: '12px',
          right: '12px',
          backgroundColor: '#e7eeff',
          width: '32px',
          height: '32px',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: '0.8'
        }}
      >
        <i
          className={`${icon}`}
          style={{ color: '#4A6FDC', fontSize: '14px' }}
        ></i>
      </div>
      <div className="detail-content" style={{ paddingRight: '40px' }}>
        <h6
          className="detail-label mb-1"
          style={{
            fontSize: '12px',
            color: '#6c757d',
            fontWeight: '500',
            textTransform: 'uppercase',
            letterSpacing: '0.5px'
          }}
        >
          {label}
        </h6>
        <div
          className="detail-value"
          style={{
            fontSize: '15px',
            color: '#2c3e50',
            fontWeight: '500'
          }}
        >
          {value}
        </div>
      </div>
    </div>
  );

  const detailsContainerStyles = {
    '.details-container': {
      display: 'grid',
      gap: '16px'
    },
    '.detail-item:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      backgroundColor: '#ffffff'
    },
    '@media (max-width: 768px)': {
      '.details-container': {
        gridTemplateColumns: '1fr'
      }
    }
  };

  const handleEditClick = () => {
    navigate(`/update-job/${jobId}`);
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <div
          onClick={handleBack}
          style={{
            cursor: 'pointer',
            marginRight: '1rem',
            transition: 'transform 0.2s ease',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = 'scale(1.1)';
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
          }}
        >
          <IoIosArrowRoundBack size={32} />
        </div>
        <h3 className="mb-0 me-auto">Job View</h3>
        <div>
          <Button
            className="btn btn-primary me-3 btn-sm d-inline-flex align-items-center"
            onClick={handleAddNewJob}
            style={{
              height: '38px',
              padding: '0 16px',
              fontSize: '14px',
              fontWeight: '500'
            }}
          >
            <i className="fas fa-plus me-2"></i>Add New Job
          </Button>

          <Button
            className="btn btn-primary me-3 btn-sm d-inline-flex align-items-center"
            onClick={handleEditClick}
            style={{
              height: '38px',
              padding: '0 16px',
              fontSize: '14px',
              fontWeight: '500'
            }}
          >
            <HiOutlinePencilAlt size={18} /> Edit Job
          </Button>

          <Link
            to={`/job-application/${jobId}`}
            className="btn btn-sm d-inline-flex align-items-center"
            title="View Applications"
            style={{
              transition: 'all 0.3s ease',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              height: '38px',
              padding: '0 16px',
              backgroundColor: '#4A6FDC',
              border: 'none',
              fontSize: '14px',
              fontWeight: '500',
              letterSpacing: '0.3px',
              color: 'white'
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = 'translateY(-1px)';
              e.currentTarget.style.boxShadow = '0 4px 6px rgba(0,0,0,0.15)';
              e.currentTarget.style.backgroundColor = '#3857B3';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = 'translateY(0)';
              e.currentTarget.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
              e.currentTarget.style.backgroundColor = '#4A6FDC';
            }}
          >
            <i className="fas fa-users me-2" style={{ fontSize: '16px' }}></i>
            Applications
          </Link>
        </div>
      </div>
      {loading === 1 && jobDetails && (
        <div className="row">
          <div className="col-xl-9 col-xxl-8">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      <h4 className="fs-20 d-block">
                        <Link className="text-black" to={'#'}>
                          {jobDetails.title}
                        </Link>
                      </h4>
                      <div className="d-block">
                        {/* <span className="me-2"><Link to={"#"}><i className="text-primary fas fa-briefcase me-2"></i>Apcd company</Link></span> */}
                        <span className="me-2">
                          <label>
                            <FaMapMarkedAlt className="text-primary mr-2 " />
                            {jobDetails.location}
                          </label>
                        </span>
                        {/* <span><Link to={"#"}><i className="text-primary fas fa-eye me-2"></i>View</Link></span> */}
                      </div>
                      <div className="d-block">
                        {/* <span className="me-2"><Link to={"#"}><i className="text-primary fas fa-briefcase me-2"></i>Apcd company</Link></span> */}
                        <span className="me-2">
                          <label>
                            <BsFillSuitcaseLgFill className="mr-2 text-primary" />
                            {jobDetails.companyName}
                          </label>
                        </span>
                        {/* <span><Link to={"#"}><i className="text-primary fas fa-eye me-2"></i>View</Link></span> */}
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block d-flex justify-content-center align-items-center"
                        onClick={handleCloneJob}
                      >
                        Clone Job
                      </button>
                    </div>
                  </div>

                  <div className="card-body pb-0">
                    <h4 className="fs-20 mb-3">Skill & Experience</h4>
                    <div className="ms-2 d-flex flex-wrap">
                      {jobDetails.skills.map((skill) => (
                        <label className="bg-secondary-light me-2 px-3 py-1 rounded text-secondary ">
                          {skill}
                        </label>
                      ))}
                    </div>
                    <h4 className="fs-20 mt-2 mb-3">Overview</h4>
                    <div className="ms-2">
                      <p>{jobDetails.description}</p>
                    </div>
                    <h4 className="fs-20 mb-3">Requirements</h4>
                    <div className="ms-2 d-flex flex-column gap-2 justify-content-start mb-4">
                      {jobDetails.responsibilities.map((item) => (
                        <div
                          key={item}
                          className="d-flex gap-1 align-items-center gap-2"
                        >
                          <FaDotCircle className="text-primary border-2" />
                          <p className="mb-0">{item}</p>
                        </div>
                      ))}
                    </div>
                    <h4 className="fs-20 mb-3">Qualifications</h4>
                    <div className="ms-2  d-flex flex-column gap-2 justify-content-start mb-4">
                      {jobDetails.qualifications.split('. ').map((item) => (
                        <div
                          key={item}
                          className="d-flex gap-1 align-items-center gap-2"
                        >
                          <FaDotCircle className="text-primary  border-2" />
                          <p className="mb-0">{item}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-xxl-4">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h4 className="fs-20 mb-0">Details</h4>
                  </div>
                  <div className="card-body">
                    <div className="details-container">
                      <DetailItem
                        icon="fas fa-map-marker-alt"
                        label="Work Location Type"
                        value={formatStringForFrontend(
                          jobDetails.workLocationType
                        )}
                      />

                      {(jobDetails.minimumExperience ||
                        jobDetails.minimumExperience === 0) && (
                        <DetailItem
                          icon="fas fa-briefcase"
                          label={renderExperienceLabel(
                            jobDetails.minimumExperience,
                            jobDetails.maximumExperience
                          )}
                          value={renderExperienceField(
                            jobDetails.minimumExperience,
                            jobDetails.maximumExperience
                          )}
                        />
                      )}

                      <DetailItem
                        icon="fas fa-money-bill-wave"
                        label="Salary"
                        value={
                          jobDetails.minimumSalary ? (
                            <>
                              {formatToCurrency(jobDetails.minimumSalary)} -{' '}
                              {formatToCurrency(jobDetails.maximumSalary)}
                            </>
                          ) : jobDetails.maximumSalary ? (
                            formatToCurrency(jobDetails.maximumSalary)
                          ) : (
                            'NA'
                          )
                        }
                      />

                      <DetailItem
                        icon="fas fa-map-marker-alt"
                        label="Location"
                        value={jobDetails.location}
                      />

                      <DetailItem
                        icon="fas fa-business-time"
                        label="Job Type"
                        value={`${formatStringForFrontend(jobDetails.jobType)} (${formatStringForFrontend(
                          jobDetails.workHours
                        )})`}
                      />

                      <DetailItem
                        icon="fas fa-calendar"
                        label="Posted Date"
                        value={timeAgo(jobDetails.createdAt)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading === 0 && (
        <div className="d-flex flex-column align-items-center justify-content-center gap-5 mt-5 pt-5">
          <div className="mt-5 mb-5 pt-5 pb-4"></div>
          <Spinner animation="border mb-3" style={{ color: '#858585' }} />
        </div>
      )}
      {loading === 2 && <h1>Job not found</h1>}
    </>
  );
};
export default JobView;
