import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { MdOutlineWifiTetheringErrorRounded } from 'react-icons/md';

const LogoCustomisation = () => {
  const [horizontalLogo, setHorizontalLogo] = useState(null);
  const [squareLogo, setSquareLogo] = useState(null);
  const [selectedHorizontalFile, setSelectedHorizontalFile] = useState(null);
  const [selectedSquareFile, setSelectedSquareFile] = useState(null);
  const [horizontalLogoKey, setHorizontalLogoKey] = useState(null);
  const [squareLogoKey, setSquareLogoKey] = useState(null);
  const horizontalLogoInputRef = useRef(null);
  const squareLogoInputRef = useRef(null);
  const navigate = useNavigate();
  const { orgId: paramOrgId } = useParams();
  const [loading, setLoading] = useState(1);

  useEffect(() => {
    fetchExistingLogos();
  }, []);

  const getAccessToken = (isParamOrgId) => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const superAdminUser = JSON.parse(localStorage.getItem('superAdminUser'));

    if (isParamOrgId) {
      return superAdminUser?.session?.accessToken || null;
    } else {
      return userDetails?.session?.accessToken || null;
    }
  };

  const fetchExistingLogos = async () => {
    setLoading(1);
    try {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      const orgId = paramOrgId || userDetails?.user?.organizationId || null;
      const isParamOrgId = !!paramOrgId;
      const accessToken = getAccessToken(isParamOrgId);

      if (!accessToken || !orgId) {
        throw new Error('Access token or organization ID not found');
      }

      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch logos');
      }

      const data = await response.json();
      const logoData = data.data.organization.logo;

      let isAnyLogoUpdated = false;

      if (logoData.horizontalLogoSignedUrl) {
        setHorizontalLogo(logoData.horizontalLogoSignedUrl);
        localStorage.setItem(
          'horizontalLogo',
          logoData.horizontalLogoSignedUrl
        );
        isAnyLogoUpdated = true;
      }
      if (logoData.squareLogoSignedUrl) {
        setSquareLogo(logoData.squareLogoSignedUrl);

        localStorage.setItem('squareLogo', logoData.squareLogoSignedUrl);
        isAnyLogoUpdated = true;
      }
      if (isAnyLogoUpdated) {
        window.dispatchEvent(new Event('logoUpdated'));
      }

      setHorizontalLogoKey(logoData.horizontal);
      setSquareLogoKey(logoData.square);
      setLoading(0);
    } catch (error) {
      console.error('Error fetching logos:', error);
      toast.error('Failed to fetch existing logos');
      setLoading(2);
    }
  };

  const uploadLogo = async (isHorizontal) => {
    setLoading(1);
    try {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      const orgId = paramOrgId || userDetails?.user?.organizationId || null;
      const isParamOrgId = !!paramOrgId;
      const accessToken = getAccessToken(isParamOrgId);

      if (!accessToken) {
        throw new Error('Access token not found');
      }

      const file = isHorizontal ? selectedHorizontalFile : selectedSquareFile;

      if (!file) {
        throw new Error('No file selected');
      }

      const formData = new FormData();
      formData.append('image', file);

      const uploadResponse = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/media/upload-image`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          body: formData
        }
      );

      if (!uploadResponse.ok) {
        throw new Error('Failed to upload image');
      }

      const uploadData = await uploadResponse.json();

      const updatedLogo = {
        horizontal: isHorizontal ? uploadData.data.objKey : horizontalLogoKey,
        square: isHorizontal ? squareLogoKey : uploadData.data.objKey
      };

      const updateResponse = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify({
            logo: updatedLogo
          })
        }
      );

      if (!updateResponse.ok) {
        throw new Error('Failed to update organization logo');
      }

      if (updateResponse.ok) {
        const { message } = await updateResponse.json();
        if (message === 'Organization updated successfully') {
          toast.success('Logo Updated Successfully');

          if (isHorizontal) {
            localStorage.setItem('horizontalLogo', uploadData.data.signedUrl);
          } else {
            localStorage.setItem('squareLogo', uploadData.data.signedUrl);
          }

          window.dispatchEvent(new Event('logoUpdated'));
        }
      }

      if (isHorizontal) {
        setSelectedHorizontalFile(null);
        setHorizontalLogoKey(uploadData.data.objKey);
      } else {
        setSelectedSquareFile(null);
        setSquareLogoKey(uploadData.data.objKey);
      }
      setLoading(0);
    } catch (error) {
      console.error('Error uploading logo:', error);
      toast.error('Failed to upload logo');
      setLoading(2);
    }
  };

  const handleFileChange = (event, isHorizontal) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ['image/jpeg', 'image/png'];
      if (!validTypes.includes(file.type)) {
        toast.error('Please select a valid JPEG or PNG file.');
        return;
      }
      if (isHorizontal) {
        setSelectedHorizontalFile(file);
        setHorizontalLogo(URL.createObjectURL(file));
      } else {
        setSelectedSquareFile(file);
        setSquareLogo(URL.createObjectURL(file));
      }
    }
  };

  return (
    <>
      <h3 className="mb-4 me-auto">Logo Customization</h3>

      {loading === 1 && (
        <div className="d-flex align-items-center justify-content-center gap-4 mt-5 pt-5">
          <Spinner animation="border mb-3" style={{ color: '#858585' }} />
        </div>
      )}

      {loading === 2 && (
        <div className="d-flex flex-column align-items-center mt-5 pt-5">
          <MdOutlineWifiTetheringErrorRounded size={120} color="#878787B7" />
          <h2>Error fetching data</h2>
          <h3 style={{ color: '#828282' }}>Check your network and try again</h3>
        </div>
      )}
      {loading === 0 && (
        <div>
          <div
            style={{
              height: 'fit-content',
              maxWidth: '28rem',
              width: '100%',
              backgroundColor: 'white',
              padding: '1rem',
              borderRadius: '0.5rem',
              boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
            }}
          >
            <div style={{ marginBottom: '1rem' }}></div>

            <div style={{ marginBottom: '1rem' }}>
              <label
                style={{
                  display: 'block',
                  fontSize: '0.875rem',
                  fontWeight: '500',
                  color: '#4a5568'
                }}
              >
                Horizontal Logo
              </label>
              <p
                style={{
                  fontSize: '0.75rem',
                  color: '#a0aec0',
                  marginBottom: '0.5rem'
                }}
              >
                Used for the main logo in the navbar and other wide placements.
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '1.5rem',
                  border: '2px dashed #cbd5e0',
                  borderRadius: '0.375rem',
                  cursor: 'pointer',
                  transition: 'all 0.3s'
                }}
                onClick={() => horizontalLogoInputRef.current.click()}
              >
                <div style={{ textAlign: 'center' }}>
                  {horizontalLogo ? (
                    <img
                      id="horizontal-logo-preview"
                      style={{
                        maxHeight: '6rem',
                        objectFit: 'contain',
                        width: '100%'
                      }}
                      src={horizontalLogo}
                      alt="Horizontal Logo Preview"
                    />
                  ) : (
                    <>
                      <svg
                        style={{
                          height: '3rem',
                          width: '3rem',
                          color: '#a0aec0'
                        }}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path d="M8 17l6-6 14 14m8-10l-6 6m6-6V7a2 2 0 00-2-2H7a2 2 0 00-2 2v34a2 2 0 002 2h34a2 2 0 002-2v-9m-6-6l6 6" />
                      </svg>
                      <div style={{ fontSize: '0.875rem', color: '#4a5568' }}>
                        <label
                          htmlFor="file-upload"
                          style={{
                            cursor: 'pointer',
                            fontWeight: '500',
                            color: '#3182ce',
                            textDecoration: 'none'
                          }}
                        >
                          <span>Upload a file</span>
                        </label>
                      </div>
                      <p style={{ fontSize: '0.75rem', color: '#a0aec0' }}>
                        PNG, JPG up to 500KB
                      </p>
                    </>
                  )}
                  <input
                    type="file"
                    id="horizontalLogo"
                    ref={horizontalLogoInputRef}
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, true)}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>

              {selectedHorizontalFile && (
                <button
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0.5rem 1rem',
                    border: '1px transparent',
                    borderRadius: '0.375rem',
                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                    fontSize: '0.875rem',
                    fontWeight: '500',
                    color: 'white',
                    backgroundColor: '#3182ce',
                    marginTop: '1rem',
                    cursor: 'pointer',
                    outline: 'none'
                  }}
                  onClick={() => uploadLogo(true)}
                >
                  Upload Horizontal Logo
                </button>
              )}
            </div>

            <div style={{ marginBottom: '1rem' }}>
              <label
                style={{
                  display: 'block',
                  fontSize: '0.875rem',
                  fontWeight: '500',
                  color: '#4a5568'
                }}
              >
                Square Logo
              </label>
              <p
                style={{
                  fontSize: '0.75rem',
                  color: '#a0aec0',
                  marginBottom: '0.5rem'
                }}
              >
                Used for the main logo in the navbar and for company logo.
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '1.5rem',
                  border: '2px dashed #cbd5e0',
                  borderRadius: '0.375rem',
                  cursor: 'pointer',
                  transition: 'all 0.3s'
                }}
                onClick={() => squareLogoInputRef.current.click()}
              >
                <div style={{ textAlign: 'center' }}>
                  {squareLogo ? (
                    <img
                      id="square-logo-preview"
                      style={{
                        height: '6rem',
                        width: '6rem',
                        objectFit: 'contain'
                      }}
                      src={squareLogo}
                      alt="Square Logo Preview"
                    />
                  ) : (
                    <>
                      <svg
                        style={{
                          height: '3rem',
                          width: '3rem',
                          color: '#a0aec0'
                        }}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path d="M8 17l6-6 14 14m8-10l-6 6m6-6V7a2 2 0 00-2-2H7a2 2 0 00-2 2v34a2 2 0 002 2h34a2 2 0 002-2v-9m-6-6l6 6" />
                      </svg>
                      <div style={{ fontSize: '0.875rem', color: '#4a5568' }}>
                        <label
                          htmlFor="file-upload"
                          style={{
                            cursor: 'pointer',
                            fontWeight: '500',
                            color: '#3182ce',
                            textDecoration: 'none'
                          }}
                        >
                          <span>Upload a file</span>
                        </label>
                      </div>
                      <p style={{ fontSize: '0.75rem', color: '#a0aec0' }}>
                        PNG, JPG up to 500KB
                      </p>
                    </>
                  )}
                  <input
                    type="file"
                    id="squareLogo"
                    ref={squareLogoInputRef}
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, false)}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
              {selectedSquareFile && (
                <button
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0.5rem 1rem',
                    border: '1px transparent',
                    borderRadius: '0.375rem',
                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                    fontSize: '0.875rem',
                    fontWeight: '500',
                    color: 'white',
                    backgroundColor: '#3182ce',
                    marginTop: '1rem',
                    cursor: 'pointer',
                    outline: 'none'
                  }}
                  onClick={() => uploadLogo(false)}
                >
                  Upload Square Logo
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LogoCustomisation;
