import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import html2pdf from 'html2pdf.js';
import {
  BsStarFill,
  BsGearFill,
  BsPeopleFill,
  BsLightbulbFill,
  BsAwardFill,
  BsHandThumbsUpFill,
  BsExclamationTriangleFill,
  BsXCircleFill
} from 'react-icons/bs';
import PDFDownloadButton from '../AppsMenu/CustomComponents/PDFDownloadButton';

const JobHvpView = () => {
  const [pdfLoading, setPdfLoading] = useState(false);
  const [hvpReport, setHvpReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const pdfContentRef = useRef(null);
  const { testId } = useParams();
  const navigate = useNavigate();

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;

  const fetchHvpReport = async () => {
    try {
      // setLoading(true);
      const accessToken = userDetails?.session.accessToken || null;
      if (!accessToken) {
        throw new Error('Not Authorized');
      }

      const apiUrl = `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/personality-test/${testId}/response?hvpPlus=true`;
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      };

      const response = await fetch(apiUrl, options);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response text:', errorText);

        if (errorText.startsWith('<!DOCTYPE')) {
          throw new Error('Received HTML response, expected JSON.');
        } else {
          const errorJson = JSON.parse(errorText);
          throw new Error(errorJson.message || 'Failed to fetch HVP report.');
        }
      }

      const result = await response.json();
      setHvpReport(result.hvpPlusTest.analysis);
    } catch (error) {
      console.error('Error fetching HVP report:', error);
      toast.error(error.message || 'An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHvpReport();
  }, [testId]);

  const downloadPDF = async () => {
    setPdfLoading(true);
    const element = pdfContentRef.current;
    const options = {
      margin: 0,
      filename: 'HVP_REPORT.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true, logging: true },
      jsPDF: { unit: 'mm', format: [520, 397], orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };

    try {
      await html2pdf().set(options).from(element).save();
      toast.success('PDF generated successfully!');
    } catch (error) {
      console.error('Error generating PDF:', error);
      toast.error('Failed to generate PDF. Please try again.');
    } finally {
      setPdfLoading(false);
    }
  };

  const renderCriticalThinkingScore = (value) => {
    const integerValue = parseInt(value);
    const className = integerValue > 50 ? 'text-success' : 'text-danger';
    return <h2 className={className}>{value}%</h2>;
  };

  return (
    <>
      {loading && (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className="mt-3">Loading HVP report...</p>
        </div>
      )}

      {!loading && !hvpReport && (
        <div className="alert alert-warning" role="alert">
          No HVP report data available. Please try again later.
        </div>
      )}

      {hvpReport && (
        <>
          <div className="d-flex w-100 justify-content-center">
            <button
              onClick={() => navigate('/personality-test')}
              className="btn btn-light btn-lg  mb-1 back-button rounded-pill text-black bg-white hover-bg-gray hover-text-white"
            >
              Back
            </button>
          </div>
          <body ref={pdfContentRef}>
            <style>
              {` @keyframes fadeIn {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      @keyframes slideUp {
        from {
          transform: translateY(20px);
          opacity: 0;
        }

        to {
          transform: translateY(0);
          opacity: 1;
        }
      }

      .animate-fadeIn {
        animation: fadeIn 1.5s ease-in-out;
      }

      .animate-slideUp {
        animation: slideUp 1.5s ease-in-out;
      }

      .hover-grow:hover {
        transform: scale(1.05);
        transition: transform 0.3s ease-in-out;
      }

      .header-gradient {
        background: #7f7fd5;
        color: white;
      }

      .header-title {
        font-size: 3.5rem;
        font-weight: 900;
        text-transform: uppercase;
      }

      .header-subtitle {
        font-size: 2rem;
        font-weight: 600;
        margin-top: 0.5rem;
      }

      .header-description {
        max-width: 800px;
        margin: 1.5rem auto 0;
        font-size: 1.25rem;
        line-height: 1.75rem;
      }

      .header-button {
        font-size: 1.25rem;
        padding: 0.75rem 2rem;
        border-radius: 50px;
        margin-top: 2rem;
        background-color: #ffffff;
        color: #007bff;
        border: none;
        font-weight: bold;
        transition: all 0.3s ease;
      }

      .header-button:hover {
        background-color: #007bff;
        color: #ffffff;
        transform: translateY(-2px);
      }

      .header-title::after {
        content: '';
        display: block;
        width: 60px;
        height: 4px;
        background-color: #ffffff;
        margin: 1rem auto 0;
      }

      .executive-summary {
        background-color: #f8f9fa;
        padding: 50px 0;
      }

      .text-gradient {
        color:black;
        // background: -webkit-linear-gradient(45deg, #007bff, #28a745);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }

      .card-advanced {
        border-radius: 15px;
        overflow: hidden;
        transition: all 0.4s ease;
      }



      .card-title {
        color: #495057;
      }

      .card-text {
        color: #6c757d;
      }

      .hover-highlight:hover {
        background-color: #e9ecef;
        transition: background-color 0.3s ease-in-out;
      }

      .overall-critical-thinking {
        background-color: #f8f9fa;
        padding: 50px 0;
      }

      .progress-circle {
        position: relative;
        display: inline-block;
      }

      .circular-chart {
        max-width: 100%;
        max-height: 100%;
      }

      .circle-bg {
        fill: none;
        stroke: #e6e6e6;
        stroke-width: 2.8;
      }

      .circle {
        fill: none;
        stroke-width: 2.8;
        stroke-linecap: round;
        stroke: #007bff;
        animation: progress 1s ease-out forwards;
      }

      @keyframes progress {
        0% {
          stroke-dasharray: 0, 100;
        }
      }

      .percentage {
        font-size: 0.6em;
        text-anchor: middle;
        fill: #007bff;
      }

      .risk-assessments {
        background-color: #f8f9fa;
      }

      .icon-wrapper {
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1.25rem;
      }

      .table-borderless tbody+tbody {
        border-top: none;
      }

      .badge {
        font-size: 1rem;
        padding: 0.5rem 1.5rem;
        border-radius: 20px;
      }

      .table thead {
        background: #f1f1f1;
        border-bottom: 2px solid #ddd;
      }

      .table td {
        vertical-align: middle;
        padding: 1.25rem 1rem;
      }

      .table-hover tbody tr:hover {
        background-color: #e9ecef;
      }

      .icon-circle {
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        border-radius: 50%;
      }

      .card {
        transition: transform 0.3s ease, box-shadow 0.3s ease;
      }

      .card:hover {
        transform: translateY(-5px);
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      }
      .hover-bg-gray:hover {
  background-color: gray !important;
  color: white !important;
}

`}
            </style>

            <div className="container py-5">
              {/* Header Section */}
              <div className="container-fluid header-gradient text-center py-5 position-relative">
                <div className="container">
                  <h1 className="header-title text-white  position-relative">
                    HVP Plus Report
                  </h1>
                  <h2 className="header-subtitle text-white  position-relative">
                    {
                      hvpReport?.executive_summary.critical_thinking
                        .critical_thinking_style
                    }
                  </h2>
                  <p className="header-description  mx-auto">
                    {
                      hvpReport?.executive_summary.critical_thinking
                        .style_description
                    }
                  </p>
                </div>
              </div>

              {/* Executive Summary Section */}
              <section className="executive-summary py-2 text-dark my-4">
                <div className="container mt-2">
                  <div className="row text-center text-md-start mb-4">
                    <div className="d-flex w-100 flex-column align-items-center justify-content-center">
                      <h3 className="display-6 fw-bold text-uppercase text-gradient mb-2 text-center  position-relative">
                        Executive Summary
                        <span
                          className="position-absolute"
                          style={{
                            bottom: '-10px', // Adjusted value
                            width: '120px',
                            left: 'calc(50% - 60px)',
                            height: '4px',
                            backgroundColor: '#3b82f6',
                            zIndex: 1 // Ensure it is on top
                          }}
                        />
                      </h3>
                      <p className="lead  mb-0 mt-3">
                        An overview of your critical thinking abilities across
                        various domains.
                      </p>
                    </div>
                  </div>
                  <div className="row g-4">
                    {/* Intuitive Thinking Card */}
                    <div className="col-md-4 d-flex justify-content-center">
                      <div className="card card-advanced shadow-sm border-0 text-center p-4 d-flex flex-column align-items-center justify-content-center">
                        <h4 className="card-title fs-5 fw-bold mb-3 text-black">
                          Intuitive Thinking
                        </h4>
                        <p className="card-text h6 mb-4">
                          {
                            hvpReport.executive_summary.critical_thinking
                              .intuitive_thinking.summary
                          }
                        </p>
                        <span
                          className="badge rounded-pill text-bg-primary shadow-sm px-4 py-3"
                          style={{ backgroundColor: '#0D6EFD' }}
                        >
                          {
                            hvpReport.executive_summary.critical_thinking
                              .intuitive_thinking.rating
                          }
                        </span>
                      </div>
                    </div>
                    {/* Practical Thinking Card */}
                    <div className="col-md-4 d-flex justify-content-center">
                      <div className="card card-advanced shadow-sm border-0 text-center p-4 d-flex flex-column align-items-center justify-content-center">
                        <h4 className="card-title fs-5 fw-bold mb-3 text-black">
                          Practical Thinking
                        </h4>
                        <p className="card-text h6 mb-4">
                          {
                            hvpReport.executive_summary.critical_thinking
                              .practical_thinking.summary
                          }
                        </p>
                        <span
                          className="badge rounded-pill shadow-sm px-4 py-3"
                          style={{ backgroundColor: '#198754' }}
                        >
                          {
                            hvpReport.executive_summary.critical_thinking
                              .practical_thinking.rating
                          }
                        </span>
                      </div>
                    </div>
                    {/* Systems Thinking Card */}
                    <div className="col-md-4 d-flex justify-content-center">
                      <div className="card card-advanced shadow-sm border-0 text-center p-4 d-flex flex-column align-items-center justify-content-center">
                        <h4 className="card-title fs-5 fw-bold mb-3 text-black">
                          Systems Thinking
                        </h4>
                        <p className="card-text h6  mb-4">
                          {
                            hvpReport.executive_summary.critical_thinking
                              .systems_thinking.summary
                          }
                        </p>
                        <span
                          className="badge rounded-pill text-bg-warning shadow-sm px-4 py-3"
                          style={{ backgroundColor: '#ffc107' }}
                        >
                          {
                            hvpReport.executive_summary.critical_thinking
                              .systems_thinking.rating
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* Overall Critical Thinking Section */}
              <section className="overall-critical-thinking py-2 text-dark">
                <div className="container mt-2">
                  <div className="row text-center text-md-start mb-4">
                    <div className="d-flex w-100 flex-column align-items-center justify-content-center">
                      <h3 className="display-6 fw-bold text-uppercase text-gradient mb-2 text-center  position-relative">
                        Overall Critical Thinking
                        <span
                          className="position-absolute"
                          style={{
                            bottom: '-10px', // Adjusted value
                            width: '120px',
                            left: 'calc(50% - 60px)',
                            height: '4px',
                            backgroundColor: '#3b82f6',
                            zIndex: 1 // Ensure it is on top
                          }}
                        />
                      </h3>
                      <p className="lead  mb-0 mt-3">
                        A comprehensive evaluation of your critical thinking
                        capabilities.
                      </p>
                    </div>
                  </div>
                  <div className="row justify-content-center g-4">
                    {/* Critical Thinking Score Card */}
                    <div className="col-lg-6 d-flex align-items-stretch">
                      <div className="card shadow-sm border-0 text-center p-4 d-flex flex-column justify-content-center w-100">
                        <div className="card-body">
                          <div className="progress-circle mx-auto mb-4">
                            {renderCriticalThinkingScore(
                              hvpReport.overall_critical_thinking.score
                            )}
                            {/* <svg
                              viewBox="0 0 36 36"
                              className="circular-chart blue"
                              width="120"
                              height="120"
                            >
                              <path
                                className="circle-bg"
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                fill="none"
                                stroke="#e6e6e6"
                                strokeWidth="4"
                              />
                              <path
                                className="circle"
                                strokeDasharray={`${hvpReport.overall_critical_thinking.score}, 100`}
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                fill="none"
                                stroke="#007bff"
                                strokeWidth="4"
                              />
                              <text
                                x="18"
                                y="20.35"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                className="percentage-text"
                                fontSize="7"
                                fill="#333"
                              >
                                {hvpReport.overall_critical_thinking.score}%
                              </text>
                            </svg> */}
                          </div>
                          <h4 className="card-title fs-4 fw-bold mb-3 text-black">
                            Critical Thinking Score
                          </h4>
                          <p className="card-text mb-0 h6 ">
                            {
                              hvpReport.overall_critical_thinking
                                .problem_solving
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Information Processing and Decision Making Cards */}
                    <div className="col-lg-6 d-flex flex-column justify-content-between">
                      <div className="card shadow-sm border-0 flex-grow-1 mb-4">
                        <div className="card-body  rounded d-flex justify-content-center align-items-center text-center">
                          <p className="card-text h6  mb-4">
                            {
                              hvpReport.overall_critical_thinking
                                .information_processing
                            }
                          </p>
                        </div>
                      </div>
                      <div className="card shadow-sm border-0 flex-grow-1">
                        <div className="card-body  rounded d-flex justify-content-center align-items-center text-center">
                          <p className="card-text h6  mb-4">
                            {
                              hvpReport.overall_critical_thinking
                                .decision_making
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* Risk Assessments Section */}
              <section className="risk-assessments ">
                <div className="container py-4">
                  <div className="row text-center">
                    <div className="col">
                      <h3 className="display-6 fw-bold text-uppercase text-gradient mb-2 text-center  position-relative">
                        Risk Assessments
                        <span
                          className="position-absolute"
                          style={{
                            bottom: '-10px', // Adjusted value
                            width: '120px',
                            left: 'calc(50% - 60px)',
                            height: '4px',
                            backgroundColor: '#3b82f6',
                            zIndex: 1 // Ensure it is on top
                          }}
                        />
                      </h3>
                      <p className="lead mt-3">
                        Comprehensive analysis of your core skills and their
                        associated risk levels.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table table-borderless align-middle mb-0">
                          <thead className="border-bottom">
                            <tr>
                              <th
                                scope="col"
                                className="text-start text-black ps-3"
                              >
                                Skills
                              </th>
                              <th
                                scope="col"
                                className="text-black text-center"
                              >
                                Risk Level
                              </th>
                              <th scope="col" className="text-black text-start">
                                Comments
                              </th>
                            </tr>
                          </thead>
                          <tbody className="rounded">
                            {Object.entries({
                              ...hvpReport.risk_assessments.core_skills,
                              ...hvpReport.risk_assessments.people_skills,
                              ...hvpReport.risk_assessments
                                .problem_solving_skills,
                              ...hvpReport.risk_assessments
                                .performance_qualities
                            }).map(([skill, assessment], index) => (
                              <tr
                                key={index}
                                className="bg-white rounded shadow-sm mb-4"
                              >
                                <td className="ps-3">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <p
                                        className={` small mb-0 ${
                                          index <
                                          Object.keys(
                                            hvpReport.risk_assessments
                                              .core_skills
                                          ).length
                                            ? 'text-primary' // Color for Core Skills
                                            : index <
                                                Object.keys(
                                                  hvpReport.risk_assessments
                                                    .core_skills
                                                ).length +
                                                  Object.keys(
                                                    hvpReport.risk_assessments
                                                      .people_skills
                                                  ).length
                                              ? 'text-info' // Color for People Skills
                                              : index <
                                                  Object.keys(
                                                    hvpReport.risk_assessments
                                                      .core_skills
                                                  ).length +
                                                    Object.keys(
                                                      hvpReport.risk_assessments
                                                        .people_skills
                                                    ).length +
                                                    Object.keys(
                                                      hvpReport.risk_assessments
                                                        .problem_solving_skills
                                                    ).length
                                                ? 'text-warning' // Color for Problem Solving Skills
                                                : 'text-success' // Color for Performance Qualities
                                        }`}
                                      >
                                        {index <
                                        Object.keys(
                                          hvpReport.risk_assessments.core_skills
                                        ).length
                                          ? 'Core Skills'
                                          : index <
                                              Object.keys(
                                                hvpReport.risk_assessments
                                                  .core_skills
                                              ).length +
                                                Object.keys(
                                                  hvpReport.risk_assessments
                                                    .people_skills
                                                ).length
                                            ? 'People Skills'
                                            : index <
                                                Object.keys(
                                                  hvpReport.risk_assessments
                                                    .core_skills
                                                ).length +
                                                  Object.keys(
                                                    hvpReport.risk_assessments
                                                      .people_skills
                                                  ).length +
                                                  Object.keys(
                                                    hvpReport.risk_assessments
                                                      .problem_solving_skills
                                                  ).length
                                              ? 'Problem Solving Skills'
                                              : 'Performance Qualities'}
                                      </p>
                                      <h6 className="mb-0 fw-bold text-black">
                                        {skill
                                          .replace(/_/g, ' ')
                                          .replace(/\b\w/g, (l) =>
                                            l.toUpperCase()
                                          )}
                                      </h6>
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: 'center',
                                    verticalAlign: 'middle'
                                  }}
                                >
                                  <div
                                    className={`icon-wrapper bg-${
                                      assessment.risk_level === 'Minimal'
                                        ? 'success'
                                        : assessment.risk_level === 'Moderate'
                                          ? 'warning'
                                          : 'danger'
                                    } rounded-circle d-flex align-items-center justify-content-center text-white`}
                                    style={{
                                      width: '40px',
                                      height: '40px',
                                      marginLeft: 'calc(50% - 20px)'
                                    }} // Center icon and make circle consistent
                                  >
                                    {assessment.risk_level === 'Minimal' ? (
                                      <BsHandThumbsUpFill />
                                    ) : assessment.risk_level === 'Moderate' ? (
                                      <BsExclamationTriangleFill />
                                    ) : (
                                      <BsXCircleFill />
                                    )}
                                    {/* <i
                                      className={`bi bi-${
                                        assessment.risk_level === "Minimal"
                                          ? "hand-thumbs-up"
                                          : assessment.risk_level === "Moderate"
                                          ? "exclamation-triangle"
                                          : "x-circle"
                                      }`}
                                    ></i> */}
                                  </div>
                                </td>
                                <td>
                                  <p className="card-text h6 text-left">
                                    {assessment.comments}
                                  </p>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* Detailed Descriptions Section */}
              <section className="detailed-descriptions">
                <div className="container">
                  <div className="row text-center mb-5">
                    <div className="col">
                      <h3 className="display-6 fw-bold text-uppercase text-gradient mb-2 text-center  position-relative">
                        Detailed Descriptions
                        <span
                          className="position-absolute"
                          style={{
                            bottom: '-10px', // Adjusted value
                            width: '120px',
                            left: 'calc(50% - 60px)',
                            height: '4px',
                            backgroundColor: '#3b82f6',
                            zIndex: 1 // Ensure it is on top
                          }}
                        />
                      </h3>
                      <p className="lead mt-3">
                        Insightful analysis and actionable recommendations for
                        each core skill.
                      </p>
                    </div>
                  </div>
                  <div className="row g-4">
                    {Object.entries(hvpReport.detailed_descriptions).flatMap(
                      ([category, skills], categoryIndex) => {
                        // Define mapping for colors and icons
                        const categoryStyles = {
                          core_skills: {
                            colorClass: 'bg-primary',
                            IconComp: BsStarFill,
                            title: 'Core Skills'
                          },
                          approach_to_work: {
                            colorClass: 'bg-info',
                            IconComp: BsGearFill,
                            title: 'Approach to Work'
                          },
                          people_skills: {
                            colorClass: 'bg-success',
                            IconComp: BsPeopleFill,
                            title: 'People Skills'
                          },
                          problem_solving_skills: {
                            colorClass: 'bg-warning',
                            IconComp: BsLightbulbFill,
                            title: 'Problem Solving Skills'
                          },
                          performance_qualities: {
                            colorClass: 'bg-secondary',
                            IconComp: BsAwardFill,
                            title: 'Performance Qualities'
                          }
                        };

                        // Get the color, icon, and title based on the category
                        const { colorClass, title, IconComp } = categoryStyles[
                          category
                        ] || {
                          colorClass: 'bg-primary',
                          iconClass: 'bi-check-circle',
                          title: 'General'
                        };

                        // Return the mapped elements
                        return Object.entries(skills).map(
                          ([skill, description], skillIndex) => {
                            const formattedSkill = skill
                              .replace(/_/g, ' ')
                              .replace(/\b\w/g, (l) => l.toUpperCase());

                            return (
                              <div
                                key={`${categoryIndex}-${skillIndex}`}
                                className="col-lg-6"
                              >
                                <div className="card border-0 shadow-sm h-100 rounded-lg">
                                  <div className="card-body p-4">
                                    <div className="d-flex align-items-center mb-4">
                                      <div
                                        className={`d-flex justify-content-center align-items-center rounded-circle ${colorClass}`}
                                        style={{
                                          width: '50px',
                                          height: '50px',
                                          flexShrink: 0 // Prevent stretching
                                        }}
                                      >
                                        <IconComp className="text-white h2 m-0" />
                                      </div>
                                      <div className="ms-3">
                                        <h4 className="card-title mb-0 text-black fw-bold">
                                          {formattedSkill}
                                        </h4>
                                        <p className=" mb-0">{title}</p>
                                      </div>
                                    </div>
                                    <div className="mb-3">
                                      <h5 className=" fw-semibold">
                                        Positive Statement:
                                      </h5>
                                      <p className="card-text h6">
                                        {description.positive_statement}
                                      </p>
                                    </div>
                                    <div>
                                      <h5 className=" fw-semibold">
                                        Development Area:
                                      </h5>
                                      <p className="card-text h6">
                                        {description.development_area}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        );
                      }
                    )}
                  </div>
                </div>
              </section>

              {/* <!-- Suggestions for Improvement Section --> */}
              <section className="suggestions-improvement">
                <div className="container">
                  <div className="row text-center mb-5">
                    <div className="col">
                      <h3 className="display-6 fw-bold text-uppercase text-gradient mb-2 text-center  position-relative">
                        Suggestions for Improvement
                        <span
                          className="position-absolute"
                          style={{
                            bottom: '-10px', // Adjusted value
                            width: '120px',
                            left: 'calc(50% - 60px)',
                            height: '4px',
                            backgroundColor: '#3b82f6',
                            zIndex: 1 // Ensure it is on top
                          }}
                        />
                      </h3>
                      <p className="lead mt-3">
                        Tailored recommendations to enhance your skills and
                        maximize potential.
                      </p>
                    </div>
                  </div>
                  <div className="row g-4">
                    {/* Suggestion Card 1 */}
                    <div className="col-md-4">
                      <div className="card border-0 shadow-sm rounded-lg text-center p-4">
                        <div className="card-body">
                          <h4
                            className="card-title fw-bold"
                            style={{ color: '#0D6EFD' }}
                          >
                            Intuitive Thinking
                          </h4>
                          <p className="card-text h6 mt-3">
                            {
                              hvpReport.overall_critical_thinking
                                .suggestions_for_improvement.intuitive_thinking
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Suggestion Card 2 */}
                    <div className="col-md-4">
                      <div className="card border-0 shadow-sm rounded-lg text-center p-4">
                        <div className="card-body">
                          <h4
                            className="card-title fw-bold"
                            style={{ color: '#198754' }}
                          >
                            Practical Thinking
                          </h4>
                          <p className="card-text h6 mt-3">
                            {
                              hvpReport.overall_critical_thinking
                                .suggestions_for_improvement.practical_thinking
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Suggestion Card 3 */}
                    <div className="col-md-4">
                      <div className="card border-0 shadow-sm rounded-lg text-center p-4">
                        <div className="card-body">
                          <h4
                            className="card-title fw-bold"
                            style={{ color: '#ffc107' }}
                          >
                            Systems Thinking
                          </h4>
                          <p className="card-text h6 mt-3">
                            {
                              hvpReport.overall_critical_thinking
                                .suggestions_for_improvement.systems_thinking
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* <!-- Summary of Critical Thinking Section --> */}
              <section className="suggestions-improvement">
                <div className="container">
                  {/* Section Title */}
                  <div className="row text-center mb-5">
                    <div className="col">
                      <h3 className="display-6 fw-bold text-uppercase text-gradient mb-2 text-center  position-relative">
                        Summary of Critical Thinking
                        <span
                          className="position-absolute"
                          style={{
                            bottom: '-10px', // Adjusted value
                            width: '120px',
                            left: 'calc(50% - 60px)',
                            height: '4px',
                            backgroundColor: '#3b82f6',
                            zIndex: 1 // Ensure it is on top
                          }}
                        />
                      </h3>
                    </div>
                  </div>

                  <div className="card shadow-sm border-0 flex-grow-1 mb-4">
                    <div className="rounded d-flex flex-column justify-content-center align-items-center text-center ">
                      <div className="card-body w-100">
                        <div
                          className="progress mt-3"
                          style={{ height: '30px' }}
                        >
                          {[
                            {
                              title: 'Strengths',
                              percentage:
                                hvpReport.summary_of_critical_thinking
                                  .strengths_percentage,
                              color: 'bg-primary'
                            },
                            {
                              title: 'Moderate Risks',
                              percentage:
                                hvpReport.summary_of_critical_thinking
                                  .moderate_risks_percentage,
                              color: 'bg-success'
                            },
                            {
                              title: 'Significant Risks',
                              percentage:
                                hvpReport.summary_of_critical_thinking
                                  .significant_risks_percentage,
                              color: 'bg-warning'
                            }
                          ].map((item, index) => (
                            <div
                              key={index}
                              className={`progress-bar rounded-0 ${item.color}`}
                              role="progressbar"
                              style={{
                                width: `${item.percentage}`,
                                zIndex: 3 - index,
                                height: '30px'
                              }}
                              aria-valuenow={item.percentage}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          ))}
                        </div>

                        <div className="d-flex justify-content-between mt-4">
                          {[
                            {
                              title: 'Strengths',
                              percentage:
                                hvpReport.summary_of_critical_thinking
                                  .strengths_percentage,
                              colorClass: 'bg-primary'
                            },
                            {
                              title: 'Moderate Risks',
                              percentage:
                                hvpReport.summary_of_critical_thinking
                                  .moderate_risks_percentage,
                              colorClass: 'bg-success'
                            },
                            {
                              title: 'Significant Risks',
                              percentage:
                                hvpReport.summary_of_critical_thinking
                                  .significant_risks_percentage,
                              colorClass: 'bg-warning'
                            }
                          ].map((item, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center"
                            >
                              <span
                                className={`d-inline-block rounded-circle ${item.colorClass}`}
                                style={{
                                  width: '15px',
                                  height: '15px',
                                  marginRight: '8px'
                                }}
                              ></span>
                              <span>
                                {item.title}: {item.percentage}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Summary Cards */}
                  {[
                    {
                      title: 'Core Skills Summary',
                      content:
                        hvpReport.summary_of_critical_thinking
                          .core_skills_summary,
                      color: 'text-primary'
                    },
                    {
                      title: 'Approach To Work Summary',
                      content:
                        hvpReport.summary_of_critical_thinking
                          .approach_to_work_summary,
                      color: 'text-info'
                    },
                    {
                      title: 'People Skills Summary',
                      content:
                        hvpReport.summary_of_critical_thinking
                          .people_skills_summary,
                      color: 'text-success'
                    },
                    {
                      title: 'Problem Solving Skills Summary',
                      content:
                        hvpReport.summary_of_critical_thinking
                          .problem_solving_skills_summary,
                      color: 'text-warning'
                    },
                    {
                      title: 'Performance Qualities Summary',
                      content:
                        hvpReport.summary_of_critical_thinking
                          .performance_qualities_summary,
                      color: 'text-secondary'
                    }
                  ].map((item, index) => (
                    <div
                      className="card shadow-sm border-0 flex-grow-1 mb-4"
                      key={index}
                    >
                      <div className="card-body rounded d-flex flex-column justify-content-center align-items-center text-center">
                        {item.title && (
                          <h4
                            className={`card-title mb-2 fw-bold ${item.color}`}
                          >
                            {item.title}
                          </h4>
                        )}
                        <p className="text-black mb-0">{item.content}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </body>
          <footer className="my-4 d-flex w-100 justify-content-center">
            <PDFDownloadButton
              label={'Download PDF'}
              loadingLabel={'Generating PDF...'}
              isLoading={pdfLoading}
              onClick={downloadPDF}
            />
          </footer>
        </>
      )}
    </>
  );
};

export default JobHvpView;
