import React, { useState } from 'react';

const ToggleSwitch = ({ isChecked, handleToggle, disabled }) => {
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input cursor-pointer"
        type="checkbox"
        disabled={disabled}
        checked={isChecked}
        onChange={handleToggle}
      />
    </div>
  );
};

export default ToggleSwitch;
