import React from 'react';

const ChartLabel = ({ title, color }) => {
  return (
    <span className="d-flex align-items-center me-2">
      <svg
        className="me-1"
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 13 13"
      >
        <rect width="13" height="13" rx="6.5" fill={color} />
      </svg>
      {title}
    </span>
  );
};

export default ChartLabel;
