import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';

const Rating = ({
  skill,
  rating,
  index,
  onRatingChange,
  disableEvents = false
}) => {
  const ratingLabels = [
    'BEGINNER',
    'BASIC',
    'INTERMEDIATE',
    'ADVANCED',
    'EXPERT'
  ];
  const currentRating = ratingLabels.indexOf(rating) + 1;
  const [hoveredRating, setHoveredRating] = useState(0);

  const capitalizeFirstLetter = (skill) => {
    if (!skill) {
      return '';
    }
    return skill.charAt(0).toUpperCase() + skill.slice(1);
  };

  const handleRatingChange = (newRating) => {
    onRatingChange(ratingLabels[newRating - 1]);
  };

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ width: '100%' }}
    >
      <h5>
        {index}. {capitalizeFirstLetter(skill)}
      </h5>
      <div className="d-flex ms-md-0 ms-2">
        {[...Array(5)].map((_, i) => (
          <div
            key={i}
            onMouseEnter={() => !disableEvents && setHoveredRating(i + 1)}
            onMouseLeave={() => !disableEvents && setHoveredRating(0)}
            onClick={() => !disableEvents && handleRatingChange(i + 1)}
            className="me-1"
          >
            <FaStar
              size={24}
              className={
                i < (hoveredRating || currentRating)
                  ? 'text-warning'
                  : 'text-secondary'
              }
              style={{ cursor: disableEvents ? 'default' : 'pointer' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Rating;
