import { useState } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import './styles.css';

const TRIGGER_OPTIONS = {
  candidate: [
    { key: 'applicationSubmission', display: 'Application Submitted' },
    { key: 'assessmentCompletion', display: 'Assessments Completed' },
    { key: 'assessmentFailure', display: 'Assessment Failed' }
  ],
  admin: [
    { key: 'newJobPosting', display: 'New Job Posted' },
    { key: 'jobStatusUpdate', display: 'Job Status Updated' }
  ]
};

export function NotificationTriggerForm({
  slackDetails,
  setSlackDetails,
  onSubmit
}) {
  const [notificationFor, setNotificationFor] = useState('candidate');
  const [trigger, setTrigger] = useState('');
  const [type, setType] = useState('webhook');
  const [slackDestination, setSlackDestination] = useState('public');
  const [slackChannel, setSlackChannel] = useState('');
  const [webhookConfig, setWebhookConfig] = useState({
    url: '',
    method: 'POST',
    headers: '',
    authType: 'none',
    authToken: ''
  });

  const handleAddChannel = async (e) => {
    e.preventDefault();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const orgId = userDetails?.user?.organizationId;
    if (!orgId) {
      return;
    }

    try {
      const channelData = {
        channelId: slackChannel.id,
        channelName: slackChannel.name,
        notificationFor: notificationFor,
        channelType: slackDestination,
        trigger: trigger,
        message: 'New notification received',
        organizationId: orgId
      };

      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/integration/slack/channel-integration`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(channelData)
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to add channel');
      }

      setSlackDetails((prev) => ({
        ...prev,
        selectedChannels: [...prev.selectedChannels, data.channelIntegration]
      }));
    } catch (error) {
      console.error('Error adding channel:', error);
      // You might want to show an error message to the user
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      id: Math.random().toString(36).substr(2, 9),
      for: notificationFor,
      trigger,
      type,
      ...(type === 'slack' && {
        slackDestination,
        slackChannel
      })
    });
    resetForm();
  };

  const resetForm = () => {
    setNotificationFor('candidate');
    setTrigger('');
    setType('webhook');
    setSlackDestination('public');
    setSlackChannel('');
  };

  const handleWebhookChange = (field, value) => {
    setWebhookConfig((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Form onSubmit={handleAddChannel} className="notification-form">
      <div className="form-section">
        <div className="form-section-header">
          <i className="fas fa-users"></i>
          <h5>Target Audience</h5>
        </div>
        <Form.Group className="custom-form-group">
          <Form.Label>Notification For</Form.Label>
          <div className="radio-group">
            <div
              className={`radio-option ${notificationFor === 'candidate' ? 'active' : ''}`}
              onClick={() => setNotificationFor('candidate')}
            >
              <i className="fas fa-user"></i>
              <span>Candidate</span>
            </div>
            <div
              className={`radio-option ${notificationFor === 'admin' ? 'active' : ''}`}
              onClick={() => setNotificationFor('admin')}
            >
              <i className="fas fa-user-shield"></i>
              <span>Admin</span>
            </div>
          </div>
        </Form.Group>
      </div>

      <div className="form-section">
        <div className="form-section-header">
          <i className="fas fa-bell"></i>
          <h5>Event Selection</h5>
        </div>
        <Form.Group className="custom-form-group">
          <Form.Label>Select Trigger</Form.Label>
          <div className="trigger-options">
            {TRIGGER_OPTIONS[notificationFor].map((option) => (
              <div
                key={option.key}
                className={`trigger-option ${trigger === option.key ? 'active' : ''}`}
                onClick={() => setTrigger(option.key)}
              >
                <i className="fas fa-bell"></i>
                <span>{option.display}</span>
              </div>
            ))}
          </div>
        </Form.Group>
      </div>

      <div className="form-section">
        <div className="form-section-header">
          <i className="fas fa-paper-plane"></i>
          <h5>Notification Channel</h5>
        </div>
        <Form.Group className="custom-form-group">
          <div className="delivery-options">
            <div
              className={`delivery-option ${type === 'webhook' ? 'active' : ''}`}
              onClick={() => setType('webhook')}
            >
              <i className="fas fa-code"></i>
              <div className="delivery-option-content">
                <span className="delivery-title">Webhook</span>
                <span className="delivery-desc">Send to external endpoint</span>
              </div>
            </div>
            <div
              className={`delivery-option ${type === 'slack' ? 'active' : ''}`}
              onClick={() => setType('slack')}
            >
              <i className="fab fa-slack"></i>
              <div className="delivery-option-content">
                <span className="delivery-title">Slack</span>
                <span className="delivery-desc">Send to Slack channel</span>
              </div>
            </div>
          </div>
        </Form.Group>
      </div>

      {type === 'webhook' && (
        <div className="form-section">
          <div className="form-section-header">
            <i className="fas fa-code"></i>
            <h5>Webhook Configuration</h5>
          </div>

          <Form.Group className="custom-form-group">
            <Form.Label>Webhook URL</Form.Label>
            <Form.Control
              type="url"
              placeholder="https://api.example.com/webhook"
              value={webhookConfig.url}
              onChange={(e) => handleWebhookChange('url', e.target.value)}
              className="form-input"
            />
          </Form.Group>

          <Form.Group className="custom-form-group">
            <Form.Label>HTTP Method</Form.Label>
            <div className="method-options">
              {['POST', 'PUT', 'PATCH'].map((method) => (
                <div
                  key={method}
                  className={`method-option ${webhookConfig.method === method ? 'active' : ''}`}
                  onClick={() => handleWebhookChange('method', method)}
                >
                  {method}
                </div>
              ))}
            </div>
          </Form.Group>

          <Form.Group className="custom-form-group">
            <Form.Label>Authentication</Form.Label>
            <div className="auth-options">
              <div
                className={`auth-option ${webhookConfig.authType === 'none' ? 'active' : ''}`}
                onClick={() => handleWebhookChange('authType', 'none')}
              >
                <i className="fas fa-unlock"></i>
                <span>None</span>
              </div>
              <div
                className={`auth-option ${webhookConfig.authType === 'bearer' ? 'active' : ''}`}
                onClick={() => handleWebhookChange('authType', 'bearer')}
              >
                <i className="fas fa-key"></i>
                <span>Bearer Token</span>
              </div>
            </div>
          </Form.Group>

          {webhookConfig.authType === 'bearer' && (
            <Form.Group className="custom-form-group">
              <Form.Label>Auth Token</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your authentication token"
                value={webhookConfig.authToken}
                onChange={(e) =>
                  handleWebhookChange('authToken', e.target.value)
                }
                className="form-input"
              />
            </Form.Group>
          )}

          <Form.Group className="custom-form-group">
            <Form.Label>Custom Headers</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter headers in JSON format"
              value={webhookConfig.headers}
              onChange={(e) => handleWebhookChange('headers', e.target.value)}
              className="form-textarea"
            />
            <Form.Text className="text-muted">
              Example:{' '}
              {
                "{'Content-Type': 'application/json', 'X-Custom-Header': 'value'}"
              }
            </Form.Text>
          </Form.Group>
        </div>
      )}

      {type === 'slack' && (
        <div className="form-section">
          <div className="form-section-header">
            <i className="fab fa-slack"></i>
            <h5>Slack Configuration</h5>
          </div>
          <Form.Group className="custom-form-group">
            <Form.Label>Channel Type</Form.Label>
            <div className="slack-destination-options">
              <div
                className={`slack-destination ${slackDestination === 'public' ? 'active' : ''}`}
                onClick={() => setSlackDestination('public')}
              >
                <i className="fas fa-hashtag"></i>
                <span>Public</span>
              </div>
              <div
                className={`slack-destination ${slackDestination === 'private' ? 'active' : ''}`}
                onClick={() => setSlackDestination('private')}
              >
                <i className="fas fa-lock"></i>
                <span>Private</span>
              </div>
              <div
                className={`slack-destination ${slackDestination === 'direct' ? 'active' : ''}`}
                onClick={() => setSlackDestination('direct')}
              >
                <i className="fas fa-at"></i>
                <span>Direct</span>
              </div>
            </div>
          </Form.Group>

          <Form.Group className="custom-form-group">
            <Form.Label>Destination Channel</Form.Label>
            <Dropdown className="channel-dropdown">
              <Dropdown.Toggle
                className={`channel-dropdown-toggle ${slackChannel?.name ? 'has-value' : ''}`}
              >
                <i className="fas fa-hashtag channel-prefix"></i>
                <span>{slackChannel?.name || 'Choose a channel'}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="channel-dropdown-menu">
                {slackDestination === 'direct'
                  ? slackDetails.users.map((user) => (
                      <Dropdown.Item
                        key={user.id}
                        onClick={() => setSlackChannel(user)}
                        className="channel-dropdown-item"
                        active={user.name === slackChannel?.name}
                      >
                        <i className={`fas fa-${user.icon}`}></i>
                        <span>{user.name}</span>
                      </Dropdown.Item>
                    ))
                  : slackDetails.channels.map((channel) => {
                      if (
                        (slackDestination === 'public' &&
                          !channel.is_private) ||
                        (slackDestination === 'private' && channel.is_private)
                      ) {
                        return (
                          <Dropdown.Item
                            key={channel.id}
                            onClick={() => setSlackChannel(channel)}
                            className="channel-dropdown-item"
                            active={channel.name === slackChannel?.name}
                          >
                            <i className={`fas fa-${channel.icon}`}></i>
                            <span>{channel.name}</span>
                          </Dropdown.Item>
                        );
                      }
                    })}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </div>
      )}

      <button type="submit" className="submit-button">
        <i className="fas fa-plus-circle"></i>
        Create Notification Trigger
      </button>
    </Form>
  );
}
