import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const AssistantCustomization = () => {
  const [showAssistant, setShowAssistant] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const fetchOrganizationDetails = async () => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const orgId = userDetails?.user?.organizationId;
    const accessToken = userDetails?.session.accessToken || null;

    if (!accessToken || !orgId) {
      console.error('Access token or organization ID not found');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch organization details');
      }

      const data = await response.json();
      setShowAssistant(data?.data?.organization?.showAssistant);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const updateAssistantStatus = async (status) => {
    setShowAssistant(status); // Update UI immediately
    setUpdating(true);

    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const orgId = userDetails?.user?.organizationId;
    const accessToken = userDetails?.session.accessToken || null;

    if (!accessToken || !orgId) {
      console.error('Access token or organization ID not found');
      setUpdating(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify({ showAssistant: status })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update assistant status');
      }

      const { message } = await response.json();
      toast.success(message);
    } catch (error) {
      console.error(error.message);
      toast.error('Failed to update assistant status');
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    fetchOrganizationDetails();
  }, []);

  return (
    <div className="theme-customization">
      <div className="d-flex align-items-center mb-4">
        <h3 className="mb-0 me-auto">Assistant Customization</h3>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-header-title">
                Customize Assistant Visibility
              </h5>
            </div>
            <div className="card-body">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <h5 className=" mb-0 mr-4">Assistant Status :</h5>
                  {/* Custom Toggle Switch */}
                  <label className="switch mb-0">
                    <input
                      type="checkbox"
                      checked={showAssistant}
                      onChange={() => updateAssistantStatus(!showAssistant)}
                      disabled={updating}
                    />
                    <span className="slider round"></span>
                  </label>

                  {/* <label className="form-label mb-0 ms-2">
                    {showAssistant ? "Enabled" : "Disabled"}
                  </label> */}

                  <span
                    className={`ms-2 badge badge-lg light badge-flex badge-${
                      showAssistant ? 'success' : 'danger'
                    }`}
                  >
                    {showAssistant ? 'Enabled' : 'Disabled'}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <style>{`
       .switch {
  position: relative;
  display: inline-block;
  width: 50px; /* Adjust width to your preference */
  height: 25px; /* Adjust height to your preference */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 25px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px; /* Adjust height for better visibility */
  width: 20px; /* Adjust width for better visibility */
  border-radius: 50%;
  left: 2px; /* Adjust for better centering */
  bottom: 3px; /* Adjust for better centering */
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(25px); /* Adjust based on the width of the slider */
}

      `}</style>
    </div>
  );
};

export default AssistantCustomization;
