import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import JobApplicationBlog from '../Jobick/Jobs/JobApplicationBlog';
import axios from 'axios';
import Search from '../Jobick/Jobs/ui/search';
import { getItemsPerPage } from '../../utils/CommonFunctions';
import {
  FaFilter,
  FaList,
  FaCalendar,
  FaCalendarAlt,
  FaSearch,
  FaTimes
} from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const JobApplication = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // State variables
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedJobId, setSelectedJobId] = useState(
    searchParams.get('jobId') || jobId || ''
  );
  const [jobList, setJobList] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get('page')) || 1
  );
  const [totalEntries, setTotalEntries] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [status, setStatus] = useState(searchParams.get('status') || '');
  const [loading, setLoading] = useState('');
  const [searchVal, setSearchVal] = useState(searchParams.get('search') || '');
  const [startDate, setStartDate] = useState(
    searchParams.get('startDate')
      ? new Date(searchParams.get('startDate'))
      : null
  );
  const [endDate, setEndDate] = useState(
    searchParams.get('endDate') ? new Date(searchParams.get('endDate')) : null
  );
  const [isLoading, setIsLoading] = useState(false);
  const abortControllerRef = useRef(null);

  // Get user details and organization ID from local storage
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const orgId = userDetails?.user?.organizationId;
  const accessToken = userDetails?.session.accessToken || null;

  // Add new state for items per page
  const [itemsPerPage, setItemsPerPage] = useState(getItemsPerPage());

  // Effect for handling mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Effect for fetching job list
  useEffect(() => {
    fetchJobList();
  }, []);

  // Effect for fetching application data when dependencies change
  useEffect(() => {
    if (selectedJobId) {
      fetchApplicationData(selectedJobId, currentPage);
    }
  }, [selectedJobId, searchVal, status, startDate, endDate, currentPage]);

  // Add resize observer effect to update items per page
  useEffect(() => {
    const updateItemsPerPage = () => {
      // Get the table container height
      const tableContainer = document.querySelector('.table-responsive');
      if (!tableContainer) {
        return;
      }

      // Calculate available height for table body
      const containerHeight = tableContainer.clientHeight;
      const headerHeight = 56; // Typical header height, adjust if different
      const rowHeight = 73; // Typical row height, adjust if different

      // Calculate how many rows can fit
      const possibleRows = Math.floor(
        (containerHeight - headerHeight) / rowHeight
      );

      // Set minimum of 1 row and maximum of calculated rows
      const newItemsPerPage = Math.max(1, possibleRows);

      // Update items per page if different
      if (newItemsPerPage !== itemsPerPage) {
        setItemsPerPage(newItemsPerPage);
        // Refetch data with new limit if necessary
        if (selectedJobId) {
          fetchApplicationData(selectedJobId, 1);
        }
      }
    };

    // Create resize observer
    const resizeObserver = new ResizeObserver(updateItemsPerPage);

    // Observe the table container
    const tableContainer = document.querySelector('.table-responsive');
    if (tableContainer) {
      resizeObserver.observe(tableContainer);
    }

    // Initial calculation
    updateItemsPerPage();

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [selectedJobId]);

  // Function to fetch job list
  const fetchJobList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/applications?limit=7`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      setJobList(response.data.jobList);
      if (response.data.jobList.length > 0 && !selectedJobId) {
        setSelectedJobId(response.data.jobList[0]._id);
      }
    } catch (error) {
      console.error('Error fetching job list:', error);
    }
  };

  // Function to format date for API
  const formatDateForAPI = (date) => {
    if (!date) {
      return null;
    }

    try {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    } catch (error) {
      console.error('Error formatting date:', error);
      return null;
    }
  };

  // Function to fetch application data
  const fetchApplicationData = useCallback(
    async (jobId, page = currentPage) => {
      // Cancel any ongoing request
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create new AbortController for this request
      abortControllerRef.current = new AbortController();

      try {
        setIsLoading(true);
        setLoading(1);

        let queryString = `?limit=${itemsPerPage}&jobId=${jobId}&page=${page}`;

        if (status) {
          queryString += `&status=${status}`;
        }
        if (searchVal) {
          queryString += `&search=${searchVal}`;
        }
        if (startDate) {
          queryString += `&startDate=${formatDateForAPI(startDate)}`;
        }
        if (endDate) {
          queryString += `&endDate=${formatDateForAPI(endDate)}`;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_DEV_API}/v1/organization/${orgId}/applications${queryString}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`
            },
            signal: abortControllerRef.current.signal
          }
        );

        // Only update state if this is still the current request
        if (abortControllerRef.current) {
          setLoading(0);
          setApplicationData(response.data.data);
          setTotalEntries(response.data.total);
          setTotalPages(response.data.totalPages);
          setCurrentPage(response.data.currentPage);
        }
      } catch (error) {
        // Only handle error if it's not an abort error
        if (!axios.isCancel(error)) {
          setLoading(2);
          navigate('/page-error-404');
          console.error('Error fetching application data:', error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      orgId,
      accessToken,
      status,
      searchVal,
      startDate,
      endDate,
      navigate,
      currentPage,
      itemsPerPage
    ]
  );

  // Function to update URL params
  const updateURLParams = useCallback(
    (updates) => {
      const newParams = new URLSearchParams(searchParams);

      Object.entries(updates).forEach(([key, value]) => {
        if (value) {
          newParams.set(key, value);
        } else {
          newParams.delete(key);
        }
      });

      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  // Update handlers to sync with URL
  const handleJobSelection = (jobId) => {
    if (jobId !== selectedJobId) {
      setSelectedJobId(jobId);
      setApplicationData([]);
      updateURLParams({
        jobId,
        page: 1 // Reset page
      });
      fetchApplicationData(jobId, 1); // Pass page 1 explicitly
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage !== currentPage && !isLoading) {
      setCurrentPage(newPage);
      updateURLParams({ page: newPage });
      fetchApplicationData(selectedJobId, newPage);
    }
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setCurrentPage(1); // Reset page state
    updateURLParams({
      status: newStatus,
      page: 1 // Reset page in URL
    });
  };

  const handleSearchChange = (newSearch) => {
    setSearchVal(newSearch);
    setCurrentPage(1); // Reset page state
    updateURLParams({
      search: newSearch,
      page: 1 // Reset page in URL
    });
  };

  const handleDateRangeChange = (dates) => {
    const [start, end] = dates;

    // Update states
    setStartDate(start);
    setEndDate(end);
    setCurrentPage(1); // Reset page state

    // Update URL params
    updateURLParams({
      startDate: start ? formatDateForAPI(start) : null,
      endDate: end ? formatDateForAPI(end) : null,
      page: 1 // Reset page in URL
    });
  };

  // Reset all filters
  const handleResetFilters = () => {
    setStatus('');
    setStartDate(null);
    setEndDate(null);
    setCurrentPage(1); // Reset page state
    updateURLParams({
      status: null,
      startDate: null,
      endDate: null,
      page: 1 // Reset page in URL
    });
  };

  // Function to get status label
  const getStatusLabel = (value) => {
    const option = statusOptions.find((opt) => opt.value === value);
    return option ? option.label : 'Select Status';
  };

  // Status options for dropdown
  const statusOptions = [
    { value: '', label: 'Default' },
    { value: 'APPLICATION_SUBMITTED', label: 'Application Submitted' },
    { value: 'PROFILE_REVIEW_COMPLETED', label: 'Profile Review Completed' },
    { value: 'AI_REVIEW_COMPLETED', label: 'AI Review Completed' },
    { value: 'ASSESSMENTS_IN_PROGRESS', label: 'Assessments in Progress' },
    { value: 'ASSESSMENTS_COMPLETED', label: 'Assessments Completed' },
    { value: 'INTERVIEW_PROCESS_STARTED', label: 'Interview Process Started' },
    { value: 'INTERVIEWS_COMPLETED', label: 'Interviews Completed' },
    { value: 'CANDIDATE_WITHDRAWN', label: 'Candidate Withdrawn' },
    { value: 'REJECTED', label: 'Rejected' },
    { value: 'OFFER_EXTENDED', label: 'Offer Extended' },
    { value: 'HIRED', label: 'Hired' }
  ];

  const selectedJob = jobList.find((job) => job._id === selectedJobId);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  return (
    <>
      <style>
        {`
          .custom-dropdown-menu {
            transform: translate3d(0px, 30px, 0px) !important;
            min-width: 100% !important;
            max-height: 200px;
            overflow-y: auto;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            padding: 0;
            margin: 0;
          }
          .custom-dropdown-menu::-webkit-scrollbar {
            width: 8px;
          }
          .custom-dropdown-menu::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
          .custom-dropdown-menu::-webkit-scrollbar-thumb {
            background: #888;
          }
          .custom-dropdown-menu::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
          .custom-dropdown-toggle {
            width: 100% !important;
          }
          .dropdown-item.active,
          .dropdown-item:active {
            background-color: #f8f9fa;
          }
          .date-picker-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .date-picker-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: space-between;
          }
          .date-picker {
            flex: 1;
            padding: 0.4rem 0.75rem;
            border: 1px solid #ced4da;
            border-radius: 4px;
            background-color: #fff;
            box-shadow: none;
            width: 18rem;
          }
          .react-datepicker__input-container {
            display: flex;
            align-items: center;
          }
          .custom-dropdown-item:hover {
            background-color: #e3f2fd !important;
            color: #0d6efd; 
            font-weight: 500;
            cursor: pointer;
            transition: background-color 0.3s, color 0.3s;
          }

          /* Advanced Filter Modal Styles */
          .filter-popup {
            padding: 0;
            min-width: 380px;
            background: #fff;
            border-radius: 12px;
            box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12) !important;
            border: none;
          }

          .filter-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.25rem 1.5rem;
            border-bottom: 1px solid #f0f0f0;
            background: #fafafa;
            border-radius: 12px 12px 0 0;
          }

          .filter-header h4 {
            font-size: 1.1rem;
            font-weight: 600;
            color: #1a1f36;
            margin: 0;
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }

          .filter-header h4 svg {
            color: #4885ed;
            display: none;
          }

          .filter-content {
            padding: 1.5rem;
          }

          .filter-section {
            margin-bottom: 1.75rem;
            position: relative;
          }

          .filter-section:last-child {
            margin-bottom: 0;
          }

          .filter-label {
            font-size: 0.875rem;
            font-weight: 500;
            color: #1a1f36;
            margin-bottom: 0.5rem;
            display: flex;
            align-items: center;
          }

          .filter-label svg {
            color: #4885ed;
            font-size: 0.75rem;
            display: none;
          }

          .status-filter-select {
            display: flex !important;
            align-items: center;
            justify-content: space-between;
            white-space: nowrap;
            overflow: visible !important;
            text-overflow: ellipsis;
            position: relative;
            margin-left: 0px !important;
            background-color: #fff;
            border: 1px solid #e5e7eb;
            border-radius: 8px;
            padding: 0.75rem 1rem;
            font-size: 0.9rem;
            transition: all 0.2s ease;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
            width: 100%;
            color: #374151;
            cursor: pointer;
          }

          .status-filter-select:hover,
          .status-filter-select:focus {
            border-color: #4885ed;
            box-shadow: 0 0 0 3px rgba(72, 133, 237, 0.1);
          }

          .status-filter-select::after {
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
          }

          .status-filter-select .selected-value {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 1.5rem;
          }

          .show .status-filter-select {
            border-color: #4885ed;
            box-shadow: 0 0 0 3px rgba(72, 133, 237, 0.1);
          }

          .status-dropdown-menu {
            max-height: 280px;
            overflow-y: auto;
            transform: none !important;
            position: fixed !important;
            inset: auto !important;
            margin-top: 4px !important;
            border: 1px solid #e5e7eb;
            border-radius: 8px;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
            padding: 0.5rem 0;
          }

          .custom-dropdown-item {
            padding: 0.75rem 1rem;
            font-size: 0.9rem;
            color: #374151;
            transition: all 0.2s ease;
          }

          .custom-dropdown-item:hover {
            background-color: #f3f4f6;
            color: #4885ed;
          }

          .custom-dropdown-item.active {
            background-color: #e8f0fe;
            color: #4885ed;
            font-weight: 500;
          }

          /* Ensure proper z-indexing */
          .dropdown {
            position: relative;
          }

          .status-dropdown-menu {
            z-index: 1060 !important;
          }

          /* Custom scrollbar for dropdown */
          .status-dropdown-menu::-webkit-scrollbar {
            width: 6px;
          }

          .status-dropdown-menu::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 3px;
          }

          .status-dropdown-menu::-webkit-scrollbar-thumb {
            background: #c1c1c1;
            border-radius: 3px;
          }

          .status-dropdown-menu::-webkit-scrollbar-thumb:hover {
            background: #a8a8a8;
          }

          .date-picker-container {
            display: grid;
            gap: 1rem;
          }

          .date-picker {
            background-color: #fff;
            border: 1px solid #e5e7eb;
            border-radius: 8px;
            padding: 0.75rem 1rem;
            font-size: 0.9rem;
            transition: all 0.2s ease;
            width: 100%;
            color: #374151;
            cursor: pointer;
          }

          .date-picker:hover {
            border-color: #4885ed;
            box-shadow: 0 0 0 3px rgba(72, 133, 237, 0.1);
          }

          .date-picker:focus {
            outline: none;
            border-color: #4885ed;
            box-shadow: 0 0 0 3px rgba(72, 133, 237, 0.1);
          }

          .filter-footer {
            padding: 1.25rem 1.5rem;
            border-top: 1px solid #f0f0f0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #fafafa;
            border-radius: 0 0 12px 12px;
          }

          .filter-badge {
            display: inline-flex;
            align-items: center;
            background: #e8f0fe;
            color: #4885ed;
            padding: 0.25rem 0.75rem;
            border-radius: 16px;
            font-size: 0.75rem;
            font-weight: 500;
            margin-left: 0.5rem;
          }

          .btn-reset {
            color: #6b7280;
            font-size: 0.875rem;
            font-weight: 500;
            background: none;
            border: none;
            padding: 0.5rem 1rem;
            cursor: pointer;
            transition: all 0.2s ease;
          }

          .btn-reset:hover {
            color: #4b5563;
          }

          .btn-apply {
            background: #4885ed;
            color: white;
            border: none;
            padding: 0.5rem 1.25rem;
            border-radius: 6px;
            font-weight: 500;
            font-size: 0.875rem;
            cursor: pointer;
            transition: all 0.2s ease;
          }

          .btn-apply:hover {
            background: #3b7de3;
            transform: translateY(-1px);
          }

          .custom-dropdown-item {
            padding: 0.75rem 1rem;
            font-size: 0.9rem;
            color: #374151;
            transition: all 0.2s ease;
          }

          .custom-dropdown-item:hover {
            background-color: #f3f4f6;
            color: #4885ed;
          }

          .custom-dropdown-item.active {
            background-color: #e8f0fe;
            color: #4885ed;
            font-weight: 500;
          }

          /* Date Range Picker Custom Styles */
          .date-range-group {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
          }

          .date-input-wrapper {
            position: relative;
          }

          .date-input-wrapper svg {
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            color: #9ca3af;
            pointer-events: none;
          }

          .react-datepicker-wrapper {
            width: 100%;
          }

          .react-datepicker {
            font-family: inherit;
            border-radius: 8px;
            border: 1px solid #e5e7eb;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
          }

          .date-range-single {
            position: relative;
            width: 100%;
          }

          .date-range-single .date-picker {
            background-color: #fff;
            border: 1px solid #e5e7eb;
            border-radius: 8px;
            padding: 0.75rem 1rem;
            padding-right: 2.5rem;
            font-size: 0.9rem;
            transition: all 0.2s ease;
            width: 100%;
            color: #374151;
            cursor: pointer;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
          }

          .date-range-single .date-picker:hover {
            border-color: #4885ed;
            box-shadow: 0 0 0 3px rgba(72, 133, 237, 0.1);
          }

          .date-range-single .date-picker:focus {
            outline: none;
            border-color: #4885ed;
            box-shadow: 0 0 0 3px rgba(72, 133, 237, 0.1);
          }

          .date-range-single .calendar-icon {
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            color: #9ca3af;
            pointer-events: none;
          }

          /* Custom DatePicker Styles */
          .react-datepicker {
            font-family: inherit;
            border: 1px solid #e5e7eb;
            border-radius: 8px;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
          }

          .react-datepicker__header {
            background-color: #f9fafb;
            border-bottom: 1px solid #e5e7eb;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            padding-top: 8px;
          }

          .react-datepicker__current-month {
            font-weight: 600;
            font-size: 0.95rem;
            color: #1f2937;
          }

          .react-datepicker__day {
            margin: 0.2rem;
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
            border-radius: 50%;
            transition: all 0.2s ease;
          }

          .react-datepicker__day:hover {
            background-color: #e8f0fe;
            color: #4885ed;
          }

          .react-datepicker__day--selected,
          .react-datepicker__day--in-range {
            background-color: #4885ed !important;
            color: white !important;
          }

          .react-datepicker__day--in-selecting-range {
            background-color: rgba(72, 133, 237, 0.2);
          }

          .react-datepicker__day--keyboard-selected {
            background-color: #e8f0fe;
            color: #4885ed;
          }

          .react-datepicker__navigation {
            top: 8px;
          }

          .react-datepicker__navigation-icon::before {
            border-color: #6b7280;
          }

          .react-datepicker__day-name {
            color: #6b7280;
            font-weight: 500;
          }

          .react-datepicker__triangle {
            display: none;
          }

          /* Clear button styles */
          .react-datepicker__close-icon {
            padding: 0;
            right: 2.5rem;
          }

          .react-datepicker__close-icon::after {
            background-color: transparent;
            color: #9ca3af;
            font-size: 1.5rem;
            padding: 0;
            transition: color 0.2s ease;
          }

          .react-datepicker__close-icon:hover::after {
            color: #4b5563;
          }

          /* Fix for dropdown visibility */
          .status-dropdown-menu {
            max-height: 280px;
            overflow-y: auto;
            transform: none !important;
            position: fixed !important;
            inset: auto !important;
            margin-top: 4px !important;
          }

          .status-dropdown-menu::-webkit-scrollbar {
            width: 6px;
          }

          .status-dropdown-menu::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 3px;
          }

          .status-dropdown-menu::-webkit-scrollbar-thumb {
            background: #c1c1c1;
            border-radius: 3px;
          }

          .status-dropdown-menu::-webkit-scrollbar-thumb:hover {
            background: #a8a8a8;
          }

          /* Fix for selected value display */
          .status-filter-select {
            display: flex !important;
            align-items: center;
            justify-content: space-between;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .status-filter-select::after {
            margin-left: 8px;
          }

          .dropdown-toggle.status-filter-select::after {
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
          }

          /* Ensure the filter popup has proper z-index */
          .filter-popup {
            z-index: 1050 !important;
          }

          /* Ensure dropdown menu has higher z-index */
          .status-dropdown-menu {
            z-index: 1060 !important;
          }

          /* Fix for date picker popup */
          .date-picker-popper {
            z-index: 1060 !important;
          }

          .react-datepicker-popper {
            z-index: 1060 !important;
          }

          /* Ensure the calendar popup is visible */
          .react-datepicker-wrapper {
            position: static !important;
          }

          .react-datepicker-popper[data-placement^="bottom"] {
            padding-top: 4px;
          }

          .react-datepicker-popper[data-placement^="top"] {
            padding-bottom: 4px;
          }

          .search-wrapper {
            position: relative;
            width: 300px;
          }

          .search-input-wrapper {
            position: relative;
            width: 100%;
          }

          .search-input {
            width: 100%;
            padding: 0.75rem 1rem;
            padding-left: 2.75rem;
            padding-right: ${searchVal ? '2.5rem' : '1rem'};
            font-size: 0.9rem;
            color: #374151;
            background-color: #fff;
            border: 1px solid #e5e7eb;
            border-radius: 8px;
            transition: all 0.2s ease;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
          }

          .search-input:hover {
            border-color: #4885ed;
            box-shadow: 0 0 0 3px rgba(72, 133, 237, 0.1);
          }

          .search-input:focus {
            outline: none;
            border-color: #4885ed;
            box-shadow: 0 0 0 3px rgba(72, 133, 237, 0.1);
          }

          .search-input::placeholder {
            color: #9ca3af;
          }

          .search-icon {
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
            color: #9ca3af;
            pointer-events: none;
            transition: color 0.2s ease;
          }

          .search-input:focus + .search-icon {
            color: #4885ed;
          }

          .clear-button {
            position: absolute;
            right: 0.75rem;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            border: none;
            padding: 0.25rem;
            color: #9ca3af;
            cursor: pointer;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease;
          }

          .clear-button:hover {
            color: #4b5563;
            background-color: #f3f4f6;
          }

          /* Optional: Add animation for clear button */
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(-50%) scale(0.8);
            }
            to {
              opacity: 1;
              transform: translateY(-50%) scale(1);
            }
          }

          .clear-button {
            animation: fadeIn 0.2s ease;
          }

          /* Optional: Add responsive styles */
          @media (max-width: 640px) {
            .search-wrapper {
              width: 100%;
            }
          }

          /* Optional: Add dark mode support */
          @media (prefers-color-scheme: dark) {
            .search-input {
              background-color: #1f2937;
              border-color: #374151;
              color: #e5e7eb;
            }

            .search-input::placeholder {
              color: #6b7280;
            }

            .search-input:hover {
              border-color: #4885ed;
            }

            .clear-button:hover {
              background-color: #374151;
              color: #e5e7eb;
            }
          }

          /* Optional: Add focus-visible support for better keyboard navigation */
          .search-input:focus-visible {
            outline: 2px solid #4885ed;
            outline-offset: 1px;
          }

          /* Optional: Add loading state styles */
          .search-wrapper.is-loading .search-icon {
            animation: spin 1s linear infinite;
          }

          @keyframes spin {
            from {
              transform: translateY(-50%) rotate(0deg);
            }
            to {
              transform: translateY(-50%) rotate(360deg);
            }
          }

          .modern-search-wrapper {
            position: relative;
            width: 320px;
          }

          .modern-search-container {
            position: relative;
            background: #F8FAFC;
            border: 1px solid #E2E8F0;
            border-radius: 12px;
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
            padding: 4px;
          }

          .modern-search-container:hover {
            background: #fff;
            border-color: #CBD5E1;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
          }

          .modern-search-container:focus-within {
            background: #fff;
            border-color: #4885ed;
            box-shadow: 0 0 0 3px rgba(72, 133, 237, 0.12);
          }

          .search-icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 12px;
            color: #64748B;
            transition: color 0.2s ease;
          }

          .modern-search-container:focus-within .search-icon-wrapper {
            color: #4885ed;
          }

          .modern-search-input {
            flex: 1;
            border: none;
            background: transparent;
            padding: 8px 8px;
            font-size: 0.925rem;
            color: #1E293B;
            min-width: 0;
            font-weight: 400;
          }

          .modern-search-input::placeholder {
            color: #94A3B8;
            font-weight: 400;
          }

          .modern-search-input:focus {
            outline: none;
          }

          .modern-clear-button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px;
            margin-right: 4px;
            border: none;
            background: #EDF2F7;
            color: #64748B;
            border-radius: 8px;
            cursor: pointer;
            transition: all 0.2s ease;
          }

          .modern-clear-button:hover {
            background: #E2E8F0;
            color: #475569;
          }

          /* Optional: Add a subtle animation for the clear button appearance */
          @keyframes scaleIn {
            from {
              transform: scale(0.9);
              opacity: 0;
            }
            to {
              transform: scale(1);
              opacity: 1;
            }
          }

          .modern-clear-button {
            animation: scaleIn 0.15s ease-out;
          }

          /* Dark mode support */
          @media (prefers-color-scheme: dark) {
            .modern-search-container {
              background: #1E293B;
              border-color: #334155;
            }

            .modern-search-container:hover {
              background: #1E293B;
              border-color: #475569;
            }

            .modern-search-input {
              color: #F1F5F9;
            }

            .modern-search-input::placeholder {
              color: #64748B;
            }

            .modern-clear-button {
              background: #334155;
              color: #94A3B8;
            }

            .modern-clear-button:hover {
              background: #475569;
              color: #E2E8F0;
            }
          }

          /* Responsive adjustments */
          @media (max-width: 640px) {
            .modern-search-wrapper {
              width: 100%;
            }
          }

          /* Modern Job Dropdown Styles */
          .job-dropdown {
            position: relative;
            min-width: 300px;
          }

          .job-dropdown .dropdown-toggle {
            background: #F8FAFC;
            border: 1px solid #E2E8F0;
            border-radius: 14px;
            padding: 14px 20px;
            width: 100%;
            transition: all 0.25s ease;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #334155;
            font-weight: 500;
            font-size: 1.05rem;
            letter-spacing: 0.01em;
          }

          .job-dropdown .dropdown-toggle:hover {
            background: #fff;
            border-color: #93C5FD;
            box-shadow: 0 4px 12px rgba(59, 130, 246, 0.08);
            transform: translateY(-1px);
          }

          .job-dropdown .dropdown-toggle:focus,
          .job-dropdown.show .dropdown-toggle {
            background: #fff;
            border-color: #60A5FA;
            box-shadow: 0 0 0 4px rgba(96, 165, 250, 0.12);
          }

          .job-dropdown .dropdown-menu {
            width: 100%;
            padding: 10px;
            border: none;
            border-radius: 14px;
            box-shadow: 
              0 10px 25px -5px rgba(0, 0, 0, 0.1),
              0 4px 10px -2px rgba(0, 0, 0, 0.05);
            margin-top: 8px;
            max-height: 360px;
            overflow-y: auto;
            background: #fff;
            border: 1px solid #E2E8F0;
          }

          .job-dropdown .dropdown-item {
            padding: 12px 16px;
            border-radius: 10px;
            color: #475569;
            transition: all 0.2s ease;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5;
            white-space: normal;
            margin-bottom: 4px;
          }

          .job-dropdown .dropdown-item:last-child {
            margin-bottom: 0;
          }

          .job-dropdown .dropdown-item:hover {
            background: #F0F9FF;
            color: #0369A1;
            transform: translateX(2px);
          }

          .job-dropdown .dropdown-item.active {
            background: #EFF6FF;
            color: #2563EB;
            font-weight: 500;
            position: relative;
            padding-left: 20px;
          }

          .job-dropdown .dropdown-item.active::before {
            content: "";
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #2563EB;
          }

          /* Custom Scrollbar */
          .job-dropdown .dropdown-menu::-webkit-scrollbar {
            width: 8px;
          }

          .job-dropdown .dropdown-menu::-webkit-scrollbar-track {
            background: #F1F5F9;
            border-radius: 4px;
          }

          .job-dropdown .dropdown-menu::-webkit-scrollbar-thumb {
            background: #CBD5E1;
            border-radius: 4px;
            border: 2px solid #F1F5F9;
          }

          .job-dropdown .dropdown-menu::-webkit-scrollbar-thumb:hover {
            background: #94A3B8;
          }

          /* Selected Job Title Ellipsis */
          .job-title-text {
            max-width: calc(100% - 24px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 2px 0;
            position: relative;
          }

          /* Dropdown Arrow Icon */
          .job-dropdown .dropdown-toggle::after {
            content: "";
            margin-left: 10px;
            width: 10px;
            height: 10px;
            border: none;
            border-right: 2px solid #64748B;
            border-bottom: 2px solid #64748B;
            transform: rotate(45deg);
            transition: transform 0.3s ease;
            position: relative;
            top: -2px;
          }

          .job-dropdown.show .dropdown-toggle::after {
            transform: rotate(-135deg);
            top: 2px;
          }

          /* Empty State */
          .job-dropdown .dropdown-menu:empty::after {
            content: "No jobs available";
            display: block;
            padding: 12px 16px;
            color: #94A3B8;
            font-size: 0.95rem;
            text-align: center;
            font-style: italic;
          }

          /* Optional: Add a subtle label above the dropdown */
          .job-dropdown-label {
            display: block;
            margin-bottom: 6px;
            color: #64748B;
            font-size: 0.9rem;
            font-weight: 500;
          }

          /* Optional: Add a subtle background pattern */
          .job-dropdown .dropdown-toggle {
            position: relative;
            overflow: hidden;
          }

          .job-dropdown .dropdown-toggle::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: 
              linear-gradient(
                135deg, 
                rgba(248, 250, 252, 0.8) 0%,
                rgba(248, 250, 252, 0) 100%
              );
            pointer-events: none;
          }

          /* Header Row Alignment */
          .job-header-row {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            margin-bottom: 2rem;
            padding: 0.5rem 0;
            width: 100%;
            justify-content: space-between;
          }

          /* Left Section */
          .job-header-left {
            flex-shrink: 0;
          }

          /* Right Section */
          .header-right {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            margin-left: auto;
          }

          /* Modern Heading Design */
          .job-job-header-title {
            font-size: 1.75rem;
            font-weight: 600;
            color: #1E293B;
            margin: 0;
            white-space: nowrap;
            position: relative;
            padding-bottom: 10px;
            letter-spacing: -0.02em;
          }

          .job-job-header-title::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 40%;
            height: 3px;
            background: linear-gradient(to right, #3B82F6, #60A5FA);
            border-radius: 2px;
          }

          /* Job Dropdown Container */
          .job-dropdown-container {
            min-width: 300px;
            max-width: 400px;
          }

          /* Search and Filter Container */
          .search-filter-container {
            display: flex;
            align-items: center;
            gap: 0.75rem;
          }

          /* Responsive Design */
          @media (max-width: 1200px) {
            .job-header-row {
              gap: 1rem;
            }
            
            .header-right {
              gap: 1rem;
            }
            
            .job-dropdown-container {
              min-width: 250px;
            }
          }

          /* Mobile breakpoint */
          @media (max-width: 768px) {
            .job-header-row {
              flex-direction: column;
              align-items: stretch;
              gap: 1.5rem;
            }

            .header-right {
              flex-direction: column;
              width: 100%;
              gap: 1rem;
            }

            .job-dropdown-container {
              max-width: 100%;
              width: 100%;
            }

            .search-filter-container {
              width: 100%;
              justify-content: space-between;
            }

            .search-filter-container .search-wrapper {
              flex: 1;
            }

            .job-job-header-title {
              font-size: 1.5rem;
            }
          }

          /* Mobile breakpoint adjustments */
          @media (max-width: 767px) {
            .job-header-row {
              flex-direction: column;
              align-items: center;
              gap: 1.25rem;
              text-align: center;
            }

            .job-header-left {
              width: 100%;
              display: flex;
              justify-content: center;
            }

            .header-right {
              flex-direction: column;
              width: 100%;
              margin-left: 0;
              gap: 1rem;
            }

            .job-dropdown-container {
              width: 100%;
            }

            .search-filter-container {
              width: 100%;
              display: flex;
              justify-content: center;
              gap: 0.75rem;
            }

            .modern-search-wrapper {
              width: auto;
              flex: 1;
              max-width: calc(100% - 60px);
            }

            .job-job-header-title {
              text-align: center;
              width: 100%;
            }

            .job-job-header-title::after {
              left: 50%;
              transform: translateX(-50%);
            }
          }

          /* Ensure layout remains unchanged for tablets and desktops */
          @media (min-width: 768px) {
            .job-header-row {
              flex-direction: row;
              align-items: center;
            }

            .header-right {
              flex-direction: row;
            }

            .job-dropdown-container {
              min-width: 300px;
              max-width: 400px;
            }

            .search-filter-container {
              width: auto;
            }

            .modern-search-wrapper {
              width: 320px;
            }
          }

          /* Rest of your existing dropdown styles... */
        `}
      </style>
      <div className="job-header-row">
        <div className="job-header-left">
          <h3 className="job-job-header-title">Job Applications</h3>
        </div>

        <div className="header-right">
          <div className="job-dropdown-container">
            <Dropdown className="job-dropdown">
              <Dropdown.Toggle as="div" className="d-flex align-items-center">
                <span
                  className={`job-title-text ${!selectedJob ? 'placeholder' : ''}`}
                >
                  {selectedJob ? selectedJob.title : 'Select Job Position'}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {jobList.length > 0
                  ? jobList.map((job) => (
                      <Dropdown.Item
                        key={job._id}
                        className={job._id === selectedJobId ? 'active' : ''}
                        onClick={() => handleJobSelection(job._id)}
                      >
                        {job.title}
                      </Dropdown.Item>
                    ))
                  : null}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="search-filter-container">
            <Search searchVal={searchVal} setSearchVal={handleSearchChange} />
            <Dropdown as="li" className="nav-item notification_dropdown">
              <Dropdown.Toggle
                variant=""
                as="div"
                className="nav-link text-primary justify-content-between align-items-center ai-icon i-false c-pointer"
                role="button"
                data-toggle="dropdown"
              >
                <button className="btn btn-secondary btn-sm">
                  <FaFilter size="13" />
                </button>
              </Dropdown.Toggle>
              <Dropdown.Menu align="right" className="filter-popup">
                <div className="filter-header">
                  <h4>
                    <FaFilter size={14} />
                    Filters
                    {(status || startDate || endDate) && (
                      <span className="filter-badge">
                        {
                          [
                            status && 'Status',
                            startDate && 'Date Range'
                          ].filter(Boolean).length
                        }{' '}
                        Active
                      </span>
                    )}
                  </h4>
                </div>

                <div className="filter-content">
                  {/* Status Filter Section */}
                  <div className="filter-section">
                    <label className="filter-label">
                      <FaList size={12} />
                      Application Status
                    </label>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="custom"
                        id="status-dropdown"
                        className="status-filter-select"
                      >
                        <span className="selected-value">
                          {getStatusLabel(status)}
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="w-100 status-dropdown-menu"
                        popperConfig={{
                          strategy: 'fixed',
                          modifiers: [
                            {
                              name: 'preventOverflow',
                              options: {
                                boundary: 'viewport'
                              }
                            }
                          ]
                        }}
                      >
                        {statusOptions.map((option) => (
                          <Dropdown.Item
                            key={option.value}
                            onClick={() => handleStatusChange(option.value)}
                            className={`custom-dropdown-item ${status === option.value ? 'active' : ''}`}
                          >
                            {option.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  {/* Date Filter Section */}
                  <div className="filter-section">
                    <label className="filter-label">
                      <FaCalendar size={12} />
                      Date Range
                    </label>
                    <div className="date-range-single">
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={handleDateRangeChange}
                        isClearable={true}
                        dateFormat="dd MMM yyyy"
                        maxDate={new Date()}
                        placeholderText="Select date range"
                        className="date-picker"
                        popperProps={{
                          strategy: 'fixed',
                          modifiers: [
                            {
                              name: 'preventOverflow',
                              options: {
                                boundary: 'viewport'
                              }
                            }
                          ]
                        }}
                        popperClassName="date-picker-popper"
                      />
                      <FaCalendarAlt className="calendar-icon" size={14} />
                    </div>
                  </div>
                </div>

                <div className="filter-footer">
                  <button className="btn-reset" onClick={handleResetFilters}>
                    Reset all
                  </button>
                  <button
                    className="btn-apply"
                    onClick={() => {
                      // Apply filters logic here
                      // Close dropdown
                    }}
                  >
                    Apply Filters
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <JobApplicationBlog
            applicationData={applicationData}
            currentPage={currentPage || 1}
            itemsPerPage={itemsPerPage || 10}
            totalEntries={totalEntries}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default JobApplication;
