import React, { useEffect, useRef, useState, useMemo, memo } from 'react';
import {
  HiOutlineArrowLongLeft,
  HiOutlineArrowLongRight,
  HiOutlineArrowLongDown
} from 'react-icons/hi2';

const STEP_WIDTH = 230;
const ALL_POSSIBLE_STEPS = [
  { title: 'Application Submitted', value: 'APPLICATION_SUBMITTED' },
  { title: 'Profile Review Completed', value: 'PROFILE_REVIEW_COMPLETED' },
  { title: 'AI Review Completed', value: 'AI_REVIEW_COMPLETED' },
  { title: 'AI Answers Submitted', value: 'AI_ANSWERS_SUBMITTED' },
  { title: 'Assessment In Progress', value: 'ASSESSMENTS_IN_PROGRESS' },
  { title: 'Assessment Completed', value: 'ASSESSMENTS_COMPLETED' },
  { title: "Interview's Started", value: 'INTERVIEW_PROCESS_STARTED' },
  { title: "Interview's Completed", value: 'INTERVIEWS_COMPLETED' },
  { title: 'Offer Extended', value: 'OFFER_EXTENDED' },
  { title: 'Hired', value: 'HIRED' },
  { title: 'Rejected', value: 'REJECTED' },
  { title: 'Interview Failed', value: 'INTERVIEW_FAILED' },
  { title: 'Candidate Withdrawn', value: 'CANDIDATE_WITHDRAWN' }
];

const INTERRUPTING_STEPS = [
  'REJECTED',
  'INTERVIEW_FAILED',
  'CANDIDATE_WITHDRAWN'
];

// Memoize small components
const DownArrowEnd = memo(({ isRowVisited }) => (
  <div
    style={{ width: STEP_WIDTH, height: '2rem' }}
    className="align-self-end d-flex justify-content-center"
  >
    <HiOutlineArrowLongDown
      size={30}
      color={isRowVisited ? '#4885ED' : '#C8C8C8'}
    />
  </div>
));

const DownArrowStart = memo(({ isRowVisited }) => (
  <div
    style={{ width: STEP_WIDTH, height: '2rem' }}
    className="d-flex justify-content-center"
  >
    <HiOutlineArrowLongDown
      size={30}
      color={isRowVisited ? '#4885ED' : '#C8C8C8'}
    />
  </div>
));

const HorizontalArrow = memo(
  ({ currentStepIndex, colIndex, itemsPerRow, stepsLength, isEvenRow }) => {
    const isLineVisited = currentStepIndex < stepsLength - 1;
    const isShowArrow =
      currentStepIndex !== stepsLength - 1 && colIndex !== itemsPerRow - 1;

    if (!isShowArrow) {
      return null;
    }

    return (
      <div style={{ width: '2rem' }} className="d-flex align-items-center">
        {isEvenRow ? (
          <HiOutlineArrowLongRight
            size={30}
            color={isLineVisited ? '#4885ED' : '#C8C8C8'}
          />
        ) : (
          <HiOutlineArrowLongLeft
            size={30}
            color={isLineVisited ? '#4885ED' : '#C8C8C8'}
          />
        )}
      </div>
    );
  }
);

const Step = memo(
  ({ rowIndex, colIndex, step, isEvenRow, itemsPerRow, lastIndex }) => {
    const currentStepIndex = itemsPerRow * rowIndex + colIndex;
    const isStepVisited = currentStepIndex < lastIndex;
    const isInterruptingStep = INTERRUPTING_STEPS.includes(step.value);

    const formattedDateTime = useMemo(() => {
      const dateObject = new Date(step.changedAt);
      return {
        date: dateObject.toLocaleDateString('en-GB'),
        time: dateObject.toLocaleTimeString('en-GB', {
          hour: '2-digit',
          minute: '2-digit'
        })
      };
    }, [step.changedAt]);

    return (
      <>
        <div
          style={{ width: STEP_WIDTH }}
          className={`p-4 rounded background ${
            isStepVisited
              ? isInterruptingStep
                ? 'bg-danger text-white'
                : 'bg-primary text-white'
              : 'border border-light'
          }`}
        >
          {step.title}
          <div className="d-flex justify-content-between">
            <p
              style={{ color: '#d4e2fa' }}
              className="m-0 p-0 text-start fs-12 "
            >
              {formattedDateTime.date}
            </p>
            <p
              style={{ color: '#d4e2fa' }}
              className="m-0 p-0 text-start fs-12 "
            >
              {formattedDateTime.time}
            </p>
          </div>
        </div>
        <HorizontalArrow
          isEvenRow={isEvenRow}
          colIndex={colIndex}
          currentStepIndex={currentStepIndex}
          itemsPerRow={itemsPerRow}
          stepsLength={lastIndex}
        />
      </>
    );
  }
);

const Rows = memo(
  ({ row, rowIndex, itemsPerRow, effectiveProgressArrayLength, lastIndex }) => {
    const isEven = rowIndex % 2 === 0;
    const isRowVisited = itemsPerRow * (rowIndex + 1) < lastIndex;

    return (
      <>
        <div className={`d-flex ${isEven ? 'flex-row' : 'flex-row-reverse'}`}>
          {row.map((step, j) => (
            <Step
              key={j}
              rowIndex={rowIndex}
              colIndex={j}
              step={step}
              isEvenRow={isEven}
              itemsPerRow={itemsPerRow}
              lastIndex={lastIndex}
            />
          ))}
        </div>
        {rowIndex < effectiveProgressArrayLength - 1 && (
          <div
            className={`d-flex ${isEven ? 'justify-content-end' : 'justify-content-start'}`}
          >
            {isEven ? (
              <DownArrowEnd isRowVisited={isRowVisited} />
            ) : (
              <DownArrowStart isRowVisited={isRowVisited} />
            )}
          </div>
        )}
      </>
    );
  }
);

const ApplicationStatusProgress = ({ statusHistory, isPdfExport }) => {
  const ref = useRef(null);
  const resizeTimeoutRef = useRef(null);
  const [itemsPerRow, setItemsPerRow] = useState(isPdfExport ? 3 : 1);
  const [loading, setLoading] = useState(false);

  // Memoize steps array
  const steps = useMemo(
    () =>
      statusHistory
        .map((status) => {
          const matchedStep = ALL_POSSIBLE_STEPS.find(
            (step) => step.value === status.currentStatus
          );
          return matchedStep
            ? { ...matchedStep, changedAt: status.changedAt }
            : null;
        })
        .filter(Boolean),
    [statusHistory]
  );

  // Memoize progress array
  const effectiveProgressArray = useMemo(() => {
    const array = [];
    for (let i = 0; i < steps.length; i += itemsPerRow) {
      array.push(steps.slice(i, i + itemsPerRow));
    }
    return array;
  }, [steps, itemsPerRow]);

  useEffect(() => {
    const initalWidth = ref?.current?.offsetWidth || 0;
    const newItemsPerRow = Math.floor(initalWidth / (STEP_WIDTH + 32));
    if (newItemsPerRow !== itemsPerRow && !isPdfExport) {
      setItemsPerRow(newItemsPerRow || 1);
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    if (isPdfExport) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      if (!entries?.[0]) {
        return;
      }

      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }

      resizeTimeoutRef.current = setTimeout(() => {
        const width = ref?.current?.offsetWidth || 0;
        const newItemsPerRow = Math.floor(width / (STEP_WIDTH + 32));
        if (newItemsPerRow !== itemsPerRow) {
          setItemsPerRow(newItemsPerRow || 1);
        }
      }, 50);
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
    };
  }, [itemsPerRow, isPdfExport]);

  useEffect(() => {
    if (isPdfExport) {
      setItemsPerRow(3);
    }
  }, [isPdfExport]);

  if (statusHistory.length === 0) {
    return <h3>No application status history found</h3>;
  }

  return (
    <div
      id="application-progression-holder"
      className="p-2 d-flex justify-content-center"
      ref={ref}
    >
      {(loading || isPdfExport) && (
        <div>
          {effectiveProgressArray.map((row, i) => (
            <Rows
              key={i}
              row={row}
              rowIndex={i}
              itemsPerRow={isPdfExport ? 3 : itemsPerRow}
              effectiveProgressArrayLength={effectiveProgressArray.length}
              lastIndex={steps.length}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(ApplicationStatusProgress);
